import { Injectable } from "@angular/core";
import { environment } from "@env";
import { Observable, of } from "rxjs";
import { delay, map } from "rxjs/operators";
import { HttpService } from "../../services/http.service";
import { PredictionPriority, PredictionProblemCauses, PredictionMachineIssues } from "./swh-predictions.model";

@Injectable()
export class SwhPredictionsService {

    constructor(private httpService: HttpService) { }

    getPredictionRPN(): Observable<PredictionPriority[]> {
        // TODO: need to uncomment when implement with API
        return this.httpService.post(environment.swhMlRPN);
        
    }

    getMachineIssues(): Observable<PredictionMachineIssues[]> {
        return this.httpService.post(environment.swhMlMachineIssues);
    }

    getProblemCauses() {
        return this.httpService.post(environment.swhMlProblemCause);
    }

}

const PREDICTION_DATA: PredictionPriority[] = [
    {
        "name": "Battery temp out of range",
        "value": 63
    },
    {
        "name": "Power out of range",
        "value": 19
    },
    {
        "name": "Overcharging",
        "value": 50
    },
    {
        "name": "Mechanical wear & tear",
        "value": 18
    }
];

const MACHINE_ISSUES: PredictionMachineIssues[] = [
    {
        "cumulativePercentage": 27.7559,
        "name": "Battery temp out of range",
        "value": 5416
    },
    {
        "cumulativePercentage": 54.1639,
        "name": "Overcharging",
        "value": 4153
    },
    {
        "cumulativePercentage": 77.6662,
        "name": "Power out of range",
        "value": 4586
    },
    {
        "cumulativePercentage": 100,
        "name": "Mechanical wear & tear",
        "value": 3358
    }
]

// const PROBLEM_CAUSES: PredictionProblemCauses[] = [
//     {
//         "name": "Battery temp out of range",
//         "value": '50.35'
//     },
//     {
//         "name": "Power out of range",
//         "value": '14.79'
//     },
//     {
//         "name": "Overcharging",
//         "value": '20.2'
//     },
//     {
//         "name": "Mechanical wear & tear",
//         "value": '14.66'
//     }
// ];