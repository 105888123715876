<div class="row">
<div class="col-md-5">
    <div class="csv-sim">
        
            <div class="title-info">
                CSV Simulator
            </div>
            <div [(ngModel)]="status">
            <div class="row" style="margin-top: 15px;" *ngIf="status=='Online'" >
                <div class="font-size" style="padding-left: 23px;"> CSV Simulator Status: </div>
                <div class="dot-size online"></div>
            </div>
        </div>
        <div [(ngModel)]="status">
            <div class="row" style="margin-top: 15px;"*ngIf="status=='Offline'">
                <div class="font-size" style="padding-left: 23px;"> CSV Simulator Status: </div>
                <div class="dot-size offline"></div>
                <div class="font-size" style="padding-left: 10px;">Last Reported Time: {{lastReportedTime}}</div>
            </div>
        </div>
            <br>
            <div class="file-upload-card">
            <label for="singleFile">Upload file</label> <br>
            <input id="singleFile" name="file" type="file"  [fileUploadInputFor]="fileUploadQueue" />
            <br>
            <div style="width: 400px">
                <mat-file-upload-queue #fileUploadQueue [fileAlias]="'file'"
                    [httpUrl]="startCsvSimulatorEndPoint" >
                    <mat-file-upload [file]="file" [id]="i" *ngFor="let file of fileUploadQueue.files; let i = index" (onUpload)="uploadEvent($event)" >
                    </mat-file-upload>
                </mat-file-upload-queue>
                <button class="mat-focus-indicator mat-raised-button mat-button-base mat-primary ng-star-inserted" style="margin-bottom: 5px;" (click)="stopSimulator()">Stop CSV Simulator</button>&nbsp;
    
            </div>
            {{responseMessage}}
        </div>
        
    </div>
</div>
<div class="col-md-6">
    <div class="csv-sim">
        <div class="title-info">
            Electric Vehicles
        </div>
        <div class="ev-table">
            <div class="fit-table-data">
                <app-ev-device-list></app-ev-device-list>
            </div>
        </div>
    </div>   

</div>
</div>