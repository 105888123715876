import { Component, Input, OnInit, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OPCCommonService } from '../../services/opc-common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertTableComponent } from '../../shared/alert-table/alert-table.component';
import { MatCalendarCellCssClasses, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { environment } from '@env';
import { SharedService } from '../../services/shared.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-swh-ticket-table',
  templateUrl: './swh-ticket-table.component.html',
  styleUrls: ['./swh-ticket-table.component.css']
})
export class SwhTicketTableComponent implements OnInit {

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  //Popup Templates Starts Here...
  @ViewChild('FormTemplate') FormTemplate: TemplateRef<any>;
  @ViewChild('orderTemplate') orderTemplate: TemplateRef<any>;
  @ViewChild('orderRepTemplate') orderRepTemplate: TemplateRef<any>;
  @ViewChild('orderSucesTemplate') orderSucesTemplate: TemplateRef<any>;
  @ViewChild('scheduleSerTemplate') scheduleSerTemplate: TemplateRef<any>;
  //Popup Templates Ends Here...
  public modalRef: BsModalRef;

  alertTable: AlertTableComponent;
  config = {
    animated: false,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-md center-modal',
  };
  ticketId: any;
  assetName: string;
  ticketColumnHeadrs = ['Sl.No', 'Ticket ID', 'Asset Name', 'Description', 'Created Time', 'Status', 'Modified Time', 'SLA'];
  scheduleDisFlg: boolean = true;
  dateDisFlg: boolean = true;
  constructor(
    private service: OPCCommonService,
    private modalService: BsModalService,
    private sharedService: SharedService,
    private http: HttpService,
    private httpClient: HttpClient
  ) { }
  @Input() tableData;
  @Input() columnHeader;
  @Input() dataSourceTotalSize;
  @Input() isLoad;
  @Output() nextPageEvent = new EventEmitter<{ offset: string, limit: string }>();
  @Output() isSyncRequired: EventEmitter<boolean> = new EventEmitter(false);
  @Output() eventDescOnClick: EventEmitter<string> = new EventEmitter();
  @Output() filterStatus = new EventEmitter<{status:string}>();
  objectKeys = Object.keys;
  selectedAssetToEdit: string;
  dataSource;
  //Popup Varaiables Starts Here...
  todayDate: any;
  popupHeading: any;
  succesMsg: any;
  defaultshipment = 'stan';
  netprice: any;
  shipmentCost: any = 10
  unitprice: any;
  totalprice: any;
  tax: any = 5.5;
  vendorFrom: any;
  shipToAdrs: any = "xyz,USA,NewYork,zipcode:500028";
  shipToAdres: any;
  servceTktPopupData = [];
  itemsLength: any[] = [];
  loadingSpin = false;
  locDisbleFlg = true;
  slotDisbleFlg = false;
  date: MatDatepickerInputEvent<Date>;
  locVal: string;
  selectedPartDetails: any;
  serviceTechList: ServiceTech[] = [{
    name: "Service Technician 1",
    loc: ['USA/Texas', 'USA/NewYork', 'USA/Dallas'],
    timeSlots: ['5 am - 6 am',
      '7 am - 8 am',
      '9 pm - 10 pm',
      '11 pm - 12 pm']
  },
  {
    name: "Service Technician 2",
    loc: ["USA,Chicago,zipcode:500028", "USA,NewYork,zipcode:500057", "USA,Bloomington,zipcode:400067", "USA,Dallas,zipcode:400028", "USA,Boston,zipcode:400031"],
    timeSlots: ['8 am - 9 am', '5 pm - 6 pm', '9 pm - 10 pm']
  },
  {
    name: "Service Technician 3",
    loc: ["USA,Chicago,zipcode:500028", "USA,NewYork,zipcode:500057", "USA,Bloomington,zipcode:400067", "USA,Dallas,zipcode:400028", "USA,Boston,zipcode:400031"],
    timeSlots: ['8 am - 9 am', '10 am - 11 am', '5 pm - 6 pm', '9 pm - 10 pm']
  },
  {
    name: "Service Technician 4",
    loc: ["USA/Texas", "USA/Dallas", "USA/Chicago", "USA/Blomington"],
    timeSlots: ['8 am - 9 am', '10 am - 11 am', '9 pm - 10 pm']
  },
  {
    name: "Service Technician 5",
    loc: ["USA,Chicago,zipcode:500028", "USA,NewYork,zipcode:500057", "USA,Bloomington,zipcode:400067", "USA,Dallas,zipcode:400028", "USA,Boston,zipcode:400031"],
    timeSlots: ['8 am - 9 am', '10 am - 11 am', '5 pm - 6 pm', '9 pm - 10 pm']
  }];
  serTechLocList: string[] = [
    "USA,Chicago,zipcode:500028",
    "USA,NewYork,zipcode:500057",
    "USA,Bloomington,zipcode:400067",
    "USA,Dallas,zipcode:400028",
    "USA,Boston,zipcode:400031"
  ];
  slotsAvl: any = [];
  partDetailsHeader = ['Replacement OEM Part#', 'Rep. Part Description', 'Vendor Details', 'Stock', 'Price ($)']
  partDetailsData: any[];
  minDate: Date;
  dateClass: any;
  scheduleServiceForm: FormGroup;

  //Popup Varaiables Ends Here...

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.tableData);
    let dateObj = new Date();
    this.minDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate())
  }
  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  editAssetParams(element) {
    this.selectedAssetToEdit = element.folder;
    // console.log("selectedAssetToEdit:"+this.selectedAssetToEdit)
    this.service.setData(this.selectedAssetToEdit);
  }

  public redirectToDetails(element) {
    this.resetScheduleServiceForm();
    this.ticketId = element.ticketId;
    this.assetName = element.freeFld1;
    this.servceTktPopupData = [];
    let partDetail = PART_DETAILS_JSON.find(({ description }) => description === element.details);
    if (!partDetail) {
      partDetail = PART_DETAILS_JSON[4]; // TODO: remove later default if no data present
    }
    this.partDetailsData = partDetail ? partDetail.partDetails : [];
    this.servceTktPopupData.push({ ...element, proposedSolution: partDetail ? partDetail.proposedSolution : '' });
    this.modalRef = this.modalService.show(this.FormTemplate, this.config);
  }

  changeTab(desc) {
    this.eventDescOnClick.emit(desc);
  }

  resetScheduleServiceForm() {
    this.createScheduleServiceForm();
    this.slotsAvl = [];
  }

  close() {
    this.modalRef.hide();
  }
  onChangePage(event: PageEvent) {
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    // this.alertTable = new AlertTableComponent(null,this.http,null,null);
    //this.alertTable.pageChanged(event);
    this.nextPageEvent.emit({ offset: pageIndex.toString(), limit: pageSize.toString() });
  }

  //Popup methods Starts Here...

  navigationEvent(type, item?: any) {
    this.locDisbleFlg = true;
    this.slotDisbleFlg = false;
    this.scheduleDisFlg = true;
    this.dateDisFlg = true;
    this.shipmentCost = 10;
    this.modalRef.hide();
    let popupOptions = {
      animated: false,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg center-modal',
    }
    if (type == 'orderScren') {
      this.modalRef = this.modalService.show(this.orderTemplate, popupOptions);
    }
    else if (type == 'orderNow') {
      this.selectedPartDetails = item;
      this.vendorFrom = item.vendor;
      this.unitprice = parseFloat(item.price);
      this.totalprice = parseFloat(item.price);
      this.itemsLength = [];
      for (let i = 1; i <= parseInt(item.stockItems); i++) {
        this.itemsLength.push(i);
      }
      this.netprice = parseFloat(this.totalprice + this.tax + this.shipmentCost).toFixed(2);
      this.modalRef = this.modalService.show(this.orderRepTemplate, popupOptions);
    }
    else if (type == "submitOrder") {
      popupOptions['class'] = 'modal-md center-modal',
        this.popupHeading = "Order Confirmation";
      const orderId = this.sharedService.randonOrderNumber;
      this.scheduleServiceForm.patchValue({orderId});
      this.succesMsg = 'Order placed successfully with an OrderID : #' + (orderId);
      this.loadingSpin = true;
      this.modalRef = this.modalService.show(this.orderSucesTemplate, popupOptions);
      setTimeout(() => {
        this.loadingSpin = false;
      }, 1500)

    } else if (type == "schedule") {
      popupOptions['class'] = 'modal-lg center-modal',
        this.dateClass = (d: Date): MatCalendarCellCssClasses => {
          const date = d.getDate();
          const mnth = d.getMonth();
          if (mnth === 8)
            return (date === 23 || date === 20 || date === 29) ? 'example-custom-date-class' : '';
          else if (mnth === 9)
            return (date === 5 || date === 14 || date === 27) ? 'example-custom-date-class' : '';
          else if (mnth === 10)
            return (date === 8 || date === 11 || date === 30 || date === 16) ? 'example-custom-date-class' : '';
        }
      this.modalRef = this.modalService.show(this.scheduleSerTemplate, popupOptions);
    } else if (type == "scheduleSer") {
      popupOptions['class'] = 'modal-md center-modal',
        this.popupHeading = "Schedule Service Confirmation";
      this.succesMsg = "Service Scheduling is Successful.";
      this.loadingSpin = true;
      const alertId = +this.servceTktPopupData[0].alertId;
      const payload = {
        alertId,
        assetName: this.assetName,
        partNumber: this.selectedPartDetails.RepOEMpart,
        partName: this.selectedPartDetails.part,
        material: this.selectedPartDetails.material,
        ...this.scheduleServiceForm.value,
      }

      payload.serviceDate = this.sharedService.getFormattedDate(payload.serviceDate); // Modify service date
      const serviceDate = payload.serviceDate;
      const serviceTime = payload.serviceTime;
      this.getServiceStatusUpdateResponse(payload).subscribe(
        (response) => {
          console.log("Successful Resonse");
          this.isSyncRequired.emit(true);
        },
        (error) => {
          console.log("There Was A Problem Registering");
        });
      const alertPayload = {
        "freefld1": this.assetName,
        "part_name": this.selectedPartDetails.part,
        "part_number": this.selectedPartDetails.RepOEMpart,
        "service_date": serviceDate,
        "service_time": serviceTime
      }
      this.getAlertResponse(alertPayload).subscribe(
        (response) => {
          console.log("Successful Resonse from Logic App");
          this.isSyncRequired.emit(true);
        },
        (error) => {
          console.log("There Was A Problem While Triggering Email from Logic App");
        });

      this.modalRef = this.modalService.show(this.orderSucesTemplate, popupOptions);
      setTimeout(() => {
        this.loadingSpin = false;
      }, 1500);
    }
  }

  getServiceStatusUpdateResponse(body): Observable<any> {
    return this.http.post(environment.swhServiceStatusUpdateEndPoint, body);
  }
  getAlertResponse(body): Observable<any> {
    const alertUrl = localStorage.getItem('rheemalertlogicendpoint');
    return this.httpClient.post(alertUrl, body);
  }
  filterByStatus(statusName:string){
    document.getElementById("icon").style.color='#ffff00';
    const status_types=['All','Open','Closed','Scheduled','In Progress','Completed'];
    for(let i=0;i<status_types.length;i++){
      document.getElementById(status_types[i]).style.background='#000000';
    }
    document.getElementById(statusName).style.backgroundColor='#9c9c30';
    if(statusName=="All"){
      document.getElementById("icon").style.color='#FFFFFF';
    }
    this.filterStatus.emit({status:statusName.toString()});
  }

  goback(screnName) {
    this.modalRef.hide();
    let popupOptions = {
      animated: false,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg center-modal',
    }
    if (screnName == "serviceTkt") {
      popupOptions.class = 'modal-md center-modal'
      this.modalRef = this.modalService.show(this.FormTemplate, this.config);
    } else if (screnName == "orderNow") {
      this.modalRef = this.modalService.show(this.orderTemplate, popupOptions);
    }
  }

  shipmentMethodChnge(e) {
    let shipmntType = e.target.value;
    if (shipmntType == "stan")
      this.shipmentCost = 10;
    else if (shipmntType == "ex")
      this.shipmentCost = 15;
    else if (shipmntType == "pr")
      this.shipmentCost = 20;
    else if (shipmntType == "ep") {
      this.shipmentCost = 30;
    }
    this.netprice = parseFloat(Number(this.totalprice) + this.tax + this.shipmentCost).toFixed(2);
  }

  onQuantityChange(e) {
    let quanity = e.target.options[e.target.selectedIndex].text;
    this.totalprice = (this.unitprice * parseInt(quanity)).toFixed(2);
    this.netprice = parseFloat(Number(this.totalprice) + this.tax + this.shipmentCost).toFixed(2);
  }

  getStock(item) {
    if (item.stock == 'Available')
      return item.stock + "(" + item.stockItems + ")"
    else
      return item.stock;
  }

  onChangeSerTech(e) {
    this.scheduleDisFlg = true;
    this.slotsAvl = [];
    this.scheduleServiceForm.get('location').enable();
    this.scheduleServiceForm.patchValue({ location: '', serviceDate: null, serviceTime: '' })
    // let serTech = e.target.value;
    // const currentTechSelect: ServiceTech = this.serviceTechList.find(item => item.name === serTech);
    // if (currentTechSelect) {
    //   this.serTechLocList = currentTechSelect.loc;
    //   this.slotsAvl = currentTechSelect.timeSlots;
    // } else {
    //   this.serTechLocList = [];
    //   this.slotsAvl = [];
    // }

    let locList = [
      "USA,Chicago,zipcode:500028",
      "USA,NewYork,zipcode:500057",
      "USA,Bloomington,zipcode:400067",
      "USA,Dallas,zipcode:400028",
      "USA,Boston,zipcode:400031"];
    let index = Math.floor(Math.random() * 5);
    if (index == 0) {
      this.serTechLocList = locList.splice(0, 2);
    } else {
      this.serTechLocList = locList.splice(0, index);
    }
  }
  onChnageLoc(e) {
    if (e) {
      this.scheduleServiceForm.get('serviceDate').enable();
      this.scheduleServiceForm.patchValue({ serviceDate: null, serviceTime: '' });
    }
  }
  dateChnageEvent(type: string, event) {
    this.date = event.target.value;
    this.slotsAvl = [];
    this.slotDisbleFlg = true;
    let allAvlSlots = ['5 am - 6 am', '7 am - 8 am', '8 am - 9 am', '10 am - 11 am', '11 am - 12 am', '2 pm - 3 pm', '4 pm - 5 pm', '5 pm - 6 pm', '9 pm - 10 pm', '11 pm - 12 pm'];
    let index = Math.floor(Math.random() * 7);
    if (index == 0) {
      this.slotsAvl = allAvlSlots.splice(0, 3);
    } else
      this.slotsAvl = allAvlSlots.splice(0, index);
  }
  onchangeTime(e) {
    let avlTime = e.target.value;
    if (avlTime != ' ') {
      this.scheduleDisFlg = false;
    }
  }
  //Popup methods Ends Here...

  createScheduleServiceForm() {
    this.scheduleServiceForm = new FormGroup({
      orderId: new FormControl(''),
      technician: new FormControl('', [Validators.required]),
      location: new FormControl({ value: '', disabled: true }, [Validators.required]),
      serviceDate: new FormControl({ value: null, disabled: true }),
      serviceTime: new FormControl('', [Validators.required])
    });
  }
}

const PART_DETAILS_JSON = [
  {
    id: 1,
    description: 'Capacity Outage',
    proposedSolution: 'Adding a tempering valve.',
    partDetails: [
      {
        'RepOEMpart': 'DT104001001',
        'part': 'Tempering valve',
        'material': 'Aluminum 7075',
        'RepPartDescription': 'Part - Tempering valve \n Material - Aluminum 7075',
        'vendor': 'Vendor1,USA,Texas,zipcode: 60007',
        'stock': 'Available',
        'stockItems': '15',
        'price': '250.00'
      },
      {
        'RepOEMpart': 'DT107007701',
        'part': 'Tempering valve',
        'material': 'Aluminum 7075',
        'RepPartDescription': 'Part - Tempering valve \n Material - Aluminum 7075',
        'vendor': 'Vendor2,USA,Bloomington,zipcode: 47401',
        'stock': 'Available',
        'stockItems': '5',
        'price': '280.00'
      },
      {
        'RepOEMpart': 'DT107013501',
        'part': 'Tempering valve',
        'material': 'Aluminum 7075',
        'RepPartDescription': 'Part - Tempering valve \n Material - Aluminum 7075',
        'vendor': 'Vendor3,USA,Chicago,zipcode: 60401',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '290.50'
      },
      {
        'RepOEMpart': 'DT107005101',
        'part': 'Tempering valve',
        'material': 'Aluminum 7075',
        'RepPartDescription': 'Part - Tempering valve \n Material - Aluminum 7075',
        'vendor': 'Vendor4,USA,Dallas,zipcode: 48401',
        'stock': 'Available',
        'stockItems': '7',
        'price': '255.67'
      },
      {
        'RepOEMpart': 'DT107023901',
        'part': 'Tempering valve',
        'material': 'Aluminum 7075',
        'RepPartDescription': 'Part - Tempering valve \n Material - Aluminum 7075',
        'vendor': 'Vendor5,USA,NewYork,zipcode: 67001',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '250.90'
      }
    ]
  },
  {
    id: 2,
    description: 'High Water Pressure',
    proposedSolution: 'Adjust the Pressure.',
    partDetails: [
      {
        'RepOEMpart': 'DT104001001',
        'part': '72v-12v conv',
        'material': 'Generic',
        'RepPartDescription': 'Part - 72v-12v conv \n Material - Generic',
        'vendor': 'Vendor1,USA,Texas,zipcode: 60007',
        'stock': 'Available',
        'stockItems': '15',
        'price': '250.00'
      },
      {
        'RepOEMpart': 'DT107007701',
        'part': '72v-12v conv',
        'material': 'Generic',
        'RepPartDescription': 'Part - 72v-12v conv \n Material - Generic',
        'vendor': 'Vendor2,USA,Bloomington,zipcode: 47401',
        'stock': 'Available',
        'stockItems': '5',
        'price': '280.00'
      },
      {
        'RepOEMpart': 'DT107013501',
        'part': '72v-12v conv',
        'material': 'Generic',
        'RepPartDescription': 'Part - 72v-12v conv \n Material - Generic',
        'vendor': 'Vendor3,USA,Chicago,zipcode: 60401',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '290.50'
      },
      {
        'RepOEMpart': 'DT107005101',
        'part': '72v-12v conv',
        'material': 'Generic',
        'RepPartDescription': 'Part - 72v-12v conv \n Material - Generic',
        'vendor': 'Vendor4,USA,Dallas,zipcode: 48401',
        'stock': 'Available',
        'stockItems': '7',
        'price': '255.67'
      },
      {
        'RepOEMpart': 'DT107023901',
        'part': '72v-12v conv',
        'material': 'Generic',
        'RepPartDescription': 'Part - 72v-12v conv \n Material - Generic',
        'vendor': 'Vendor5,USA,NewYork,zipcode: 67001',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '250.90'
      }
    ]
  },
  {
    id: 3,
    description: 'Water Leakage',
    proposedSolution: 'Drain valves needs tightening.',
    partDetails: [
      {
        'RepOEMpart': 'DT104001001',
        'part': 'Drain valves',
        'material': 'Generic',
        'RepPartDescription': 'Part - Drain valves \n Material  - Generic',
        'vendor': 'Vendor1,USA,Texas,zipcode: 60007',
        'stock': 'Available',
        'stockItems': '15',
        'price': '250.00'
      },
      {
        'RepOEMpart': 'DT107007701',
        'part': 'Drain valves',
        'material': 'Generic',
        'RepPartDescription': 'Part - Drain valves \n Material  - Generic',
        'vendor': 'Vendor2,USA,Bloomington,zipcode: 47401',
        'stock': 'Available',
        'stockItems': '5',
        'price': '280.00'
      },
      {
        'RepOEMpart': 'DT107013501',
        'part': 'Drain valves',
        'material': 'Generic',
        'RepPartDescription': 'Part - Drain valves \n Material  - Generic',
        'vendor': 'Vendor3,USA,Chicago,zipcode: 60401',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '290.50'
      },
      {
        'RepOEMpart': 'DT107005101',
        'part': 'Drain valves',
        'material': 'Generic',
        'RepPartDescription': 'Part - Drain valves \n Material  - Generic',
        'vendor': 'Vendor4,USA,Dallas,zipcode: 48401',
        'stock': 'Available',
        'stockItems': '7',
        'price': '255.67'
      },
      {
        'RepOEMpart': 'DT107023901',
        'part': 'Drain valves',
        'material': 'Generic',
        'RepPartDescription': 'Part - Drain valves \n Material  - Generic',
        'vendor': 'Vendor5,USA,NewYork,zipcode: 67001',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '250.90'
      }
    ]
  },
  {
    id: 4,
    description: 'Thermostat Failure',
    proposedSolution: 'Disassembly of failed thermostat.',
    partDetails: [
      {
        'RepOEMpart': 'DT104001001',
        'part': 'Thermostat',
        'material': 'Generic',
        'RepPartDescription': 'Part - Thermostat \n Material - Generic',
        'vendor': 'Vendor1,USA,Texas,zipcode: 60007',
        'stock': 'Available',
        'stockItems': '15',
        'price': '250.00'
      },
      {
        'RepOEMpart': 'DT107007701',
        'part': 'Thermostat',
        'material': 'Generic',
        'RepPartDescription': 'Part - Thermostat \n Material - Generic',
        'vendor': 'Vendor2,USA,Bloomington,zipcode: 47401',
        'stock': 'Available',
        'stockItems': '5',
        'price': '280.00'
      },
      {
        'RepOEMpart': 'DT107013501',
        'part': 'Thermostat',
        'material': 'Generic',
        'RepPartDescription': 'Part - Thermostat \n Material - Generic',
        'vendor': 'Vendor3,USA,Chicago,zipcode: 60401',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '290.50'
      },
      {
        'RepOEMpart': 'DT107005101',
        'part': 'Thermostat',
        'material': 'Generic',
        'RepPartDescription': 'Part - Thermostat \n Material - Generic',
        'vendor': 'Vendor4,USA,Dallas,zipcode: 48401',
        'stock': 'Available',
        'stockItems': '7',
        'price': '255.67'
      },
      {
        'RepOEMpart': 'DT107023901',
        'part': 'Thermostat',
        'material': 'Generic',
        'RepPartDescription': 'Part - Thermostat \n Material - Generic',
        'vendor': 'Vendor5,USA,NewYork,zipcode: 67001',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '250.90'
      }
    ]
  }
]

interface ServiceTech {
  name: string,
  loc: string[],
  timeSlots: string[]
}
