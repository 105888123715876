<ngx-charts-bubble-chart
  [view]="view"
  [scheme]="colorScheme"
  [results]="bubbleData"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [legendPosition]="legendPosition"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [xScaleMin]=""
  [xScaleMax]=""
  [yScaleMin]="yScaleMin"
  [yScaleMax]="yScaleMax"
  [minRadius]="minRadius"
  [maxRadius]="maxRadius"
  [showGridLines]="showGridLines"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)">
</ngx-charts-bubble-chart>

<!-- <div id="my_dataviz1"></div> -->
