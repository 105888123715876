<div class="container1"
  [ngStyle]="{'height.px':innerHeight+340,'width.px':innerWidth+140,'min-width.px':innerWidth+90,'overflow-y':'hidden','overflow-x':'hidden'}">
  <div class="row text-content" [ngStyle]="{'width.px':innerWidth+130}">
    <div class="col-sm-7 text-right text-style1">
      <a>
        <!-- <img class="header-logo-image" src="assets/images/land_logo.png" width="120px" alt="Logo"> -->
        <!-- <img class="header-logo-image" src="assets/images/ev_login2.PNG" width="120px" alt="Logo"> -->
        <p><b> Electric Vehicle</b>  </p>
      </a>
      <p>Digital Twin</p>
    </div>
    <!-- <div class="col-sm-4 text-right text-style2">{{from | date | date:'mediumDate'}} to {{to | date | date:'mediumDate'}}</div> -->
    <div class="col-sm-5 text-right text-style2" style="margin-left: -1%;">{{currentDate}}</div>
  </div>
  <mat-tab-group mat-align-tabs="left" dynamicHeight="true" [selectedIndex]="getSelectedIndex()"
    (selectedTabChange)="tabChanged($event)">
    <mat-tab label="Asset Health">
      <ng-template matTabContent>
        <app-ev-operations [assetId]="assetId"></app-ev-operations>
      </ng-template>
    </mat-tab>
    <mat-tab label="Fleet Health" *ngIf='"OpSum" | role:userRole'>
      <ng-template matTabContent>
        <app-ev-operation-summary></app-ev-operation-summary>
      </ng-template>
    </mat-tab>
    <mat-tab label="Predictions" *ngIf='"predctions" | role:userRole'>
      <ng-template matTabContent>
        <app-ev-predictions></app-ev-predictions>
      </ng-template>
    </mat-tab>
    <mat-tab label="Remote Operations Cockpit" *ngIf='"serDash" | role:userRole'>
      <ng-template matTabContent>
        <app-ev-diagnostics></app-ev-diagnostics>
      </ng-template>
    </mat-tab>
    <!-- <mat-tab label="LAND EV Telemetry">
      <ng-template matTabContent>
        <app-land-ev-operations></app-land-ev-operations>
      </ng-template>
    </mat-tab> -->
    <!-- <mat-tab label="LAND Battery Health">
      <ng-template matTabContent>
        <app-land-ev-battery (selIndex)="sohTab($event)"></app-land-ev-battery>
      </ng-template>
    </mat-tab> -->
    <!-- <mat-tab label="State of Health">
      <ng-template matTabContent>
        <app-battery-soh></app-battery-soh>
      </ng-template>
    </mat-tab> -->
  </mat-tab-group>
</div>
