<!-- <div class="d-flex flex-row">
  <app-horizontal-medium-widget [widgetType]="'d3-line-chart'" [widgetName]="'Asset Failure Probability'"
  [leftFooterName]="''" [rightFooterName]="''" [widgetData]="lineChartInputData">
</app-horizontal-medium-widget>
<app-horizontal-medium-widget [widgetType]="'d3-line-chart-new'" [widgetName]="'Freezer Temperature Prediction'" [leftFooterName]="''"
[rightFooterName]="''" [widgetData]="lineChartInputData">
</app-horizontal-medium-widget>
</div>
<div class="d-flex flex-row">
  <app-horizontal-medium-widget [widgetType]="'bubble-chart'" [widgetName]="'Events History'" [leftFooterName]="''"
  [rightFooterName]="''" [widgetData]="gaugeInputData3">
</app-horizontal-medium-widget>
<app-horizontal-medium-widget [widgetType]="'vertical-bar-chart'" [widgetName]="'Issue Count Prediction'" [leftFooterName]="''"
[rightFooterName]="''" [widgetData]="gaugeInputData3">
</app-horizontal-medium-widget>
</div> -->

<!-- <div class="container" style="margin-top: 3%;" [ngStyle]="{'height.px':innerHeight+250,'width.px':innerWidth-90,'min-width.px':innerWidth+70}"> -->
  <div class="container" style="margin-top: 3%;" [ngStyle]="{'width.px':innerWidth-90,'min-width.px':innerWidth+70}">
  <div class="row" >
    <div class="col-md-4" style="margin-bottom: 2%; background-color: #222222; margin-left: 5px; max-width: 31%; border-radius: 18px;">
      <div style="background-color:#222222;">

        <p style="font-size: small; color: rgb(209, 209, 209);text-align: center;margin-top: 0px;padding-top: 5px;">
          Probability By Problem Causes</p>

        <div *ngIf="isProblemsLoaded" style="margin-top: -34px;">
          <kendo-chart [style.height.px]="height" [transitions]="false">
            <kendo-chart-series>
              <kendo-chart-series-item [size]="30" [autoFit]="autofit" type="donut" [data]="data" categoryField="kind"
                field="value">
                <kendo-chart-series-item-labels position="outsideEnd" color="#000" [content]="labelContent">
                </kendo-chart-series-item-labels>
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-legend [offsetX]="offsetX"
            [offsetY]="15" [visible]="true" position="bottom" [height]="200" orientation="vertical"></kendo-chart-legend>
          </kendo-chart>
        </div>
        <div *ngIf="!isProblemsLoaded" class="d-flex justify-content-center" style='height:150px;'>
         <!-- <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div> -->
        <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image"/>
        </div>

      </div>
    </div>
    
    <div class="col-md-4 col-md-offset-2" style="margin-bottom: 2%; background-color: #222222; height: 453px; margin-right: 5px; margin-left: 5px;max-width: 33%; border-radius: 18px;">
      <div style="background-color:#222222;">
        <div>
          <p style="font-size: small; color: rgb(209, 209, 209);text-align: center;margin-top: 0px;padding-top: 1%;">
            Count Of Problems</p>
        </div>
        <div>
          <!-- <app-pareto-chart></app-pareto-chart>  -->
          <!-- <app-ngx-pareto-chart *ngIf="isIssuesLoaded" [barChartData]="barChartInput" [lineChartData]="lineChartInput"  [screenName]="'prediction'"></app-ngx-pareto-chart> -->
          <app-horizontal-large-widget *ngIf="isIssuesLoaded" [widgetType]="'vertical-bar-chart'" [widgetName]="''" [leftFooterName]=""
          [rightFooterName]="" [isIssuesLoaded]="isIssuesLoaded" [xAxisLabelName]="xAxisLabelName" [yAxisLabelName]="yAxisLabelName" [widgetData]="MACHINE_ISSUES_LIST">
        </app-horizontal-large-widget>
        </div>
        <div *ngIf="!isIssuesLoaded" class="d-flex justify-content-center" style='height:150px;'>
          <!-- <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div> -->
        <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image"/>
        </div>
      </div>
    </div>
    <div class="col-md-4" style="margin-bottom: 2%; background-color: #222222; border-radius: 18px;">
      <div style="background-color:#222222;">
        <div>
          <p style="font-size: small;color: rgb(209, 209, 209);text-align: center;margin-top: 0px;padding-top: 5px;">RPN
            (Risk Priority
            Number)</p>
        </div>
      </div>
      <div>
        <app-horizontal-large-widget *ngIf="isPriorityLoaded" [widgetType]="'vertical-bar-chart'" [widgetName]="''" [leftFooterName]=""
          [rightFooterName]="" [widgetData]="PRIORITY_LIST">
        </app-horizontal-large-widget>
      </div>
      <div *ngIf="!isPriorityLoaded" class="d-flex justify-content-center" style='height:150px;'>
        <!-- <div class="spinner-border" role="status">
            <span class="sr-only" id="loading"></span>
        </div> -->
        <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image"/>
      </div>

    </div>
  </div>

  <!-- <div class="row row-class1" style="margin-top: 5px;"> -->
    <!-- <div class="col-md-6" style="background-color: #222222;height: 350px;">
      <div style="background-color:#222222;">
        <div>
          <p style="font-size: 16px; color: rgb(209, 209, 209);text-align: left;margin-top: 0px;padding-top: 1%;">
            Machine Issue</p>
        </div>
        <div style="margin-left: -30px;">
          <app-ngx-pareto-chart [screenName]="'prediction'"></app-ngx-pareto-chart>
        </div>
      </div>
    </div>
    <div class="col-md-6" style="background-color: #222222;margin-left: 5px;max-width: 49%;">
      <div style="background-color:#222222;">

        <p style="font-size: 16px; color: rgb(209, 209, 209);text-align: left;margin-top: 0px;padding-top: 5px;">
          Probability By Problem Causes</p>

        <div style="margin-top: 18px;">
          <kendo-chart [style.height.px]="200" [transitions]="false">
            <kendo-chart-series>
              <kendo-chart-series-item [autoFit]="autofit" type="donut" [data]="data" categoryField="kind"
                field="value">
                <kendo-chart-series-item-labels position="outsideEnd" color="#000" [content]="labelContent">
                </kendo-chart-series-item-labels>
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-legend [visible]="false"></kendo-chart-legend>
          </kendo-chart>
        </div>
      </div>
    </div> -->

  <!-- </div> -->
</div>