import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { KeyVaultService } from '../services/key-vault.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Output() isLoginEventTriggered: EventEmitter<Boolean> = new EventEmitter();
  keys: any;
  constructor(private authService: MsalService,private router: Router, private keyVaultSvc: KeyVaultService) { }

  ngOnInit(): void {
    
  }

  login() {
    localStorage.setItem('ev_login','');
    sessionStorage.setItem('swh_login', '');
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    localStorage.setItem('role','VP of Operations');
    localStorage.setItem('redirectUrlAfterLogin','/home/polar-delight');
    if (isIE) {
      this.authService.loginRedirect();
    } else {
      //this.authService.loginPopup();
      this.authService.loginRedirect();
    }
  }

}
