import { Component, Input, OnInit } from '@angular/core';
import { single } from './pie-chart-data.js';

@Component({
  selector: 'app-ngx-pie-chart',
  templateUrl: './ngx-pie-chart.component.html',
  styleUrls: ['./ngx-pie-chart.component.css']
})
export class NgxPieChartComponent implements OnInit {

  @Input() inputPieData : any[];
  @Input() legendShow : string;
  @Input() screenName : string;
   // single: any[];
  view: any[];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';

  colorScheme = {
    domain:  ['#085f01', '#e0f7fa','rgba(255, 166, 0, 0.805)', '#C7B42C', 'rgba(0, 217, 255, 0.205)','green', 'navyblue', 'darkgrey','lightgreen','brown']
  };

  constructor() {
    Object.assign(this, { single });
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  ngOnInit(): void {
    if(this.legendShow == 'Yes'){
      this.showLegend = true;
    }else{
      this.showLegend = false;
    }
    if(this.screenName == 'summary'){
      this.view = [280,170];
      this.showLabels=true;
      this.isDoughnut = true;
     // this.showLegend = true;
    }else if(this.screenName == 'prediction'){
      this.view = [540,245];
    }else{
      this.view = [350,235];
    }
  }

}
