import { Component, Input, OnInit } from '@angular/core';
// import { single } from './gauge-data.js';

@Component({
  selector: 'app-ngx-gauge-chart',
  templateUrl: './ngx-gauge-chart.component.html',
  styleUrls: ['./ngx-gauge-chart.component.css']
})
export class NgxGaugeChartComponent {

  @Input() gaugeInputData : any[];

  //single: any[];
  view: any[] = [220,140];
  showLegend : boolean = false;
  legendPosition: string = 'below';
  // gauge
  gaugeMin: number = 0;
  gaugeMax: number = 100;
  gaugeLargeSegments: number = 10;
  gaugeSmallSegments: number = 5;
  gaugeTextValue: string = '';
  gaugeUnits: string = '';
  gaugeAngleSpan: number = 240;
  gaugeStartAngle: number = -120;
  gaugeShowAxis: boolean = true;
  gaugeValue: number = 50; // linear gauge value
  gaugePreviousValue: number = 70;

  colorScheme = {
    domain: ['#e0f7fa', '#00bcd4', '#00bcd4', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor() {
    //Object.assign(this, { single });
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
