<div class="container">
<mat-card class="example-card">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title style="font-size: 15px;color: green;"><b>POD ID</b>:PD01</mat-card-title>
      <mat-card-subtitle style="font-size: 15px;color:red;"><b>Severity</b>: High</mat-card-subtitle>
    </mat-card-header>
  
    <mat-card-content>
      <p style="color: white;">
        Action is required immediately because the scope of the problem has increased.
        Investigate critical alerts or events immediately.
        For example, the same malware is detected across multiple devices in your environment.
        For example, the same malware is detected.
      </p>
    </mat-card-content>
    
    <mat-card-actions align = "end end">
        <button  class="btn btn-info">Close</button>&nbsp;
        <button  class="btn btn-info">Ignore</button>  
    </mat-card-actions>

  </mat-card>
</div>
  
