import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HostListener } from "@angular/core";
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { ServiceTicketsData, Tab, TicketsSummary } from './cnc-remote-op.model';
import { CncService } from './cnc-remote-op.service';
import { env } from 'process';

@Component({
  selector: 'app-remote-operations-cnc',
  templateUrl: './remote-operations-cnc.component.html',
  styleUrls: ['./remote-operations-cnc.component.css'],
  providers: [CncService]
})
export class RemoteOperationsCncComponent implements OnInit {
  filterByStatus:string='All';
  scrHeight: any;
  scrWidth: any;
  interval: any;
  dataSourceTotalSize: any;
  position;
  orientation;
  autoRefreshPeriod = environment.serviceTicketsAutoRefreshPeriodInSeconds * 1000;
  //alertTableData = ALERT_EVENT_DATA;
  //alertTableHeader = { 'id': 'SL No.', 'severity': 'Severity', 'createdTime':'Created Time', 'deviceName': 'Device Name', 'catagory': 'Catagory', 'errorCode': 'Error Code', 'message': 'Messages'};
  SERVICE_TICKET_DATA: ServiceTicketsData[] = [];
  OVERALL_TICKET_STATUS: TicketsSummary[] = [];
  serviceTicketData: any;
  isLoad: boolean = false;
  loadingSpin: boolean = false;
  loadingSpinInPie: boolean = false;
  serviceTicketTableHeader = { 'alertId': 'Alert ID', 'ticketId': 'Ticket ID', 'freeFld1': 'Asset Name', 'details': 'Description', 'RCreTime': 'Created Time', 'status': 'Status' };
  apiUrlTicketStatus: string = environment.coreApiBaseUrl + environment.operationSummaryEndPoint;
  serviceTicketNew: any;
  serviceTicketClosed: any;
  serviceTicketInprogress: any;
  pageIndex: string = '0';
  pageSize: string = '5'
  statusmessage: string ='';


  public autofit = true;
  public data: any[];
  isLoadTicketStatus: boolean = false;

  //@HostListener('window:resize', ['$event'])
  view: any[];

  tabsData: Tab[] = this.evDiagnosticsService.tabDataByDesc('Drive train failure');

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth > 641 && this.scrWidth <= 961) {
      this.position = 'bottom';
      this.orientation = 'horizontal';
    }
    else if (this.scrWidth > 961 && this.scrWidth <= 1025) {
      this.position = 'bottom';
      this.orientation = 'horizontal';
    }
    else {
      this.position = 'right';
      this.orientation = 'vertical';
    }

  }

  constructor(private evDiagnosticsService: CncService, private authService: MsalService, private http: HttpClient, private auth: MsalService, private cd: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.onOrientationChange();
    this.getScreenSize();
    this.getServiceTickets(this.pageIndex, this.pageSize,this.filterByStatus);
    if (this.scrWidth > 641 && this.scrWidth <= 961) {
      this.view = [225, 200];
    }
    else if (this.scrWidth > 961 && this.scrWidth <= 1025) {
      this.view = [295, 200];
    }
    else {
      this.view = [350, 300];
    }
    this.interval = setInterval(() => {
      // this.getServiceTickets(0,5)

    }, this.autoRefreshPeriod);
    this.getTicketsSummary();
  }

  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }


  getServiceTickets(offset, limit,status:string) {
    this.loadingSpin = true;
    this.isLoad = false;
    this.SERVICE_TICKET_DATA = [];
    this.evDiagnosticsService.getAllServiceTickets(offset, limit,status).subscribe(
      (response) => {
        this.SERVICE_TICKET_DATA.push(...response.serviceTickets.content);
        //Sort the records by RCreTime
        //this.serviceTicketData =  this.SERVICE_TICKET_DATA.sort((a,b) => (a.RCreTime < b.RCreTime) ? 1 : ((b.RCreTime < a.RCreTime) ? -1 : 0))
        //this.serviceTicketData =  this.SERVICE_TICKET_DATA.sort((a, b) => new Date(b.RCreTime).getTime() - new Date(a.RCreTime).getTime());
        this.serviceTicketData = this.SERVICE_TICKET_DATA;
        console.log('this.serviceTicketData', this.serviceTicketData);
        this.dataSourceTotalSize = response.serviceTickets.total;
        if(this.dataSourceTotalSize==0){
          this.statusmessage=`No Service Tickets to show from status "${status}".Kindly choose another status`;
        }
        this.isLoad = true;
        let table = document.getElementById('serviceTcktTbl');
        table.querySelector('tbody').style.display = 'revert';
        this.loadingSpin = false;
      },
      (error) => {
        console.log("There Was A Problem Getting All Service Tickets");
        this.loadingSpin = false;
      });
  }
  filterStatusChange(event:any){
    this.statusmessage='';
    this.filterByStatus=event?.status;
    this.getServiceTickets(this.pageIndex,this.pageSize,this.filterByStatus)
    console.log("Main Status-CNC",this.filterByStatus);
  }

  getTicketsSummary() {
    this.loadingSpinInPie = true;
    this.evDiagnosticsService.getOverallTicketStatus().subscribe(
      (response) => {
        response.operations.forEach(element => {
          //console.log("Data: " + JSON.stringify(element));
          this.OVERALL_TICKET_STATUS.push(element);
        });
        this.OVERALL_TICKET_STATUS.forEach(element => {
          this.serviceTicketNew = element.serviceTicketNew;
          this.serviceTicketClosed = element.serviceTicketClosed;
          this.serviceTicketInprogress = element.serviceTicketInprogress;
        });
        this.data = [{
          kind: 'New', share: this.serviceTicketNew
        }, {
          kind: 'Closed', share: this.serviceTicketClosed
        }, {
          kind: 'In Progress', share: this.serviceTicketInprogress
        }];
        this.isLoadTicketStatus = true;
        this.loadingSpinInPie = false;
      },
      (error) => {
        console.log("There Was A Problem Getting All Service Tickets");
        this.loadingSpinInPie = false;
      });
  }

  public disableEvent(e) {
    e.preventDefault();
  }

  public labelContent(e: any): string {
    return e.category + ":" + " " + e.value + "%";
  }
  pageChanged(event) {

    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    let previousIndex = event.previousPageIndex;

    let previousSize = pageSize * pageIndex;

    this.getServiceTickets((pageIndex).toString(), pageSize.toString(),this.filterByStatus);
  }
  pageChangedOnClick(event) {
    let table = document.getElementById('serviceTcktTbl');
    table.querySelector('tbody').style.display = 'none';
    this.pageIndex = event.offset;
    this.pageSize = event.limit;
    this.getServiceTickets((this.pageIndex).toString(), this.pageSize.toString(),this.filterByStatus);
  }

  isSyncRequired(isSync: boolean) {
    if(isSync) {
      this.getServiceTickets((this.pageIndex).toString(), this.pageSize.toString(),this.filterByStatus);
    }
  }

  onDetailClick(description) {
    this.tabsData = this.evDiagnosticsService.tabDataByDesc(description);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

}
