import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { data } from 'azure-maps-control';
import { ResultFunc } from 'rxjs/internal/observable/generate';
@Component({
  selector: 'app-battery-soh',
  templateUrl: './battery-soh.component.html',
  styleUrls: ['./battery-soh.component.css']
})
export class BatterySohComponent implements OnInit {
  charData: Array<any>;
  JSONData: any;
  innerWidth: any;
  public autofit = true;
  innerHeight: any;
  height: number;
  offsetX: number;
  elmA: number[] = [];
  elmP: number[] = [];
  categoryLabels: any[] = [];
  valueLabels: any[] = [600, 650, 700, 750, 800];
  rollingA: number[] = [];
  rollingP: number[] = [];
  rollingR: number[] = [];
  rollingPS: number[] = [];
  rollingT: number[] = [100, 200, 300, 400, 500, 600]
  rulrfA: number[] = [];
  rulrfP: number[] = [];
  RUL_ET_Ref: any = [{ Actual: 0, Predicted: 0 }, { Actual: 200, Predicted: 200 }, { Actual: 400, Predicted: 400 }, { Actual: 600, Predicted: 600 }, { Actual: 800, Predicted: 800 }, { Actual: 1000, Predicted: 1000 }, { Actual: 1130, Predicted: 1130 }]
  RUL_ET: any = []
  RF_data: any;
  RUL_KNN: any;
  RUL_RF: any;
  RUL_SVR: any;
  RF_Pred: number[] = [];
  RF_True: number[] = [];

  rollingL: { Predicted: string; Actual: string } = {
    Predicted: "Predicted",
    Actual: "Actual",
  };
  // areaRef: number[] = [];

  rufdata: number[] = [];
  str: string;
  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.SOHData();
    this.RULData();
    // this.SOHELM();
    this.RF();
    console.log("elmA", this.elmA);
    console.log("elmP", this.elmP);
    // this.rollingT.pop();
    // console.log("")
  }
  RF() {
    this.httpClient.get('/assets/csv/Oxford_RF_model_plot_1_2.csv', { responseType: 'text' })
      .subscribe(
        data => {
          var lines = data.split("\n");
          var result = [];
          var headers = lines[0].split(",");
          for (var i = 1; i < lines.length - 1; i++) {
            var obj = {};
            var currentline = lines[i].split(",");
            for (var j = 0; j < headers.length; j++) {
              obj[headers[j]] = +currentline[j];
            }
            result.push(obj);
          }
          this.RF_data = result;
          for (var i = 0; i < this.RF_data.length; i++) {
            this.RF_Pred.push(this.RF_data[i].predicted_capacity);
            this.RF_True.push(this.RF_data[i].true_capacity);
          }
          // console.log("RF_data True", this.RF_True);
          // console.log("RF_data", this.RF_data);
          console.log("RUL_ET", this.RUL_ET);
          // console.log("RF_data-predicted_capacity", this.RF_data[3].predicted_capacity );
        },
      );

  }


  SOHData() {
    this.httpClient.get('/assets/csv/SOH_ROLLING.csv', { responseType: 'text' })
      .subscribe(
        data => {
          var lines = data.split("\n");
          var result = [];
          var headers = lines[0].split(",");
          for (var i = 1; i < lines.length - 1; i++) {
            var obj = {};
            var currentline = lines[i].split(",");
            for (var j = 0; j < headers.length; j++) {
              obj[headers[j]] = currentline[j];
            }
            result.push(obj);
          }
          for (let i = 0; i < result.length; i++) {
            this.rollingA.push(result[i].Actual);
            this.categoryLabels.push(i);
          }
          // this.areaRef = this.rollingA.slice(1, 15)
          // console.log("areaREf" ,this.areaRef);
          // console.log("rollingA",this.rollingA);
          for (let i = 911; i < 1001; i++) {
            this.categoryLabels.push(i);
          }
          // console.log("categoryLabels", this.categoryLabels)
          for (let i = 0; i < result.length; i++) {
            this.rollingP.push(result[i].Predicted);
            this.rollingR.push(80);
          }
          // console.log("rollingP",this.rollingP);
          // this.rollingPS= this.rollingP.slice(200,800);
          // console.log("rollingPS",this.rollingPS);
          // this.JSONData = JSON.stringify(result);
        },
        error => {
          console.log("Error is", error);
        }
      );
      this.httpClient.get('/assets/csv/SOH_ELM.csv', { responseType: 'text' })
      .subscribe(
        data => {
          var lines = data.split("\n");
          var result = [];
          var headers = lines[0].split(",");
          for (var i = 1; i < lines.length - 1; i++) {
            var obj = {};
            var currentline = lines[i].split(",");
            for (var j = 0; j < headers.length; j++) {
              obj[headers[j]] = currentline[j];
            }
            result.push(obj);
          }
          for (let i = 0; i < result.length; i++) {
            this.elmA.push(result[i].Actual)
          }
          for (let i = 0; i < result.length; i++) {
            this.elmP.push(result[i].Predicted)
          }
          // this.JSONData = JSON.stringify(result);
        },
      );
  }
  // SOHELM() {

  // }

  RULData() {
    // this.httpClient.get('/assets/csv/RUL_SVR.csv', { responseType: 'text' })
    //   .subscribe(
    //     data => {
    //       var lines = data.split("\n");
    //       var result = [];
    //       var headers = lines[0].split(",");
    //       for (var i = 1; i < lines.length - 1; i++) {
    //         var obj = {};
    //         var currentline = lines[i].split(",");
    //         for (var j = 0; j < headers.length; j++) {
    //           obj[headers[j]] = +currentline[j];
    //         }
    //         result.push(obj);
    //       }
    //       this.RUL_SVR = result;
    //     },
    //   );
    // this.httpClient.get('/assets/csv/RUL_RF.csv', { responseType: 'text' })
    //   .subscribe(
    //     data => {
    //       var lines = data.split("\n");
    //       var result = [];
    //       var headers = lines[0].split(",");
    //       for (var i = 1; i < lines.length - 1; i++) {
    //         var obj = {};
    //         var currentline = lines[i].split(",");
    //         for (var j = 0; j < headers.length; j++) {
    //           obj[headers[j]] = +currentline[j];
    //         }
    //         result.push(obj);
    //       }
    //       this.RUL_RF = result;
    //     },
    //   );
    this.httpClient.get('/assets/csv/RUL_ET.csv', { responseType: 'text' })
      .subscribe(
        data => {
          var lines = data.split("\n");
          var result = [];
          var headers = lines[0].split(",");
          for (var i = 1; i < lines.length - 1; i++) {
            var obj = {};
            var currentline = lines[i].split(",");
            for (var j = 0; j < headers.length; j++) {
              obj[headers[j]] = +currentline[j];
            }
            result.push(obj);
          }
          this.RUL_ET = result;
          // console.log("RUL_ET", this.RUL_ET);
        },
      );
    // this.httpClient.get('/assets/csv/RUL_KNN.csv', { responseType: 'text' })
    //   .subscribe(
    //     data => {
    //       var lines = data.split("\n");
    //       var result = [];
    //       var headers = lines[0].split(",");
    //       for (var i = 1; i < lines.length - 1; i++) {
    //         var obj = {};
    //         var currentline = lines[i].split(",");
    //         for (var j = 0; j < headers.length; j++) {
    //           obj[headers[j]] = +currentline[j];
    //         }
    //         result.push(obj);
    //       }
    //       this.RUL_KNN = result;
    //     },
    //   );
  }
}
