import {
  Component,
  Input,
  OnInit,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OPCCommonService } from '../../services/opc-common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertTableComponent } from '../alert-table/alert-table.component';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { HttpService } from '../../services/http.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-reusable-app-table',
  templateUrl: './reusable-app-table.component.html',
  styleUrls: ['./reusable-app-table.component.css'],
})

export class ReusableAppTableComponent implements OnInit, OnChanges {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  //Popup Templates Starts Here...
  @ViewChild('FormTemplate') FormTemplate: TemplateRef<any>;
  @ViewChild('orderTemplate') orderTemplate: TemplateRef<any>;
  @ViewChild('orderRepTemplate') orderRepTemplate: TemplateRef<any>;
  @ViewChild('orderSucesTemplate') orderSucesTemplate: TemplateRef<any>;
  @ViewChild('scheduleSerTemplate') scheduleSerTemplate: TemplateRef<any>;
  //Popup Templates Ends Here...
  public modalRef: BsModalRef;

  alertTable: AlertTableComponent;
  config = {
    animated: false,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-md center-modal',
  };
  ticketId: any;
  ticketColumnHeadrs = ['Sl.No', 'Ticket ID', 'Asset Name', 'Description', 'Created Time', 'Status', 'Modified Time', 'SLA'];
  scheduleDisFlg: boolean = true;
  dateDisFlg: boolean = true;
  serviceTechName: any;
  srvcTechId: number;
  constructor(
    private service: OPCCommonService,
    private modalService: BsModalService,
    private http: HttpService,
    private sharedService: SharedService,
    private httpClient: HttpClient
  ) { }
  @Input() statusAll;
  @Input() present;
  @Input() tableData;
  @Input() columnHeader;
  @Input() dataSourceTotalSize;
  @Input() isLoad;
  @Output() nextPageEvent = new EventEmitter<{ offset: string, limit: string }>();
  @Output() filterStatus = new EventEmitter<{ status: string }>();
  // @Output() statusAll: EventEmitter<>= new EventEmitter();
  @Output() isSyncRequired: EventEmitter<boolean> = new EventEmitter(false);
  @Output() eventDescOnClick: EventEmitter<string> = new EventEmitter();
  objectKeys = Object.keys;
  selectedAssetToEdit: string;
  dataSource;
  //Popup Varaiables Starts Here...
  randomordId: any;
  todayDate: any;
  popupHeading: any;
  succesMsg: any;
  defaultshipment = 'stan';
  netprice: any;
  shipmentCost: any = 10
  unitprice: any;
  totalprice: any;
  tax: any = 5.5;
  vendorFrom: any;
  shipToAdrs: any = "sampleVendor,USA,NewYork,zipcode:500028";
  shipToAdres: any;
  servceTktPopupData = [];
  itemsLength: any[] = [];
  loadingSpin = false;
  locDisbleFlg = true;
  slotDisbleFlg = false;
  id: string;

  serviceTechList: ServiceTech[] = [{
    name: "Service Technician 1",
    loc: ['USA/Texas', 'USA/NewYork', 'USA/Dallas'],
    timeSlots: ['5 am - 6 am',
      '7 am - 8 am',
      '9 pm - 10 pm',
      '11 pm - 12 pm']
  },
  {
    name: "Service Technician 2",
    loc: ["USA,Chicago,zipcode:500028", "USA,NewYork,zipcode:500057", "USA,Bloomington,zipcode:400067", "USA,Dallas,zipcode:400028", "USA,Boston,zipcode:400031"],
    timeSlots: ['8 am - 9 am', '5 pm - 6 pm', '9 pm - 10 pm']
  },
  {
    name: "Service Technician 3",
    loc: ["USA,Chicago,zipcode:500028", "USA,NewYork,zipcode:500057", "USA,Bloomington,zipcode:400067", "USA,Dallas,zipcode:400028", "USA,Boston,zipcode:400031"],
    timeSlots: ['8 am - 9 am', '10 am - 11 am', '5 pm - 6 pm', '9 pm - 10 pm']
  },
  {
    name: "Service Technician 4",
    loc: ["USA/Texas", "USA/Dallas", "USA/Chicago", "USA/Blomington"],
    timeSlots: ['8 am - 9 am', '10 am - 11 am', '9 pm - 10 pm']
  },
    // {
    //   name: "Service Technician 5",
    //   loc: ["USA,Chicago,zipcode:500028", "USA,NewYork,zipcode:500057", "USA,Bloomington,zipcode:400067", "USA,Dallas,zipcode:400028", "USA,Boston,zipcode:400031"],
    //   timeSlots: ['8 am - 9 am', '10 am - 11 am', '5 pm - 6 pm', '9 pm - 10 pm']
    // }
  ];
  serTechLocList: any = [
    "USA,Chicago,zipcode:500028",
    "USA,NewYork,zipcode:500057",
    "USA,Bloomington,zipcode:400067",
    "USA,Dallas,zipcode:400028",
    "USA,Boston,zipcode:400031"];
  slotsAvl: any = [];
  partDetailsHeader = ['Replacement OEM Part#', 'Rep. Part Description', 'Vendor Details', 'Stock', 'Price ($)']
  partDetailsData: any[];
  minDate: Date;
  dateClass: any;
  assetName: any;
  selectedPartDetails: any;
  scheduleServiceForm: FormGroup;
  date: MatDatepickerInputEvent<Date>;

  //Popup Varaiables Ends Here...

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.tableData);
    let dateObj = new Date();
    this.minDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate())
  }
  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  editAssetParams(element) {
    this.selectedAssetToEdit = element.folder;
    // console.log("selectedAssetToEdit:"+this.selectedAssetToEdit)
    this.service.setData(this.selectedAssetToEdit);
  }

  createScheduleServiceForm() {
    this.scheduleServiceForm = new FormGroup({
      orderId: new FormControl(''),
      technician: new FormControl('', [Validators.required]),
      location: new FormControl({ value: '', disabled: true }, [Validators.required]),
      serviceDate: new FormControl({ value: null, disabled: true }),
      serviceTime: new FormControl('', [Validators.required])
    });
  }

  resetScheduleServiceForm() {
    this.createScheduleServiceForm();
    this.slotsAvl = [];
  }

  changeTab(desc) {
    this.eventDescOnClick.emit(desc);
  }



  public redirectToDetails(element) {
    this.resetScheduleServiceForm();
    this.ticketId = element.ticketId;
    this.assetName = element.freeFld1;
    this.servceTktPopupData = [];
    let partDetail = PART_DETAILS_JSON.find(({ description }) => description === element.details);
    if (!partDetail) {
      partDetail = PART_DETAILS_JSON[2]; // TODO: remove later default if no data present
    }
    this.partDetailsData = partDetail ? partDetail.partDetails : [];
    this.servceTktPopupData.push({ ...element, proposedSolution: partDetail ? partDetail.proposedSolution : '' });
    this.modalRef = this.modalService.show(this.FormTemplate, this.config);
  }

  close() {
    this.modalRef.hide();
  }
  onChangePage(event: PageEvent) {
    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    // this.alertTable = new AlertTableComponent(null,this.http,null,null);
    //this.alertTable.pageChanged(event);
    this.nextPageEvent.emit({ offset: pageIndex.toString(), limit: pageSize.toString() });
  }

  filterByStatus(statusName: string, id: string) {

    console.log("id", id);

    console.log("Status Name", statusName);
    this.filterStatus.emit({ status: statusName.toString() });
    console.log(this.filterStatus);
    document.getElementById("icon").style.color = '#ffff00';
    const status_types = ['All', 'Open', 'Closed', 'Scheduled', 'In Progress', 'Completed']
    for (let i = 0; i < status_types.length; i++) {
      document.getElementById(status_types[i]).style.backgroundColor = '#000000';
    }
    document.getElementById(statusName).style.backgroundColor = '#9c9c30';
    if (statusName == "All") {
      document.getElementById("icon").style.color = '#FFFFFF';

    }



  }

  //Popup methods Starts Here...

  navigationEvent(type, item?: any) {
    console.log('item', item);
    this.slotsAvl = [];
    this.locDisbleFlg = true;
    this.slotDisbleFlg = false;
    this.scheduleDisFlg = true;
    this.dateDisFlg = true;
    this.shipmentCost = 10;
    this.modalRef.hide();
    let popupOptions = {
      animated: false,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg center-modal',
    }
    if (type == 'orderScren') {
      this.modalRef = this.modalService.show(this.orderTemplate, popupOptions);
    }
    else if (type == 'orderNow') {
      this.selectedPartDetails = item;
      this.vendorFrom = item.vendor;
      this.unitprice = parseFloat(item.price);
      this.totalprice = parseFloat(item.price);
      this.itemsLength = [];
      for (let i = 1; i <= parseInt(item.stockItems); i++) {
        this.itemsLength.push(i);
      }
      this.netprice = parseFloat(this.totalprice + this.tax + this.shipmentCost).toFixed(2);
      this.modalRef = this.modalService.show(this.orderRepTemplate, popupOptions);
    }
    else if (type == "submitOrder") {
      popupOptions['class'] = 'modal-md center-modal',
        this.popupHeading = "Order Confirmation";
      const orderId = this.sharedService.randonOrderNumber;
      this.scheduleServiceForm.patchValue({ orderId });
      this.succesMsg = 'Order placed successfully with an OrderID : #' + (orderId);
      this.loadingSpin = true;
      this.modalRef = this.modalService.show(this.orderSucesTemplate, popupOptions);
      setTimeout(() => {
        this.loadingSpin = false;
      }, 1500)

    } else if (type == "schedule") {
      popupOptions['class'] = 'modal-lg center-modal',
        this.dateClass = (d: Date): MatCalendarCellCssClasses => {
          const date = d.getDate();
          const mnth = d.getMonth();
          if (mnth === 8)
            return (date === 23 || date === 20 || date === 29) ? 'example-custom-date-class' : '';
          else if (mnth === 9)
            return (date === 5 || date === 14 || date === 27) ? 'example-custom-date-class' : '';
          else if (mnth === 10)
            return (date === 8 || date === 11 || date === 30 || date === 16) ? 'example-custom-date-class' : '';
        }
      this.modalRef = this.modalService.show(this.scheduleSerTemplate, popupOptions);
    } else if (type == "scheduleSer") {
      popupOptions['class'] = 'modal-md center-modal',
        this.popupHeading = "Schedule Service Confirmation";
      this.succesMsg = "Service Scheduling is Successful.";
      this.loadingSpin = true;
      const alertId = this.servceTktPopupData[0].alertId;
      const payload = {
        alertId,
        assetName: this.assetName,
        partNumber: this.selectedPartDetails.RepOEMpart,
        partName: this.selectedPartDetails.RepPartDescription,
        ...this.scheduleServiceForm.value,
      }
      payload['serviceDate'] = this.sharedService.getFormattedDate(payload['serviceDate']); // Modify service date
      const serviceDate = payload.serviceDate;
      const serviceTime = payload.serviceTime;
      this.getServiceStatusUpdateResponse(payload).subscribe(
        (response) => {
          console.log("Successful Resonse");
          this.isSyncRequired.emit(true);
        },
        (error) => {
          console.log("There Was A Problem Registering")
        });
      const alertPayload = {
        "freefld1": this.assetName,
        "part_name": this.selectedPartDetails.RepPartDescription,
        "part_number": this.selectedPartDetails.RepOEMpart,
        "service_date": serviceDate,
        "service_time": serviceTime
      }
      this.getAlertResponse(alertPayload).subscribe(
        (response) => {
          console.log("Successful Resonse from Logic App");
          this.isSyncRequired.emit(true);
        },
        (error) => {
          console.log("There Was A Problem While Triggering Email from Logic App");
        });
      var orderId = this.servceTktPopupData[0].ticketId.split('_');
      var startTime = serviceTime.split('-')[0].split('am');
      var endTime = serviceTime.split('-')[1].split('am');
      const orderAndSchedulePayload = {
        "ticketId": this.servceTktPopupData[0].ticketId,
        "orderId": orderId[2],
        "srvTechnicianId": this.srvcTechId,
        "srvTechnicianName": this.serviceTechName,
        "schedStartDate": serviceDate,
        "schedEndDate": serviceDate,
        "schedStartTime": startTime[0],
        "schedEndTime": endTime[0],
        "operationId": "",
        "basicStartDate": serviceDate,
        "basicEndDate": serviceDate,
        "statusUpdate": "Scheduled",
        "activityType": "",
        "modifiedBy": "",
        "modifiedDate": "",
        "normalDuratin": "",
        "workActivity": "",
        "notes": ""
      }
      console.log('emailNotificationPayload', orderAndSchedulePayload);
      this.getOrderAndScheduleResponse(orderAndSchedulePayload).subscribe(
        (response) => {
          console.log("Successful Resonse from Logic App");
          this.isSyncRequired.emit(true);
        },
        (error) => {
          console.log("There Was A Problem While Triggering Email from Logic App");
        });
      this.modalRef = this.modalService.show(this.orderSucesTemplate, popupOptions);
      setTimeout(() => {
        this.loadingSpin = false;
      }, 1500);
    }

  }

  getServiceStatusUpdateResponse(payload): Observable<any> {
    return this.http.post(environment.serviceStatusUpdateEndPoint, payload);
  }
  getAlertResponse(body): Observable<any> {
    const alertUrl = localStorage.getItem('pdalertlogicappendpoint');
    return this.httpClient.post(alertUrl, body);
  }
  getOrderAndScheduleResponse(body): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + idToken

    }
    const orderAndScheduleUrl = environment.coreApiBaseUrl + environment.orderAndScheduleEndPoint;
    return this.httpClient.post(orderAndScheduleUrl, body, { 'headers': headers });
  }
  goback(screnName) {
    this.modalRef.hide();
    let popupOptions = {
      animated: false,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg center-modal',
    }
    if (screnName == "serviceTkt") {
      popupOptions.class = 'modal-md center-modal'
      this.modalRef = this.modalService.show(this.FormTemplate, this.config);
    } else if (screnName == "orderNow") {
      this.modalRef = this.modalService.show(this.orderTemplate, popupOptions);
    }
  }
  shipmentMethodChnge(e) {
    let shipmntType = e.target.value;
    if (shipmntType == "stan")
      this.shipmentCost = 10;
    else if (shipmntType == "ex")
      this.shipmentCost = 15;
    else if (shipmntType == "pr")
      this.shipmentCost = 20;
    else if (shipmntType == "ep") {
      this.shipmentCost = 30;
    }
    this.netprice = parseFloat(Number(this.totalprice) + this.tax + this.shipmentCost).toFixed(2);

  }

  onQuantityChange(e) {
    let quanity = e.target.options[e.target.selectedIndex].text;
    this.totalprice = (this.unitprice * parseInt(quanity)).toFixed(2);
    this.netprice = parseFloat(Number(this.totalprice) + this.tax + this.shipmentCost).toFixed(2);
  }

  getStock(item) {
    if (item.stock == 'Available')
      return item.stock + "(" + item.stockItems + ")"
    else
      return item.stock;
  }

  onChangeSerTech(e) {
    this.serviceTechName = e;
    if (this.serviceTechName === 'Service Technician 1') {
      this.srvcTechId = 12;
    } else if (this.serviceTechName === 'Service Technician 2') {
      this.srvcTechId = 13;
    } else if (this.serviceTechName === 'Service Technician 3') {
      this.srvcTechId = 14;
    } else if (this.serviceTechName === 'Service Technician 4') {
      this.srvcTechId = 15;
    }
    this.scheduleDisFlg = true;
    this.slotsAvl = [];
    this.scheduleServiceForm.get('location').enable();
    this.scheduleServiceForm.patchValue({ location: '', serviceDate: null, serviceTime: '' })
    // let serTech = e.target.value;
    // const currentTechSelect: ServiceTech = this.serviceTechList.find(item => item.name === serTech);
    // if (currentTechSelect) {
    //   this.serTechLocList = currentTechSelect.loc;
    //   this.slotsAvl = currentTechSelect.timeSlots;
    // } else {
    //   this.serTechLocList = [];
    //   this.slotsAvl = [];
    // }

    let locList = [
      "USA,Chicago,zipcode:500028",
      "USA,NewYork,zipcode:500057",
      "USA,Bloomington,zipcode:400067",
      "USA,Dallas,zipcode:400028",
      "USA,Boston,zipcode:400031"];
    let index = Math.floor(Math.random() * 5);
    if (index == 0) {
      this.serTechLocList = locList.splice(0, 2);
    } else {
      this.serTechLocList = locList.splice(0, index);
    }
  }

  onChnageLoc(e) {
    if (e) {
      this.scheduleServiceForm.get('serviceDate').enable();
      this.scheduleServiceForm.patchValue({ serviceDate: null, serviceTime: '' });
    }
  }

  dateChnageEvent(type: string, event) {
    this.date = event.target.value;
    this.slotsAvl = [];
    this.slotDisbleFlg = true;
    let allAvlSlots = ['5 am - 6 am', '7 am - 8 am', '8 am - 9 am', '10 am - 11 am', '11 am - 12 am', '2 pm - 3 pm', '4 pm - 5 pm', '5 pm - 6 pm', '9 pm - 10 pm', '11 pm - 12 pm'];
    let index = Math.floor(Math.random() * 7);
    if (index == 0) {
      this.slotsAvl = allAvlSlots.splice(0, 3);
    } else
      this.slotsAvl = allAvlSlots.splice(0, index);
  }

  onchangeTime(e) {
    let avlTime = e.target.value;
    if (avlTime != ' ') {
      this.scheduleDisFlg = false;
    }
  }

  //Popup methods Ends Here...


}

const PART_DETAILS_JSON = [
  {
    id: 1,
    description: 'Freezer Temperature Out Of Range',
    proposedSolution: 'Circuit board is not working, needs replacement.',
    partDetails: [
      {
        'RepOEMpart': 'AZTHT7890349',
        'RepPartDescription': 'Circuit Board',
        'vendor': 'Vendor1,USA,Texas,zipcode: 60007',
        'stock': 'Available',
        'stockItems': '15',
        'price': '250.00'
      },
      {
        'RepOEMpart': 'AZTHT7890351',
        'RepPartDescription': 'Circuit Board',
        'vendor': 'Vendor2,USA,Bloomington,zipcode: 47401',
        'stock': 'Available',
        'stockItems': '5',
        'price': '280.00'
      },
      {
        'RepOEMpart': 'AZTHT7890350',
        'RepPartDescription': 'Circuit Board',
        'vendor': 'Vendor3,USA,Chicago,zipcode: 60401',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '290.50'
      },
      {
        'RepOEMpart': 'AZTHT7890354',
        'RepPartDescription': 'Circuit Board',
        'vendor': 'Vendor4,USA,Dallas,zipcode: 48401',
        'stock': 'Available',
        'stockItems': '7',
        'price': '255.67'
      },
      {
        'RepOEMpart': 'AZTHT7890366',
        'RepPartDescription': 'Circuit Board',
        'vendor': 'Vendor5,USA,NewYork,zipcode: 67001',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '250.90'
      }
    ]
  },
  {
    id: 2,
    description: 'Dispense Force Out Of Range',
    proposedSolution: 'Motor is not working, needs replacement.',
    partDetails: [
      {
        'RepOEMpart': 'AZTHT7890349',
        'RepPartDescription': 'Motor',
        'vendor': 'Vendor1,USA,Texas,zipcode: 60007',
        'stock': 'Available',
        'stockItems': '15',
        'price': '250.00'
      },
      {
        'RepOEMpart': 'AZTHT7890351',
        'RepPartDescription': 'Motor',
        'vendor': 'Vendor2,USA,Bloomington,zipcode: 47401',
        'stock': 'Available',
        'stockItems': '5',
        'price': '280.00'
      },
      {
        'RepOEMpart': 'AZTHT7890350',
        'RepPartDescription': 'Motor',
        'vendor': 'Vendor3,USA,Chicago,zipcode: 60401',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '290.50'
      },
      {
        'RepOEMpart': 'AZTHT7890354',
        'RepPartDescription': 'Motor',
        'vendor': 'Vendor4,USA,Dallas,zipcode: 48401',
        'stock': 'Available',
        'stockItems': '7',
        'price': '255.67'
      },
      {
        'RepOEMpart': 'AZTHT7890366',
        'RepPartDescription': 'Motor',
        'vendor': 'Vendor5,USA,NewYork,zipcode: 67001',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '250.90'
      }
    ]
  }, {
    id: 3,
    description: 'Default',
    proposedSolution: 'Thermostat is not working need Replacement.',
    partDetails: [
      {
        'RepOEMpart': 'AZTHT7890349',
        'RepPartDescription': 'Thermostat is malfunctioning',
        'vendor': 'Vendor1,USA,Texas,zipcode: 60007',
        'stock': 'Available',
        'stockItems': '15',
        'price': '250.00'
      },
      {
        'RepOEMpart': 'AZTHT7890351',
        'RepPartDescription': 'Thermostat is malfunctioning',
        'vendor': 'Vendor2,USA,Bloomington,zipcode: 47401',
        'stock': 'Available',
        'stockItems': '5',
        'price': '280.00'
      },
      {
        'RepOEMpart': 'AZTHT7890350',
        'RepPartDescription': 'Thermostat is malfunctioning',
        'vendor': 'Vendor3,USA,Chicago,zipcode: 60401',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '290.50'
      },
      {
        'RepOEMpart': 'AZTHT7890354',
        'RepPartDescription': 'Thermostat is malfunctioning',
        'vendor': 'Vendor4,USA,Dallas,zipcode: 48401',
        'stock': 'Available',
        'stockItems': '7',
        'price': '255.67'
      },
      {
        'RepOEMpart': 'AZTHT7890366',
        'RepPartDescription': 'Thermostat is malfunctioning',
        'vendor': 'Vendor5,USA,NewYork,zipcode: 67001',
        'stock': 'Not Available',
        'stockItems': '',
        'price': '250.90'
      }
    ]
  },
]

interface ServiceTech {
  name: string,
  loc: string[],
  timeSlots: string[]

}
