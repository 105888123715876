import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthResponse } from 'msal';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-land-ev-battery',
  templateUrl: './land-ev-battery.component.html',
  styleUrls: ['./land-ev-battery.component.css']
})
export class LandEvBatteryComponent implements OnInit {
  @Input() assetId: any;
  @Output() selIndex = new EventEmitter<number>();
  interval: any;
  autoRefreshPeriod = environment.operationAutoRefreshPeriodInSeconds * 1000;
  loading = false;
  asset1ID = "26A6K5GA8B";
  // 8AY4BE5N3M assetList= environment.retrieveAssetsListEndPoint

  adtExplorerUrl = "https://explorer.digitaltwins.azure.net/?tid=" + environment.msalConfig.tenantId + "&eid=" + localStorage.getItem('azureDigitalTwinUrl');

  // Link to SOH screen
  sohlink = "http://localhost:4200/battery-SoH";

  //Sandbox API URL
  apiUrl: string = environment.coreApiBaseUrl + environment.landBatteryHealth;
  //assetIdapi :string = environment.coreApiBaseUrl+environment.batteryAssetID;

  //Sandbox ADT URL
  adtUrl = "https://" + localStorage.getItem('azureDigitalTwinUrl') + "/query?api-version=2020-10-31";
  selectedIndex: number;
  adtToken: string;
  //azureDigitalTwinScope = ["8f513527-1e6b-4d54-8456-22abbb0ea1d9"];
  azureDigitalTwinScope = ["https://digitaltwins.azure.net/Read.Write"];
  pdOperationData: any;
  fetchAllAssetUrl = environment.coreApiBaseUrl + environment.retrieveAssetsListEndPoint;
  assetIdList: any[] = [];
  capPosition: any[];
  podInterval: any;
  next: number = 0;
  landOperationData: any;
  cncCompData: any;
  shift: any;
  time: any;
  assetIdArray: any[];
  constructor(private http: HttpClient,
    private auth: MsalService,
    public dialog: MatDialog,
    private sharedService: SharedService) {
  }

  ngOnInit(): void {
    //console.log("Asset List", this.assetIdList);
    this.fetchTokenAndGetPdOperationData();
    this.setDefaultCapPosition();
    this.setDefaultValues();
    this.loadAssetIdsList();
    this.loadPdOperationDataPeriodically();
  }

  get randomNumberInteval() {
    return this.sharedService.randomIntFromInterval(1, 10);
  }

  setDefaultCapPosition() {
    this.capPosition = [{
      name: 'Vanilla',
      value: this.randomNumberInteval
    }, {
      name: 'Strawberry ShortCake',
      value: this.randomNumberInteval
    }, {
      name: 'Chocolate',
      value: this.randomNumberInteval
    }, {
      name: 'Mint & Chocolate',
      value: this.randomNumberInteval
    }, {
      name: 'Cookies & Cream',
      value: this.randomNumberInteval
    }, {
      name: 'Salty Caramel',
      value: this.randomNumberInteval
    }];
  }

  loadPdOperationDataPeriodically() {
    this.interval = setInterval(() => {
      this.fetchTokenAndGetPdOperationData();
    }, this.autoRefreshPeriod);
  }
  chooseAsset(asset: any) {
    // console.log("Asset", asset)
    this.assetId = asset;

  }

  fetchTokenAndGetPdOperationData(assetId?: string) {
    this.auth.acquireTokenSilent(
      { scopes: this.azureDigitalTwinScope }
    )
      .then((response: AuthResponse) => {
        this.adtToken = response.accessToken;
        localStorage.setItem('adtToken', this.adtToken);
        //console.log(this.adtToken);
      }).catch(err => {
        // console.log(err);
      }).then(_ => {
        this.getOperationData(assetId)
          .subscribe(data => {
            this.landOperationData = data;
            // console.log('this.landOperationData', this.landOperationData);
            this.loading = false;
            // let num = this.landOperationData.DischargeEnergy
            // let formated = (Math.round(num * 100) / 100).toFixed(2);
            // let kwh = formated.toString() + "kwh"

          });
      });
  }
  sohTab() {
    this.selectedIndex = 6;
    this.selIndex.emit(this.selectedIndex);
  }

  /**
   * Change value of every flavour after 2 min
   */
  generatePodData() {
    this.podInterval = setInterval(() => {
      if (this.pdOperationData.machineStatus === 'Online') {
        if (this.next >= this.capPosition.length) this.next = 0;
        const current = this.next;
        const value = this.capPosition[current].value
        this.capPosition[current].value = value <= 1 ? 10 : value - 1;
        this.next += 1;
      }
    }, 2 * 60 * 1000);
  }


  getOperationData(assetId?: string): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    let header_node = {
      Accept: 'application/json',
      rejectUnauthorized: 'false',
      'Authorization': 'Bearer ' + idToken
    }

    if (this.assetId === undefined) {
      this.assetId = this.asset1ID;
    } else if (assetId) {
      this.assetId = assetId;
    }
    const body = {
      "fromDate": "",
      "toDate": "",
      "adtUrl": "https://EUWDSRG02YRSG02ADT01.api.weu.digitaltwins.azure.net/query?api-version=2020-10-31",
      "accessToken": this.adtToken,
      "adtQuery": "SELECT * FROM DIGITALTWINS T WHERE T.$dtId = '" + this.assetId + "'"

    };
    // this.assetIdArray= this.landOperationData.assetId;
    //console.log(body)
    return this.http.post(this.apiUrl, body, { 'headers': header_node })
    // console.log("Api Values",this.http.post(this.assetIdapi, body,{'headers':header_node}))

    // return this.http.post(this.assetIdapi, body,{'headers':header_node})

  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    clearInterval(this.podInterval)
  }

  openDialog() {
    window.open(this.adtExplorerUrl, "_blank");
  }
  // batterysoh (){
  //   window.open(this.sohlink)
  // }

  setDefaultValues() {
    this.landOperationData = { "Speed": 34.49, "Trip": 49225.0, "SOC": 90.0, "Ubat": 76.90, "Actual_map": 4.0, "Error": 0.0, "Ibat": 40.15, "App_ID": 10.0, "RPMF": 2215.0, "App_mode": 102.0, "Stat": 0.0, "assetId": "LandEV", "Limit": 16384.0, "ODO": 6878.0, "Iphase": 151.0, "Power": 3002.0, "distToEmpty": "34.65", "DischargeEnergy": "164503.5", "CCCVdistF": "74.67", "DischargeCapacity": "56277.65", "RecoveryVoltage": "2.34", "PseudoResistance": "1.16", "Capacity": "23.22", "CapCoefficientSkewnessacity": "0.34", "DischargeQ": "1.23", "Energy": "0.23", "Entropy": "-1.12", "KurtosisCoefficient": "1.12", "diffVoltage": "0.12", "CoefficientSkewness": "0.42" }
    this.generatePodData(); // Set interval
  }

  loadAssetIdsList() {
    this.getAssetsList().subscribe(res => {
      this.assetIdList = res;
      // console.log("Id list", this.assetIdList)
    })
  }

  getAssetsList(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + idToken

    }
    return this.http.post(this.fetchAllAssetUrl, "", { 'headers': headers })
  }

  onChnageOfAsset(e) {
    const { value, text } = e.target.options[e.target.selectedIndex]
    this.fetchTokenAndGetPdOperationData(text);
  }

}
