<ngx-charts-advanced-pie-chart
  [view]="view"
  [scheme]="colorScheme"
  [results]="single"
  [gradient]="gradient"
  (select)="onSelect($event)"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
  >
</ngx-charts-advanced-pie-chart>
