<!-- <div class="container1"
  [ngStyle]="{'height.px':innerHeight+340,'width.px':innerWidth+140,'min-width.px':innerWidth+90,'overflow-y':'hidden','overflow-x':'hidden'}"> -->
  <div class="container1"
  [ngStyle]="{'width.px':innerWidth+140,'min-width.px':innerWidth+90}">
  <div class="row text-content" [ngStyle]="{'width.px':innerWidth+130}">
    <div class="col-sm-7 text-right text-style1">
      <a>
        <img class="header-logo-image" src="assets/images/PolarDelight.png" width="160px" alt="Logo">
      </a>
      <p>Digital Twin</p>
    </div>
    <!-- <div class="col-sm-4 text-right text-style2">{{from | date | date:'mediumDate'}} to {{to | date | date:'mediumDate'}}</div> -->
    <div class="col-sm-5 text-right text-style2" style="margin-left: -1%;">{{currentDate}}</div>
  </div>
  <mat-tab-group mat-align-tabs="left" dynamicHeight="true" [selectedIndex]="getSelectedIndex()"
    (selectedTabChange)="tabChanged($event)">
    <mat-tab label="Asset Health">
      <ng-template matTabContent>
        <app-pd-operations [assetId]="assetId"></app-pd-operations>
      </ng-template>
    </mat-tab>
    <mat-tab label="Operations Summary" *ngIf='"OpSum" | role:userRole'>
      <ng-template matTabContent>
        <app-plant-performance></app-plant-performance>
      </ng-template>
    </mat-tab>
    <mat-tab label="Predictions" *ngIf='"predctions" | role:userRole'>
      <ng-template matTabContent>
        <app-pd-insights></app-pd-insights>
      </ng-template>
    </mat-tab>
    <mat-tab label="Insights" *ngIf='"Insights" | role:userRole'>
      <ng-template matTabContent>
        <div>
          <app-real-time-feed></app-real-time-feed>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Remote Operations Cockpit" *ngIf='"serDash" | role:userRole'>
      <ng-template matTabContent>
        <div class="row">
          <app-diagnostics></app-diagnostics>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>