<mat-paginator style="display: inline-flex;"  #paginator [pageSizeOptions]="[5,10]" showFirstLastButtons
[length]="dataSourceTotalSize" (page)="onChangePage($event)"></mat-paginator>
<table class="table-container" mat-table [dataSource]="dataSource" matSort id="serviceTcktTbl">

    <ng-container [matColumnDef]="tableData" *ngFor="let tableData of objectKeys(columnHeader)">
         <ng-container *ngIf ="columnHeader[tableData] != 'Status' ">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="margin:0px;"> {{columnHeader[tableData]}} </th>
            <!-- <td mat-cell *matCellDef="let element"> {{element[tableData] }}</td> -->
            <td mat-cell *matCellDef="let element">

              <span *ngIf="columnHeader[tableData] == 'Ticket ID' || columnHeader[tableData] == 'Alert ID'">
                <a class="servceTkct">{{element[tableData]}}</a>
              </span>
              <span *ngIf="columnHeader[tableData] == 'Description'">
                <span style="cursor: pointer;" (click)="changeTab(element[tableData])">{{element[tableData]}}</span>
              </span>
              <span *ngIf="tableData == 'serverEnable'" [ngClass]="element[tableData] == 'Online' ? 'i-am-green-box' : 'i-am-red-box'">
                  {{element[tableData]}}
              </span>
              <span *ngIf="columnHeader[tableData] != 'Description' && columnHeader[tableData] != 'Ticket ID' && tableData != 'serverEnable' && columnHeader[tableData] != 'Alert ID'">
                  {{element[tableData]}}
              </span>
              <!-- <span *ngIf="columnHeader[tableData] == 'Ticket ID'">
                   <a href=''>{{element[tableData]}}</a>
              </span>
              <span *ngIf="columnHeader[tableData] != 'Ticket ID'">
                  {{element[tableData]}}
              </span> -->
         </td>
         </ng-container>
         <ng-container *ngIf ="columnHeader[tableData] == 'Status' " >
          <th mat-header-cell *matHeaderCellDef  style="margin:0px;"  > {{columnHeader[tableData]}}
            <i class="fa fa-filter " id="icon" aria-hidden="true" [matMenuTriggerFor]="status"     >
                <mat-menu #status="matMenu" class="selected" id="items"  >
                  <button mat-menu-item (click)="filterByStatus('All')" id="All">All</button>
                  <button mat-menu-item (click)="filterByStatus('Open')" id="Open">Open</button>
                  <button mat-menu-item (click)="filterByStatus('Closed')" id="Closed">Closed</button>
                  <button mat-menu-item (click)="filterByStatus('Scheduled')" id="Scheduled" >Scheduled</button>
                  <button mat-menu-item (click)="filterByStatus('In Progress')" id="In Progress" >In Progress</button>
                  <button mat-menu-item (click)="filterByStatus('Completed')" id="Completed" >Completed</button>
                </mat-menu>
            </i>
          </th>
          <td mat-cell *matCellDef="let element">
            <span >
                {{element[tableData]}}
            </span>
          </td>
       </ng-container>


    </ng-container>
    <ng-container *ngIf="columnHeader.sla == 'SLA'" matColumnDef="detailsButton" >
        <th mat-header-cell *matHeaderCellDef> Details </th>
        <td mat-cell *matCellDef="let element">
          <a class="btn btn-primary" (click)="redirectToDetails(element)">
            <i class="fa fa-eye" aria-hidden="true" style="color: rgb(255, 217, 0);"></i>
          </a>
        </td>
    </ng-container>

    <ng-container *ngIf="columnHeader.freeFld1 == 'Asset Name'" matColumnDef="detailsButton" >
        <th mat-header-cell *matHeaderCellDef> Details </th>
        <td mat-cell *matCellDef="let element">
          <a class="btn btn-primary" (click)="redirectToDetails(element)">
            <i class="fa fa-eye" aria-hidden="true" style="color: rgb(255, 217, 0);"></i>
          </a>
        </td>
    </ng-container>



    <ng-container *ngIf="columnHeader.folder == 'Asset ID'" matColumnDef="actionButton" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
      <td mat-cell *matCellDef="let element">
        <a class="btnClass btn btn-primary" (click)="editAssetParams(element)">
          <i class="fa fa-edit pl-1 ml-4"></i>
        </a>
      </td>
    </ng-container>


    <span *ngIf="columnHeader.sla == 'SLA'">
      <tr mat-header-row *matHeaderRowDef="objectKeys(columnHeader).concat(['detailsButton'])"  style="height: 20px;"></tr>
      <tr mat-row *matRowDef="let row; columns: objectKeys(columnHeader).concat(['detailsButton']);"></tr>
    </span>
    <span *ngIf="columnHeader.freeFld1 == 'Asset Name'">
      <tr mat-header-row *matHeaderRowDef="objectKeys(columnHeader).concat(['detailsButton'])"  style="height: 20px;"></tr>
      <tr mat-row *matRowDef="let row; columns: objectKeys(columnHeader).concat(['detailsButton']);"></tr>
    </span>
    <span *ngIf="columnHeader.folder == 'Asset ID'">
      <tr mat-header-row *matHeaderRowDef="objectKeys(columnHeader).concat(['actionButton'])"   style="height: 20px;"></tr>
      <tr mat-row *matRowDef="let row; columns: objectKeys(columnHeader).concat(['actionButton']);"></tr>
    </span>


    <span  *ngIf="columnHeader.sla != 'SLA' && columnHeader.folder != 'Asset ID' && columnHeader.freeFld1 != 'Asset Name'">
      <tr mat-header-row *matHeaderRowDef="objectKeys(columnHeader)"  style="height: 20px;"></tr>
      <tr mat-row *matRowDef="let row; columns: objectKeys(columnHeader);"></tr>
    </span>
</table>




<!-- Popup Screens for diff Stages-->

<ng-template #FormTemplate>
  <div class="modal-header h4">
    Service Ticket Details
  </div>
  <div class="modal-body text-center border-left">
    <div class="modalPopupCard modalCancel">
      <div class="modalPopupCardBody card-body srvceTktModal">
        <p>Alert ID :  {{servceTktPopupData[0].alertId}}</p>
        <p>Ticket ID :  {{servceTktPopupData[0].ticketId}}</p>
        <p>Asset Name :  {{servceTktPopupData[0].freeFld1}}</p>
        <p>Description :  {{servceTktPopupData[0].details}}</p>
        <p>Created Time :  {{servceTktPopupData[0].RCreTime}}</p>
        <p>Status :  {{servceTktPopupData[0].status}}</p>
        <p>Modified Time :  {{servceTktPopupData[0].RModTime}}</p>
        <p>SLA :  {{servceTktPopupData[0].sla |  date:'medium'}}</p>
        <p style="color:green;">Proposed Solution : {{servceTktPopupData[0]?.proposedSolution}}</p>
      </div>
      <div class="modal-footer">
        <div class="row float-right">
          <button type="button" class="ml-1 btn btn-info" (click)="close()"> Close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>



