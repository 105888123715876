<combo-chart-component
  [view]="view"
  [scheme]="comboBarScheme"
  [colorSchemeLine]="lineChartScheme"
  [results]="barChart"
  [animations]="animations"
  [lineChart]="lineChartSeries"
  [xAxisTickFormatting]="xAxisTickFormatting"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  [showGridLines]="showGridLines"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [showRightYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [yAxisLabelRight]="yAxisLabelRight">
  
</combo-chart-component>