<div class="container" style="margin-top: 2px;">
    <!-- <div class="row"
      [ngStyle]="{'height.px':innerHeight+80,'min-height.px':innerHeight+150,'width.px':innerWidth+90,'min-width.px':innerWidth+70}"> -->
    <div class="row" [ngStyle]="{'width.px':innerWidth+90,'min-width.px':innerWidth+70}">
      <div class="col-md-5">
        <!-- <div *ngIf="currentUserRole !== 'TV User'" style="text-align: left;" class="row">
          <button style="text-align: left; cursor: pointer; background-color: transparent; border-color: transparent;" (click)="showCustomerInfo()">
            <i class="fa fa-eye pt-2" aria-hidden="true" style="font-size: 12px; color: rgb(209, 209, 209);"></i>
          </button>
        </div> -->
        <div class="row">
          <div *ngIf="!showWidgets" class="col-md-12 pr-2">
            <app-swh-map></app-swh-map>
          </div>
          <div *ngIf="showWidgets" class="col-md-12 pr-2">
            <div class="row">
              <div class="customer-card1 col-md-6">
                <div class="card card-container ">
                  <label
                    style="padding-left: 4%; font-size: 11pt; color: rgb(209, 209, 209);margin-top: 0px;padding-top: 5px;margin-bottom: 0;">Sheetz
                    North</label>
                  <div style="padding-top: 8%;" class="row">
                    <div class="col-md-8">
                      <p
                        style="padding-left: 4%; font-size: 9pt; color: rgb(209, 209, 209);margin-top: 0px;padding-top: 5px;margin-bottom: 0;">
                        Avg Service Cost</p>
                    </div>
                    <div class="col-md-4">
                      <p
                        style="padding-left: 4%; font-size: 9pt; color: rgb(209, 209, 209);margin-top: 0px;padding-top: 5px;margin-bottom: 0;">
                        $250/month</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="customer-card2 col-md-6">
                <div class="card card-container ">
                  <label
                    style="padding-left: 4%; font-size: 11pt; color: rgb(209, 209, 209);margin-top: 0px;padding-top: 5px;margin-bottom: 0;">Sheetz
                    East</label>
                  <div style="padding-top: 8%;" class="row">
                    <div class="col-md-8">
                      <p
                        style="padding-left: 4%; font-size: 9pt; color: rgb(209, 209, 209);margin-top: 0px;padding-top: 5px;margin-bottom: 0;">
                        Avg Service Cost</p>
                    </div>
                    <div class="col-md-4">
                      <p
                        style="padding-left: 4%; font-size: 9pt; color: rgb(209, 209, 209);margin-top: 0px;padding-top: 5px;margin-bottom: 0;">
                        $50/month</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="customer-card1 col-md-6">
                <div class="card card-container ">
                  <label
                    style="padding-left: 4%; font-size: 11pt; color: rgb(209, 209, 209);margin-top: 0px;padding-top: 5px;margin-bottom: 0;">Sheetz
                    West</label>
                  <div style="padding-top: 8%;" class="row">
                    <div class="col-md-8">
                      <p
                        style="padding-left: 4%; font-size: 9pt; color: rgb(209, 209, 209);margin-top: 0px;padding-top: 5px;margin-bottom: 0;">
                        Avg Service Cost</p>
                    </div>
                    <div class="col-md-4">
                      <p
                        style="padding-left: 4%; font-size: 9pt; color: rgb(209, 209, 209);margin-top: 0px;padding-top: 5px;margin-bottom: 0;">
                        $75/month</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="customer-card2 col-md-6">
                <div class="card card-container ">
                  <label
                    style="padding-left: 4%; font-size: 11pt; color: rgb(209, 209, 209);margin-top: 0px;padding-top: 5px;margin-bottom: 0;">Sheetz
                    South</label>
                  <div style="padding-top: 8%;" class="row">
                    <div class="col-md-8">
                      <p
                        style="padding-left: 4%; font-size: 9pt; color: rgb(209, 209, 209);margin-top: 0px;padding-top: 5px;margin-bottom: 0;">
                        Avg Service Cost</p>
                    </div>
                    <div class="col-md-4">
                      <p
                        style="padding-left: 4%; font-size: 9pt; color: rgb(209, 209, 209);margin-top: 0px;padding-top: 5px;margin-bottom: 0;">
                        $400/month</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="pl-3 customer-card2 col-md-12">
                <div class="card table-card-container ">
                  <div>
                    <p
                      style="font-size: 11pt; color: rgb(209, 209, 209);margin-left: 15px;margin-bottom: -11px;text-align: center;">
                      Vendor Details</p>
                    <button class="btn btn-info" style="font-size: 10px; margin-bottom: 5px;margin-left: 15px;"
                      (click)="exporter.exportTable('txt')">Text</button>&nbsp;
                    <button class="btn btn-info" style="font-size: 10px; margin-bottom: 5px;"
                      (click)="exporter.exportTable('xlsx')">Excel</button>&nbsp;
                    <button class="btn btn-info" style="font-size: 10px; margin-bottom: 5px;"
                      (click)="exporter.exportTable('csv')">CSV</button>&nbsp;
                    <!-- <button class="btn btn-info" style="margin-bottom: 5px;margin-top: 5px;"
                (click)="exporter.exportTable('json')">JSON</button> -->
  
  
                    <mat-paginator style="display: inline-flex;" #paginator [pageSizeOptions]="[5,10]"
                      showFirstLastButtons [length]="dataSourceTotalSize" (page)="pageChanged($event)">
                    </mat-paginator>
                  </div>
                  <!-- <div class="paginator-style">
            <mat-paginator style="display: inline-flex;" #paginator [pageSizeOptions]="[7,14]" showFirstLastButtons></mat-paginator>
          </div> -->
                  <table class="table-container" id="criticlTicktsTbl" mat-table [dataSource]="dataSource"
                    matTableExporter #exporter="matTableExporter" matSort>
  
                    <ng-container matColumnDef="VendorId">
                      <th class="pl-0" mat-header-cell *matHeaderCellDef> VendorId </th>
                      <td class="pl-0" mat-cell *matCellDef="let element"> <a class="criticlEvnt">{{element.id}}</a> </td>
                    </ng-container>
  
  
                    <ng-container matColumnDef="VendorName">
                      <th scope="col" mat-header-cell *matHeaderCellDef> VendorName </th>
                      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
  
                    <ng-container matColumnDef="Location">
                      <th scope="col" mat-header-cell *matHeaderCellDef> Location </th>
                      <td mat-cell *matCellDef="let element"> {{element.location}} </td>
                    </ng-container>
  
                    <ng-container matColumnDef="OpenTickets">
                      <th scope="col" mat-header-cell *matHeaderCellDef> OpenTickets</th>
                      <td mat-cell *matCellDef="let element"> {{element.open}} </td>
                    </ng-container>
  
  
                    <ng-container matColumnDef="TotalTickets">
                      <th scope="col" mat-header-cell *matHeaderCellDef> TotalTickets </th>
                      <td mat-cell *matCellDef="let element"> {{element.total}} </td>
                    </ng-container>
  
  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" style="height: 10px;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
                  </table>
                </div>
              </div>
            </div>
          </div>
  
        </div>
        <div class="row">
          <div class="col-md-6 custom-card1">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Total Assets Online'"
              [attributeValue]="'6'" [imageName]="''" [screenName]="'summary1'"></app-custom-number-card>
          </div>
          <div class="col-md-6 custom-card2">
            <app-custom-number-card [cardFooterColor]="'#E44D25'" [attribute]="'Total Assets Offline'"
              [attributeValue]="'0'" [imageName]="" [screenName]="'summary1'"></app-custom-number-card>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="row">
          <div class="col-md-4" style="padding-right: 4px;">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Service Tickets Open'"
              [attributeValue]=serviceTicketsOpen [imageName]="" [screenName]="'summary'"></app-custom-number-card>
          </div>
          <div class="col-md-4" style="padding-right: 4px;">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Avg. Maintenance Spend'"
              [attributeValue]=avgMaintSpend [imageName]="" [screenName]="'summary'"></app-custom-number-card>
          </div>
          <div class="col-md-4" style="padding-right: 0px;">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Avg. Maintenance Time'"
              [attributeValue]=avgMaintTime [imageName]="" [screenName]="'summary'"></app-custom-number-card>
  
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 widget__left">
  
            <div class="widget-1-left">
              <div>
                <p
                  style="font-size: 11pt; color: rgb(209, 209, 209);text-align: center;margin-top: 0px;padding-top: 5px;margin-bottom: 0;">
                  Average Availability</p>
              </div>
              <div *ngIf="!isPieLoad" class="d-flex justify-content-center">
                <!-- <div class="spinner-border" role="status">
                  <span class="sr-only" id="loading"></span>
                </div> -->
                <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image" />
              </div>
              <div *ngIf="isPieLoad" style="margin-top: 30px;">
                <!-- <app-ngx-pie-grid [gridName]="'Availability'"></app-ngx-pie-grid> -->
                <div class="row">
                  <div class="col-sm-6 left-div" style="padding-left: 10px;">
                    <kendo-circulargauge class="green-class" [value]="inputPieGridData" [scale]="{ max: 100 }"
                      [colors]="colors" [style.height.px]="height" [style.width.px]="width">
                      <ng-template kendoCircularGaugeCenterTemplate let-value="value">
                        {{ value }}% <br>UpTime
                      </ng-template>
                    </kendo-circulargauge>
                  </div>
                  <div class="col-sm-6 left-div" style="padding-right: 10px;">
                    <kendo-circulargauge class="red-class" [value]="inputPieGridData1" [scale]="{ max: 100 }"
                      [colors]="colors" [style.height.px]="height" [style.width.px]="width">
                      <ng-template kendoCircularGaugeCenterTemplate let-value="value">
                        {{ value }}% <br>DownTime
                      </ng-template>
                    </kendo-circulargauge>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-sm-6 text-right">Total(hrs):100</div>
                  <div class="col-sm-6 text-left">Total(hrs): 20</div>
                </div>
  
              </div>
            </div>
          </div>
          <div class="col-md-6 widget__right" style="margin-bottom: 5px;">
            <div class="widget-1">
              <p
                style="font-size: 11pt; color: rgb(209, 209, 209);text-align: center;margin-top: 0px;padding-top: 5px;margin-bottom: 0;">
                Average Energy
                Consumption</p>
              <div *ngIf="loadingSpin" class="d-flex justify-content-center" style='height:150px;'>
                <!-- <div class="spinner-border" role="status">
                  <span class="sr-only" id="loading"></span>
                </div> -->
                <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image" />
              </div>
              <div *ngIf="isParetoLoaded" style="margin-left: 3px;">
                <app-vertical-bar-chart [results]="barChartInput">
                </app-vertical-bar-chart>
                <!-- <app-ngx-pareto-chart [barChartData]="barChartInput" [lineChartData]="lineChartInput"
                  [screenName]="'summary'"></app-ngx-pareto-chart> -->
  
              </div>
  
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 widget__left">
            <div class="widget-2">
              <div>
                <p
                  style="font-size: 11pt; color: rgb(209, 209, 209);text-align: center;margin-top: 0px;padding-top: 2px;margin-bottom: 0;">
                  Maintenance Type</p>
              </div>
              <div>
                <div *ngIf="!isPieLoad" class="d-flex justify-content-center">
                  <!-- <div class="spinner-border" role="status">
                  <span class="sr-only" id="loading"></span>
                </div> -->
                  <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image" />
                </div>
                <div style="margin-top: -25px;">
                  <app-chartjs-pie *ngIf="isPieLoad" [maintenancePlanned]="maintenancePlanned"
                    [maintenanceBreakdown]="maintenanceBreakdown" [maintenancePredictive]="maintenancePredictive">
                  </app-chartjs-pie>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 widget__right">
            <div class="widget-2">
              <div>
                <p
                  style="font-size: 11pt; color: rgb(209, 209, 209);text-align: center;margin-top: 0px;padding-top: 2px;margin-bottom: 0;">
                  Critical Parts Inventory</p>
              </div>
              <div *ngIf="!isPieLoad" class="d-flex justify-content-center" style='height:150px;'>
                <!-- <div class="spinner-border" role="status">
                  <span class="sr-only" id="loading"></span>
                </div> -->
                <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image" />
              </div>
              <div>
                <app-ngx-pie-grid *ngIf="isPieLoad" [gridName]="'Maintenance'" [inputPieGridStock]="inputPieGridStock"
                  [inputPieGridConsumed]="inputPieGridConsumed"></app-ngx-pie-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </div>