 <!-- <p> ELMP</p>
 <p>{{elmP[1] }} </p> -->
 <div class="parent">
  <div class="child"  >
    <!-- <p>P</p> -->
  <kendo-chart style="border-radius: 10%; " >
      <kendo-chart-title text="SoH: DECVD" color="#FFFFFF"> </kendo-chart-title>
      <kendo-chart-legend position="bottom" orientation="horizontal" background="white"></kendo-chart-legend>
      <kendo-chart-area background="#222222"></kendo-chart-area>
      <kendo-chart-value-axis>
        <kendo-chart-value-axis-item [title]="{ text: 'SOH (%)' }" color="#FFFFFF">
        </kendo-chart-value-axis-item>
      </kendo-chart-value-axis>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item [title]="{ text: 'Cycle' }" [labels]="{step:100} " [majorTicks]="{step:100} "
          [categories]="categoryLabels" color="#FFFFFF">
        </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
      <kendo-chart-series>
        <kendo-chart-series-item type="line" [data]="elmP" [markers]="{ visible:false }" [name]="rollingL.Predicted">
        </kendo-chart-series-item>
        <kendo-chart-series-item type="line" [data]="elmA" [markers]="{ visible:false }" [name]="rollingL.Actual">
        </kendo-chart-series-item>
        <kendo-chart-series-item type="line" [data]="rollingR" [markers]="{ visible:false }">
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
  </div>
  <div class="child rightMargin">
    <kendo-chart>
      <kendo-chart-title text="SoH: Autoregressive" color="#FFFFFF"></kendo-chart-title>
      <kendo-chart-legend position="bottom" orientation="horizontal" background="white"></kendo-chart-legend>
      <kendo-chart-area background="#222222"> </kendo-chart-area>
      <kendo-chart-series>
        <kendo-chart-series-item type="line" [data]="rollingP" [markers]="{ visible:false }" [labels]="{skip:100}"
          [name]="rollingL.Predicted  ">
        </kendo-chart-series-item>
        <kendo-chart-series-item type="line" [data]="rollingA" [markers]="{ visible:false }" [name]="rollingL.Actual">
        </kendo-chart-series-item>
        <kendo-chart-series-item type="line" [data]="rollingR" [markers]="{ visible:false }">
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item [title]="{ text: 'Cycle' }" [categories]="categoryLabels" [labels]="{step:100} "
          [majorTicks]="{step:100}" color="#FFFFFF">
        </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
      <kendo-chart-value-axis>
        <kendo-chart-value-axis-item [title]="{ text: 'SOH (%)' }" color="#FFFFFF">
        </kendo-chart-value-axis-item>
      </kendo-chart-value-axis>
    </kendo-chart>
  </div>

</div>
<div class="parent top-margin">
  <div class="child">
    <kendo-chart [title]="{ text: 'RUL: ET', color:'#FFFFFF'}" [seriesColors]="['#0000FF' ]">
      <kendo-chart-area background="#222222" font-color="#FFFFFF"> </kendo-chart-area>
      <kendo-chart-series>
        <kendo-chart-series-item type="scatter" [data]="RUL_ET" xField="Actual" yField="Predicted" color="blue">
        </kendo-chart-series-item>
        <kendo-chart-series-item type="scatterLine" width="3" [data]=" RUL_ET_Ref" xField="Actual" yField="Predicted"
          dashType="dash" color="red" [markers]="{visible:false } ">
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-x-axis>
        <kendo-chart-x-axis-item [title]="{ text: 'Actual' }" color="#FFFFFF">
        </kendo-chart-x-axis-item>
      </kendo-chart-x-axis>
      <kendo-chart-y-axis>
        <kendo-chart-y-axis-item [title]="{ text: 'Predicted' }" color="#FFFFFF">
        </kendo-chart-y-axis-item>
      </kendo-chart-y-axis>
    </kendo-chart>

  </div>
  <div class="child rightMargin">
    <kendo-chart [pannable]="true" [zoomable]="true">
      <kendo-chart-title text="SoH: Nature paper" color="#FFFFFF"></kendo-chart-title>
      <kendo-chart-area background="#222222"> </kendo-chart-area>
      <kendo-chart-legend position="bottom" orientation="horizontal" background="white"></kendo-chart-legend>
      <!-- <kendo-chart-area background="#222222" font-color="#FFFFFF"> </kendo-chart-area> -->
      <kendo-chart-series>
        <kendo-chart-series-item type="rangeArea" opacity="2" [data]="RF_data" fromField="y_low_calibrated"
          toField="y_up_calibrated" categoryField="x_axis" color="#FADA5E" name="&#177;2&#963; ">
        </kendo-chart-series-item>
        <kendo-chart-series-item type="line" [data]="RF_Pred" [markers]="{ visible:false }" dashType="dashDot"
          color="#0000FF" width="2" name="Predicted Capacity">
        </kendo-chart-series-item>
        <kendo-chart-series-item type="line" width="1" [data]="RF_True" [markers]="{ visible:false }" dashType="dashDot"
          width="2" color="#FF0000" name="True Capacity">
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item [title]="{ text: 'Cycle' }" [categories]="categoryLabels" [labels]="{step:10} "
          [majorTicks]="{step:10}" color="#FFFFFF">
        </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
      <kendo-chart-value-axis>
        <kendo-chart-value-axis-item [title]="{ text: 'Capacity [mAh]'} "  color="#FFFFFF">
        </kendo-chart-value-axis-item>
      </kendo-chart-value-axis>
    </kendo-chart>
  </div>
</div>


<!-- <div class="parent">
  <div class="child">
    <kendo-chart [title]="{ text: ' RUL KNN: Actual vs Predicted', color:'#FFFFFF'}" [seriesColors]="['#0000FF' ]">
      <kendo-chart-area background="#222222" font-color="#FFFFFF"> </kendo-chart-area>
      <kendo-chart-series>
        <kendo-chart-series-item type="scatter" [data]="RUL_KNN" xField="Actual" yField="Predicted" color="blue">
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-x-axis>
        <kendo-chart-x-axis-item [title]="{ text: 'Actual' }" color="#FFFFFF">
        </kendo-chart-x-axis-item>
      </kendo-chart-x-axis>
      <kendo-chart-y-axis>
        <kendo-chart-y-axis-item [title]="{ text: 'Predicted' }" color="#FFFFFF">
        </kendo-chart-y-axis-item>
      </kendo-chart-y-axis>
    </kendo-chart>

  </div>
  <div class="child">
    <kendo-chart [title]="{ text: ' RUL RF: Actual vs Predicted', color:'#FFFFFF'}" [seriesColors]="['#0000FF' ]">
      <kendo-chart-area background="#222222" font-color="#FFFFFF"> </kendo-chart-area>
      <kendo-chart-series>
        <kendo-chart-series-item type="scatter" [data]="RUL_RF" xField="Actual" yField="Predicted" color="blue">
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-x-axis>
        <kendo-chart-x-axis-item [title]="{ text: 'Actual' }" color="#FFFFFF">
        </kendo-chart-x-axis-item>
      </kendo-chart-x-axis>
      <kendo-chart-y-axis>
        <kendo-chart-y-axis-item [title]="{ text: 'Predicted' }" color="#FFFFFF">
        </kendo-chart-y-axis-item>
      </kendo-chart-y-axis>
    </kendo-chart>

  </div>
  <div class="child rightMargin">
    <kendo-chart [title]="{ text: ' RUL SVR: Actual vs Predicted', color:'#FFFFFF'}" [seriesColors]="['#0000FF' ]">

      <kendo-chart-area background="#222222" font-color="#FFFFFF"> </kendo-chart-area>
      <kendo-chart-series>
        <kendo-chart-series-item type="scatter" [data]="RUL_SVR" xField="Actual" yField="Predicted" color="blue">
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-x-axis>
        <kendo-chart-x-axis-item [title]="{ text: 'Actual' }" color="#FFFFFF">
        </kendo-chart-x-axis-item>
      </kendo-chart-x-axis>
      <kendo-chart-y-axis>
        <kendo-chart-y-axis-item [title]="{ text: 'Predicted' }" color="#FFFFFF">
        </kendo-chart-y-axis-item>
      </kendo-chart-y-axis>
    </kendo-chart>
  </div>
</div> -->
