import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SharedService {

    constructor() { }

    getFormattedDate(date) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return day + '/' + month + '/' + year;
    }

    get randonOrderNumber() {
        return ((Math.floor(1000 + Math.random() * 900000)) * 9999).toString();
    }

    randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }
}