import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-widget',
  templateUrl: './card-widget.component.html',
  styleUrls: ['./card-widget.component.css']
})
export class CardWidgetComponent implements OnInit {
  @Input() widgetName : string;
  @Input() leftFooterName : string;
  @Input() rightFooterName : string;
  @Input() widgetType : string;
  @Input() widgetData : any;
  @Input() min : string;
  @Input() max : string;
  @Input() type: string;
  @Input() module: string;

  footerColor = "red";
  widget_1: string[] = ['Max RAM Load', 'Power Supply', 'Hydraulic Pressure', 'Coolant Capacity', 'Hydraulic Oil NAS', 'Water Usage', 'Avg energy Usage/day', 'Actual Current', 'Thermostat', 'Power','Battery Charge','Bike Speed','Bike Power','Bike RPM','Bike Current','Battery Voltage','Trip','Odometer','Battery Current','App Mode','Distance To Empty','CCCVdistT','Capacity','Coefficient Skewness','DischargeQ','Energy','Entropy','Kurtosis Coefficient','Discharge Capacity','Recovery Voltage','Differential Voltage'];
  widget_2: string[] = ['Cycle Status', 'Charge Cycle Status'];
  widget_3: string[] = ['RAM Position'];
  widget_4: string[] = ['Cap Position', 'Temperature'];
  widget_5: string[] = [];

  constructor() { }

  ngOnInit(): void {
    // console.log("SOC",this.widgetName);
  }



}
