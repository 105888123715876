import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthResponse } from 'msal';
import { MatDialog } from '@angular/material/dialog';
import {
  Asset,
  EvOperationData,
  EvOperationNumberCard,
  EvOperationCard,
  EV_NUMBER_CARD_DATA,
  EV_OPERATION_CARD_DATA,
} from './ev-operation.model';
import { EvOperationService } from './ev-operation.service';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { HttpService } from '../../services/http.service';
import { share } from 'rxjs/operators';

@Component({
  selector: 'app-ev-operations',
  templateUrl: './ev-operations.component.html',
  styleUrls: ['./ev-operations.component.css'],
  providers: [EvOperationService],
})
export class EvOperationsComponent implements OnInit, OnDestroy {
  @Input() assetId: any;
  status: boolean = true;
  location: string; // = "Cleveland, OH, USA, 44101";
  interval: any;
  loading = false;
  podId = 'EV_01';
  evOperationData: EvOperationData = this.evOperationService.defaultValue; // default data
  assetIdList: Asset[];
  evOperationNumberCardsData: EvOperationNumberCard[] = EV_NUMBER_CARD_DATA;
  evOperationCardsData: EvOperationCard[] = EV_OPERATION_CARD_DATA;
  screenName: string = 'operation';
  digitalTwinUrl = localStorage.getItem('azureDigitalTwinUrl');
  adtUrl: string =
    'https://' + this.digitalTwinUrl + '/query?api-version=2020-10-31';
  VEHICLES_STATUS_LIST: VehicleList[] = [];
  apiUrlOnlineOfflineStatus: string = environment.fetchAllVehiclesEndPoint;
  estimateRange: number = 80;
  innerWidth: number;
  innerHeight: number;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth-200;
    this.innerHeight = window.innerHeight-150;
  }
  constructor(
    public dialog: MatDialog,
    private evOperationService: EvOperationService,
    private http: HttpService
  ) {}

  ngOnInit(): void {
    this.onResize();
    this.assetsStatus();
    // TODO: Uncomment it later after implement API
    this.fetchTokenAndGetevOperationData(true, this.assetId);
    this.loadevOperationDataPeriodically();
  }
  

  loadevOperationDataPeriodically() {
    this.interval = setInterval(() => {
      // Only when device if online
      if(this.status) {
        // Static value 0
        this.evOperationService.resetNeedleValueData.forEach(key => {
          this.evOperationData[key]['needleValue'] = 0;
        });
      }
      this.fetchTokenAndGetevOperationData();
    }, this.evOperationService.autoRefreshPeriod);
  }

  fetchTokenAndGetevOperationData(
    onload: boolean = false,
    assetId?: string,
    status?: boolean,
    location?: string
  ) {
    this.evOperationService
      .getToken()
      .then((response: AuthResponse) => {
        this.evOperationService.updateLocalStorageItem(
          'adtToken',
          response.accessToken
        );
      })
      .then((_) => {
        this.getOperationData(assetId).subscribe((result) => {
          const evOperationData = result;
          // if(onload) {
          //   const setScaleRange = (key, widgetName) => {
          //     return this.evOperationCardsData.map(item => {
          //       if(item.widgetName === widgetName) {
          //         item.scaleRange = this.createGaugeColorData(evOperationData[key]);
          //       }
          //       return item;
          //     });
          //   }
          //   Object.keys(evOperationData).forEach(key => {
          //     if(key === 'bikeOdometerInMiles') {
          //       this.evOperationCardsData = setScaleRange(key, 'Odometer');
          //     } else if(key === 'bikeRpm') {
          //       this.evOperationCardsData = setScaleRange(key, 'Speed');
          //     }
          //   })
          // }
          // Location and machineStatus is static
          evOperationData.machineStatus = this.status ? 'Online' : 'Offline';
          // evOperationData.assetLocation = this.location;
          if(evOperationData.RideMode == "3") {
            evOperationData.RideMode = "Economy";
          }
          const rideModeThrottleKey = 'rideModeThrottlePercentage';
          const batteryChargePercentageKey = 'soCPercentage';
          const batteryPowerKey = 'batteryPowerInWatts';
          const batteryTemperatureKey = 'batteryTemperature';
          const estimateRange = 'estimateRange';
          // const rideModeThrottlePercentage =
          //   +evOperationData[rideModeThrottleKey][rideModeThrottleKey] / 100;
          // evOperationData[rideModeThrottleKey] = {
          //   ...evOperationData[rideModeThrottleKey],
          //   [rideModeThrottleKey]: rideModeThrottlePercentage,
          //   status: this.getPercentageStatus(rideModeThrottlePercentage),
          // };
          const estimateRangeRandom = this.getEstimateRage;
          evOperationData[estimateRange] = {
            status: this.getPercentageStatus(estimateRangeRandom),
            [estimateRange]: estimateRangeRandom,
          }
          evOperationData[rideModeThrottleKey].status = this.getPercentageStatus(
            evOperationData[rideModeThrottleKey][rideModeThrottleKey]
          );
          evOperationData[batteryChargePercentageKey].status =
            this.getPercentageStatus(
              evOperationData[batteryChargePercentageKey][batteryChargePercentageKey]
            );
          evOperationData[batteryPowerKey].status = this.getMinStatus(
            evOperationData[batteryPowerKey].min,
            evOperationData[batteryPowerKey][batteryPowerKey]
          );
          evOperationData[batteryTemperatureKey].status = this.getMinStatus(
            evOperationData[batteryTemperatureKey].min,
            evOperationData[batteryTemperatureKey][batteryTemperatureKey]
          );
          this.evOperationData = evOperationData;
          this.loading = false;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setLocation() {
    this.VEHICLES_STATUS_LIST.forEach((element) => {
      if (element.vehicleName === this.assetId) {
        this.status = element.status;
        this.location = element.address;
      }
    });
  }

  getOperationData(assetId: string): Observable<EvOperationData> {
    if (this.assetId === undefined) {
      this.assetId = this.podId;
    }
    if(!!this.VEHICLES_STATUS_LIST.length) {
      this.setLocation();
    } else {
      this.assetsStatus();
    }
    const accessToken = this.getLocalstorageItem('adtToken');
    const headers = {
      rejectUnauthorized: 'false',
    };
    const body = {
      fromDate: '',
      toDate: '',
      adtUrl: this.adtUrl,
      accessToken,
      adtQuery:
        "SELECT * FROM DIGITALTWINS T WHERE T.$dtId = '" + this.assetId + "'",
    };
    return this.http
      .post(environment.evOperationsEndPoint, body, { headers })
      .pipe(share());
    // return of(this.defaultValue);
  }
  getLocalstorageItem(key) {
    return localStorage.getItem(key);
  }

  getMinStatus(min, value) {
    return value > min ? 'OK' : 'FAULT';
  }

  getPercentageStatus(percentage) {
    return percentage > 35 ? 'OK' : 'FAULT';
  }

  get getEstimateRage() {
    if(this.estimateRange <=20) {
      this.estimateRange = 80;
    } else {
      this.estimateRange -= 5;
    }
    return this.estimateRange;
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  openDialog() {
    window.open(this.evOperationService.adtExplorerUrl, '_blank');
  }

  loadAssetIdsList() {
    this.evOperationService.getAssetsList().subscribe((res) => {
      // console.log('res', res);
      this.assetIdList = res;
    });
  }

  onChnageOfAsset(e) {
    console.log('event', e.target.options[e.target.selectedIndex]);
    const { value, text } = e.target.options[e.target.selectedIndex];
    this.assetId = text;
    this.fetchTokenAndGetevOperationData(false, text);
  }

  assetsStatus() {
    this.getAssetsStatus().subscribe(
      (response) => {
        this.VEHICLES_STATUS_LIST = response;
        this.setLocation();
      },
      (error) => {
        console.log('There Was A Problem');
      }
    );
  }
  getAssetsStatus(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    };
    return this.http.post(this.apiUrlOnlineOfflineStatus, '', {
      headers: headers,
    });
  }

  // createGaugeColorData(card) {
  //   if(!card || !card.needleValue) {
  //     return false;
  //   }
  //   if(card.needleValue <= 100) {
  //     return DEFAULT_RANGE;
  //   }
  //   const colors: string[] = ['red', 'orange', 'yellow', 'lightgreen', 'green'];
  //   const count = card.needleValue / colors.length;
  //   let prev = 0;
  //   return colors.map((color, i) => {
  //     let next = count * (i + 1);
  //     if(i === 0) {
  //       next = next - (count * 15 / 100); // On 0 position subtract 15%
  //     } else {
  //       next = next + (count * (i + 1) / 100); // Add extra according to index
  //     }
  //     let obj = {
  //       from: prev,
  //       to: next,
  //       color
  //     };
  //     prev = next;
  //     return obj;
  //   });
  // }
}

export interface VehicleList {
  id: number;
  address: string;
  latitude: string;
  longitude: string;
  status: boolean;
  vehicleName: string;
}
