import { Component, Input, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
@Component({
  selector: 'app-ngx-pareto-chart',
  templateUrl: './ngx-pareto-chart-component.html',
  styleUrls: ['./ngx-pareto-chart-component.css'],
})
export class NgxParetoChartComponent implements OnInit {
  scrHeight: any;
  scrWidth: any;
  @Input() barChartData: any;
  @Input() lineChartData: any;
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.screenName == 'prediction') {
      if (this.scrWidth > 641 && this.scrWidth <= 768) {
        this.view = [300, 300];
      } else if (this.scrWidth > 768 && this.scrWidth <= 834) {
        this.view = [300, 300];
      }
       else if (this.scrWidth > 834 && this.scrWidth <= 961) {
        this.view = [300, 300];
      }
       else if (this.scrWidth > 961 && this.scrWidth <= 1025) {
        this.view = [215, 300];
      } else if (this.scrWidth > 1025 && this.scrWidth <= 1112) {
        this.view = [215, 300];
      }
    } else {
      if (this.scrWidth > 641 && this.scrWidth <= 768) {
        this.view = [280, 200];
      } else if (this.scrWidth > 768 && this.scrWidth <= 834) {
        this.view = [280, 200];
      }
      else if (this.scrWidth > 834 && this.scrWidth <= 961) {
        this.view = [280, 200];
      } else if (this.scrWidth > 961 && this.scrWidth <= 1025) {
        this.view = [215, 200];
      } else if (this.scrWidth > 1025 && this.scrWidth <= 1112) {
        this.view = [215, 200];
      }
    }
  }
  ngOnInit(): void {
    //console.log("lineChartData: "+this.lineChartData)
    this.onOrientationChange();
    if (this.screenName == 'prediction') {
      if (this.scrWidth > 641 && this.scrWidth <= 961) {
        this.view = [215, 300];
      } else if (this.scrWidth > 961 && this.scrWidth <= 1025) {
        this.view = [300, 300];
      } else if (this.scrWidth > 1025 && this.scrWidth <= 1112) {
        this.view = [300, 300];
      } else if (this.scrWidth > 1112 && this.scrWidth <= 1600) {
        this.view = [355, 300];
      } 
      else {
        this.view = [520, 300];
      }
      this.barChart = this.barChartData;
      this.lineChartSeries = [
        {
          name: 'Cumulative Percentage',
          series: this.lineChartData,
        },
      ];
      this.xAxisLabel = 'Problem Cause';
      this.yAxisLabel = 'Count';
      this.xAxisTickFormatting(this);
    } else {
      this.barChart = this.barChartData;
      this.lineChartSeries = [
        {
          name: 'Power(%age)',
          series: this.lineChartData,
        },
      ];
      this.xAxisLabel = 'Location';
      this.yAxisLabel = 'Power Consumption(Kwh)';
      if (this.scrWidth > 641 && this.scrWidth <= 961) {
        this.view = [215, 200];
      } else if (this.scrWidth > 961 && this.scrWidth <= 1025) {
        this.view = [280, 200];
      } else if (this.scrWidth > 1025 && this.scrWidth <= 1112) {
        this.view = [280, 200];
      } else if (this.scrWidth > 1025 && this.scrWidth <= 1400) {
        this.view = [360, 200];
      } else if (this.scrWidth > 1400 && this.scrWidth <= 1590) {
        this.view = [400, 230];
      } else if (this.scrWidth > 1599 && this.scrWidth <= 1700) {
        this.view = [440, 230];
      } else if (this.scrWidth > 1700 && this.scrWidth < 1800) {
        this.view = [480, 300];
      } else {
        this.view = [520, 300];
      }
    }
  }
  @Input() screenName: string;
  view: any[];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  legendTitle = 'Legend';
  legendPosition = 'right';
  showXAxisLabel = true;
  xAxisLabel: string;
  showYAxisLabel = true;
  yAxisLabel: string;
  showGridLines = true;
  innerPadding = '10%';
  animations: boolean = true;
  barChart: any[];
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  rotateXAxisTicks = true;
  maxXAxisTickLength = 16;
  maxYAxisTickLength = 16;

  lineChartSeries: any[];

  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5'],
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    //domain: ['#01579b']
    domain: ['#085f01'],
  };

  showRightYAxisLabel: boolean = true;
  // yAxisLabelRight: string = 'Utilization';
  yAxisLabelRight: string = '';

  xAxisTickFormatting(val) {
    var ttl = document.getElementById('mine');
    if (ttl != null) {
      for (let i = 0; i < ttl.getElementsByTagName('title').length; i++) {
        // console.log("Value: "+ val)
        //console.log("ttl: "+ ttl.getElementsByTagName("title")[i].innerHTML)
        if (
          ttl.getElementsByTagName('title')[i].innerHTML.substring(0, 14) ==
          val.toString().substring(0, 14)
        ) {
          // console.log("Condition Matched................")
          ttl.getElementsByTagName('title')[i].innerHTML = val;
        }
      }
    }

    return val;
  }
}

export let lineChartSeries1 = [
  {
    name: 'Power',
    series: [
      {
        name: 'USA',
        value: 50,
      },
      {
        value: 80,
        name: 'United Kingdom',
      },
      {
        value: 85,
        name: 'France',
      },
      {
        value: 90,
        name: 'Japan',
      },
    ],
  },
];
// export let lineChartSeries2 = [
//   {
//     name: 'Power',
//     series: [
//       {
//         name: 'New York',
//         value: 50
//       },
//       {
//         value: 80,
//         name: 'Los Angeles'
//       },
//       {
//         value: 85,
//         name: 'Birmingham'
//       },
//       {
//         value: 90,
//         name: 'montgomery'
//       },
//       {
//         value: 95,
//         name: 'Chicago'
//       },
//       {
//         value: 100,
//         name: 'Houston'
//       }
//     ]
//   }
// ];

export let barChart1: any = [
  {
    name: 'Cup stuck',
    value: 28,
  },
  {
    name: 'Unit out of service- Not dispencing',
    value: 19,
  },
  {
    name: 'Low product warning',
    value: 16,
  },
  {
    name: 'Humidity sensor near threshold',
    value: 11,
  },
  {
    name: 'Dispense pressure',
    value: 5,
  },
  {
    name: 'freezing temperature High/Low',
    value: 3,
  },
];
// export let barChart2: any = [
//   {
//     name: 'New York',
//     value: 28
//   },
//   {
//     name: 'Los Angeles',
//     value: 19
//   },
//   {
//     name: 'Birmingham',
//     value: 16
//   },
//   {
//     name: 'Montgomery',
//     value: 11
//   },
//   {
//     name: 'Chicago',
//     value: 5
//   },
//   {
//     name: 'Houston',
//     value: 3
//   }
// ];
