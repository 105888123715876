<mat-paginator style="display: inline-flex;" #paginator [pageSizeOptions]="[10,20]" showFirstLastButtons></mat-paginator>
<table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.id}}</td>
    </ng-container>


    <ng-container matColumnDef="assetId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Asset ID </th>
      <td mat-cell *matCellDef="let element"> {{element.assetId}} </td>
    </ng-container>

    
    <ng-container matColumnDef="serverenableflag">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Server Enable Flag</th>
      <td mat-cell *matCellDef="let element; let i=index">
        <!-- <div class="custom-control custom-switch custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="customSwitchDelFlag1">
            <label class="custom-control-label" for="customSwitchDelFlag1"></label>
        </div> -->
        <mat-checkbox (change)="updateCheckedList($event, element)" 
                      [checked]="element.serverenableflag"></mat-checkbox>
      </td>
    </ng-container>
   

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <br>
  <button class="btn btn-info" type="submit" (click)="updateDelFlag()">Trigger Now</button>
  <p *ngIf="sumilatedReadyFlag" style="align-items: center;">{{simulatedDeviceStatus}} </p>
  
  
