<div class="tsi-content" style="height: 100%; position: relative; overflow: hidden;">
  <div>
    <mat-slide-toggle style="padding-left: 5px; margin-top: 5px;" [(ngModel)]="isAutoRefreshTriggered" (change)="onAutoRefreshToggle($event)">Auto Refresh</mat-slide-toggle>
  </div>
  <div id="availability" style="width: 100%; height: 200px; margin-top: 40px; background: black"></div>
  <div id="intervalSliderWrapper"
    style="width: 300px; height: 40px; position: absolute; margin-top: -40px; font-size: 12px; margin-left: 50px;">
    Interval Size
    <div style="position: relative; top: 22px; left: 20px;" id="intervalSlider"></div>
  </div>
  <div id="hierarchy" style="width: 300px; height: calc(100% - 240px);background: black; border: 1px solid #ccc;">
  </div>
  <div id="chart1"
    style="width: calc(100% - 300px); height:258px;position: absolute; left: 300px; top: 240px; background: black; border: 1px solid #ccc;">
  </div>
  <div id="eventsWrapper"
    style="position: absolute; top: 0; right: 0; width: 80%; height: calc(100% - 40px); transform: translateX(100%); transition: .4s all ease; background: gray; border: 1px solid silver; z-index: 10; box-shadow: -4px 0px 4px 0px rgba(0,0,0,0.2);">
    <div style="position: absolute; top: 8px; left: 20px;"><h3>Events</h3></div>
    <!-- <div id="modalClose" (click)="hideEvents()" style="z-index: 11;">&times;</div> -->
    <button id="modalClose" class="event-close-button" (click)="hideEvents()" style="z-index: 12;">Close</button>
    <div id="events"
      style="width: 100%; height: calc(100% - 40px); margin-top: 40px; position: absolute; padding: 20px;">
    </div>
  </div>
</div>
