import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataService } from '../data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-smart-water-heater',
  templateUrl: './smart-water-heater.component.html',
  styleUrls: ['./smart-water-heater.component.css']
})
export class SmartWaterHeaterComponent implements OnInit, OnDestroy {
  currentDate = this.datepipe.transform(new Date, 'medium');
  gaugeInputData1 = [
    {
      "name": "RAM Speed",
      "value": 65
    }
  ];

  gaugeInputData2 = [
    {
      "name": "Freezer Temperature",
      "value": 85
    }
  ];

  gaugeInputData3 = [
    {
      "name": "Dispense Pressure",
      "value": 1500
    }
  ];

  gaugeInputData4 = [
    {
      "name": "Cycle Time",
      "value": 55
    }
  ];

  lineChartInputData = [
    {
      "name": "PD01",
      "series": [
        {
          "name": "1990",
          "value": 100
        },
        {
          "name": "1991",
          "value": 500
        },
        {
          "name": "2010",
          "value": 800
        },
        {
          "name": "2011",
          "value": 650
        },
        {
          "name": "1990",
          "value": 500
        },
        {
          "name": "2010",
          "value": 20000
        },
        {
          "name": "2010",
          "value": 10
        },
        {
          "name": "2010",
          "value": 15000
        },
        {
          "name": "2010",
          "value": 2500
        },
        {
          "name": "2010",
          "value": 2000
        },
        {
          "name": "2010",
          "value": 100
        },
        {
          "name": "2010",
          "value": 2500
        },
        {
          "name": "2010",
          "value": 15000
        },
        {
          "name": "2011",
          "value": 1000
        }
      ]
    },

    {
      "name": "PD02",
      "series": [
        {
          "name": "1990",
          "value": 1000
        },
        {
          "name": "2010",
          "value": 2500
        },
        {
          "name": "2011",
          "value": 311000000
        }
      ]
    },

    {
      "name": "PD03",
      "series": [
        {
          "name": "1990",
          "value": 6500
        },
        {
          "name": "2010",
          "value": 4000
        },
        {
          "name": "2011",
          "value": 1000
        }
      ]
    },
    {
      "name": "PD04",
      "series": [
        {
          "name": "1990",
          "value": 200
        },
        {
          "name": "2010",
          "value": 5000
        }
      ]
    }
  ];

  cardData1 = [
    {
      "name": "Machine Status",
      "value": "Online"
    }
  ];

  cardData2 = [
    {
      "name": "POD Code",
      "value": "85025"
    }
  ];

  cardData3 = [
    {
      "name": "Pod Back",
      "value": "Up"
    }
  ];

  cardData4 = [
    {
      "name": "Cap Home",
      "value": "Down"
    }
  ];

  cardData5 = [
    {
      "name": "Bin Door",
      "value": "Open"
    }
  ];

  cardData6 = [
    {
      "name": "Cup Detect",
      "value": "No"
    }
  ];

  progressBar1 = "75";

  progressBar2 = "55";
  to: Date;
  from: Date;
  isOperationSummary: any = true;
  currentTabIndex: number = 0;
  assetId: any;
  userRole = "VP of Operations";
  constructor(private router: Router,
    private route: ActivatedRoute, private service: DataService, private datepipe: DatePipe) {
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    this.from = new Date(curr.setDate(first));
    this.to = new Date(curr.setDate(last));
  }
  innerWidth: any;
  innerHeight: any;
  tabInterval: any;
  timeInterval: any;
  isParams: boolean;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth - 200;
    this.innerHeight = window.innerHeight - 150;
    if(window.innerHeight < 600) {
      document.body.style.overflowY = 'auto';
    } else {
      document.body.style.overflowY = 'hidden';
    }
  }
  ngOnInit(): void {
    this.onResize();
    if (localStorage.getItem('role')) {
      this.userRole = localStorage.getItem('role');
    }
    this.route.queryParams
      .subscribe(params => {
        this.isParams = !!Object.keys(params).length;
        this.currentTabIndex = params.tabIndex;
        this.assetId = params.assetId;
      });

    // Auto Scrolling of Tabs for TV User Role...
    this.service.rotationData.subscribe((data: any) => {
      this.service.rotationDataIndex.subscribe((nextScreen: number) => {
        clearInterval(this.tabInterval);
        this.currentTabIndex = 0;
        let increment = 0;
        if (this.userRole == 'TV User' && data[nextScreen]?.screen == 'Electric Vehicle' && data[nextScreen]?.status) {
          this.tabInterval = window.setInterval(() => {
            if (increment < 3) {
              increment = increment + 1;
              this.currentTabIndex = increment;
            } else {
              data.length == nextScreen + 1 ? nextScreen = 0 : nextScreen = nextScreen + 1;
              if (data.length != 1) {
                this.service.setRotationDetail(nextScreen, data);
              }
              this.currentTabIndex = 0;
              increment = 0;
            }
          }, data[nextScreen].duration * 1000);
        }
      });
    });

    this.timeInterval = setInterval(() => {
      this.currentDate = this.datepipe.transform(new Date, 'medium');
    }, 1000);

  }


  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    // if (tabChangeEvent.tab.textLabel === 'Predictions') {
    //   document.body.style.overflowY = 'hidden';
    // }
    // else if (window.matchMedia("(max-height: 580px)").matches && tabChangeEvent.tab.textLabel != 'Predictions') {
    //   document.body.style.overflowY = 'scroll';
    // }
    // else {
    //   document.body.style.overflowY = 'hidden';
    // }
    if (tabChangeEvent.tab.textLabel == "Fleet Health") {
      if(this.isParams) {
        this.router.navigate(['.'], { relativeTo: this.route, queryParams: {tabIndex: 1}});
      }
      this.service.currentData.subscribe(data => {
        this.isOperationSummary = data;
      });
    }
    else {
      this.service.currentData.subscribe(data => {
        this.isOperationSummary = false;
      });
    }
    // if(tabChangeEvent.index!=1){
    //   this.currentTabIndex = 0;
    // }

    this.currentTabIndex = tabChangeEvent.index;

    // console.log('tabChangeEvent => ', tabChangeEvent);
    // console.log('index => ', tabChangeEvent.index);
  }

  getSelectedIndex(): number {
    return this.currentTabIndex;
  }

  ngOnDestroy() {
    clearInterval(this.tabInterval);
    clearInterval(this.timeInterval);
  }

}
