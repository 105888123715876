<div class="event-container">
    <div>
        <p class="event-container__title">Critical Events</p>
        <ng-container *ngFor="let export of exportTableButton">
            <button class="btn btn-info" [ngClass]="export?.class"
                (click)="exporter.exportTable(export?.format)">{{export?.label}}</button>&nbsp;
        </ng-container>
        <mat-paginator class="d-flex-inline" #paginator [pageSizeOptions]="[5,10]" showFirstLastButtons
            [length]="dataSourceTotalSize" (page)="pageChanged($event)">
        </mat-paginator>
    </div>
    <table class="table-container" id="criticlTicktsTbl" mat-table [dataSource]="dataSource" matTableExporter
        #exporter="matTableExporter" matSort>
        <ng-container matColumnDef="Id">
            <th class="pl-0" mat-header-cell *matHeaderCellDef mat-sort-header> Alert ID </th>
            <td class="pl-0" mat-cell *matCellDef="let element"> <a class="criticlEvnt">{{element.Id}}</a> </td>
        </ng-container>
        <ng-container matColumnDef="AlertName">
            <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Alert Name </th>
            <td mat-cell *matCellDef="let element"> {{element.AlertName}} </td>
        </ng-container>
        <ng-container matColumnDef="Value">
            <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
            <td mat-cell *matCellDef="let element"> {{element.Value}} </td>
        </ng-container>
        <ng-container matColumnDef="AssetName">
            <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Asset Name</th>
            <td mat-cell *matCellDef="let element"> {{element.AssetName}} </td>
        </ng-container>
        <ng-container matColumnDef="rcretime">
            <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header> Timestamp </th>
            <td mat-cell *matCellDef="let element"> {{element.rcretime}} </td>
        </ng-container>
        <ng-container matColumnDef="details">
            <th scope="col" mat-header-cell *matHeaderCellDef> Details </th>
            <td mat-cell *matCellDef="let element">
                <a class="btn btn-primary ey-yellow" (click)="redirectToDetails(element)">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="update">
            <th class="pr-0" mat-header-cell *matHeaderCellDef> Update </th>
            <td class="pr-0" mat-cell *matCellDef="let element">
                <a class="btn btn-primary green" (click)="redirectToUpdate(element.id)">
                    <i class="fa fa-edit pl-1"></i>
                </a>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" style="height: 10px;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="loadingSpin" class="d-flex justify-content-center">
        <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image" />
    </div>

    <ng-template #FormTemplate>
        <div class="modal-body text-center border-left">
            <div class="modalPopupCard modalCancel">
                <div class="modalPopupCardBody card-body">
                    <p>ID: {{ Id }}</p>
                    <p>Asset Name: {{ assetName }}</p>
                </div>
                <div class="card-footer">
                    <div class="row float-right">
                        <button type="button" class="btn btn-danger d-inline-block cursor-pointer" (click)="close()">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>