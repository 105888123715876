import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'device-config-form',
  templateUrl: './device-config-form.component.html',
  styleUrls: ['./device-config-form.component.css']
})
export class DeviceConfigFormComponent implements OnInit {
  apiUrl: string = environment.coreApiBaseUrl + environment.addAssetEndPoint;
  showRandom: boolean = false;
  showConstant: boolean = false;
  valueField: any;
  dataTypes: any[]=["Int64","Byte","String"];
  successResponse: string;
  errorResponse: string;

  constructor(private authService: MsalService, private http: HttpClient, private auth: MsalService) {

  }

 ngOnInit(){
  // this.addAsset()
 }
 addAsset() {
  this.successResponse="";
  this.errorResponse ="";
  this.getAssetAddUpdateResponse().subscribe(

    (response) => {

       console.log("Asset Added")
       this.successResponse = "*** Asset Added Successfully***"
    },
    (error) => {

      console.log("There Was A Problem")
      this.errorResponse ="*** Error Occured While Adding Asset***"
    });
}
getAssetAddUpdateResponse(): Observable<any> {
  let idToken = localStorage.getItem('msal.idtoken');
  const headers = {
    'content-type': 'application/json',
    'Authorization':'Bearer '+idToken

  }
  if(this.showConstant)
    this.valueField = this.form.value.constantValue;
  else
  this.valueField = this.form.value.randomValue;
  const body= {
        "del_Flg": "false",
        "simfrequency": this.form.value.simFrequency,
        "freefld1": "",
        "freefld2": "",
        "RModTime": "",
        "R_Cre_Time": "",
        "accesslevel": "ReadWrite",
        "browsename": this.form.value.browseName,
        "datatype": this.form.value.datatype,
        "displayname": this.form.value.browseName,
        "folder": this.form.value.deviceId,
        "nodeid": this.form.value.browseName,
        "parentnodeid": "Server1",
        "rangemin": this.form.value.minRange,
        "rangemax": this.form.value.maxRange,
        "tab": "1",
        "value": this.valueField ,
        "serverEnable": "true",
        "folderid": ""

  };
  console.log(body)
  return this.http.post(this.apiUrl, body,{'headers':headers})
}




  form = new FormGroup({
    deviceId: new FormControl('', [Validators.required, Validators.minLength(3)]),
    nodeId: new FormControl('', [Validators.required, Validators.minLength(3)]),
    browseName: new FormControl('', [Validators.required, Validators.minLength(3)]),
    simFrequency: new FormControl('', [Validators.required, Validators.minLength(3)]),
    randomValue: new FormControl(''),
    minRange: new FormControl(''),
    maxRange: new FormControl(''),
    constantValue: new FormControl('', [Validators.required]),
    datatype: new FormControl('')
  });

  get f(){
      return this.form.controls;
  }

  submit(){
      if(this.form.status === 'VALID'){
        console.log(this.form.value);
      }
  }

  setValue(){
      this.form.setValue({deviceId: 'PD05', nodeId:'PD05',browseName:'test',simFrequency:'10sec', minRange: 'test', maxRange: 'test', randomValue: 'test'});
  }

  resetValue(){
      this.form.reset({deviceId: '', nodeId: '',browseName:'', simFrequency: '', minRange: '',maxRange: '', randomValue: ''});
  }
  showRandomData(event){
    if(this.showRandom){
      this.showRandom = false;
    }else{
      this.showRandom = true;
      //this.showConstant = false;
    }
    //console.log("toggled value........"+this.showRandom)
  }
  showConstantData(event){
    if(this.showConstant){
      this.showConstant = false;
    }else{
      this.showConstant = true;
    //  this.showRandom = false;
    }
  }

}
