<div class="container" style="max-width: 94%;width: 100%;">
    <div class="row">
      <div class="col-md-2 mt-2">
        <div class="row">
          <img src="assets/images/ev_bike.png" alt="Polar Delight" class="img-machine" />
        </div>
        <div class="col-sm-12" >
          <div class="assetIdDropdn">
            <div class="form-group">
              <label for="demo_overview">Asset</label>
                <select class="form-control" data-role="select-dropdown" (change)="onChnageOfAsset($event)">
                <option *ngFor="let asset of assetIdList" [value]='asset.id' [attr.selected]="asset.assetId==assetId ? '' : null">
                    {{asset}}
                </option>
                
              </select>
            </div>
          </div>
          <ul class="image-attributes" style="margin-bottom: 0;padding:0;">
             <p>Asset Id : {{landOperationData.assetId}}</p>
          </ul>
        </div>
        <div class="row">
          <button class="btn btn-primary digitalTwnBtn">
            <a (click)="openDialog()">Digital Twin</a>
          </button>
          
        </div>
      </div>
      <div class="col-md-10 mt-2">
        <div class="row">
          <div class="col">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Odometer'"
            [attributeValue]="landOperationData.ODO+' km'" [imageName]="" [screenName]="'operation'">
          </app-custom-number-card>
          </div>
          <div class="col col-style">
            <app-custom-number-card [cardFooterColor]="'green'"
            [attribute]="'Trip'" [attributeValue]="landOperationData.Trip+' km'" [imageName]=""
            [screenName]="'operation'"></app-custom-number-card>
  
          </div>
          <div class="col col-style">
            <app-custom-number-card [cardFooterColor]="'green'"
            [attribute]="'Ride Mode'" [attributeValue]="landOperationData.Actual_map+' '" [imageName]=""
            [screenName]="'operation'"></app-custom-number-card>
          </div>
          <div class="col col-style">
            <app-custom-number-card [cardFooterColor]="'green'"
            [attribute]="'App ID'" [attributeValue]="landOperationData.App_ID+' '" [imageName]=""
            [screenName]="'operation'"></app-custom-number-card>
          </div>

        </div>
        <div class="row">
          <div class="col-md-3">
            <app-card-widget [widgetData]="landOperationData.Speed+' KPH'" [widgetName]="'Bike Speed'"></app-card-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-card-widget [widgetData]="landOperationData.Power+' W'" [widgetName]="'Bike Power'"></app-card-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-card-widget [widgetData]="landOperationData.RPMF+' RPM'" [widgetName]="'Bike RPM'"></app-card-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-card-widget [widgetData]="landOperationData.Iphase+' A'" [widgetName]="'Bike Current'"></app-card-widget>
          </div>
        </div>
        <div class="row" style="margin-top: +10px;">
          <div class="col-md-3">
            <app-card-widget [widgetData]="landOperationData.Ubat+' V'" [widgetName]="'Battery Voltage'"></app-card-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-card-widget [widgetData]="landOperationData.Ibat+' A'" [widgetName]="'Battery Current'"></app-card-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-card-widget [widgetData]="landOperationData.SOC+ ' %'"
              [widgetName]="'Battery Charge'"></app-card-widget>
          </div>
          <div class="col-md-3 col-style1">
            <app-card-widget [widgetData]="landOperationData.distToEmpty +' km'"
              [widgetName]="'Distance To Empty'"></app-card-widget>
          </div>
        </div>
      </div>
    </div>
  </div>