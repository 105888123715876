import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private authService: MsalService,private router: Router) { }

  ngOnInit(): void {
    // if (sessionStorage.getItem('ev_login') === 'true') {
    //   this.router.navigate(['/home/ev']);
    // }
    // else {
    //   this.router.navigate(['/home/polar-delight']);  
    // }
  }


  logout() {
    this.authService.logout();
    this.router.navigate(["/logout"]);
  }

}
