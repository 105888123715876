import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataService } from '../data.service';

@Component({
  selector: 'app-cnc',
  templateUrl: './cnc.component.html',
  styleUrls: ['./cnc.component.css']
})
export class CncComponent implements OnInit {
  userRole: any;
  tabInterval: any;
  currentTabIndex: number = 0;
  currentInternalTabIndex: number;
  constructor(private service: DataService) { }

  ngOnInit(): void {
    if (localStorage.getItem('role')) {
      this.userRole = localStorage.getItem('role');
    }

    // Auto Scrolling of Tabs for TV User Role...
    this.service.rotationData.subscribe((data: any) => {
      this.service.rotationDataIndex.subscribe((nextScreen: number) => {
        clearInterval(this.tabInterval);
        this.currentTabIndex = 0;
        let increment = 0;
        if (this.userRole == 'TV User' && data[nextScreen]?.screen == 'CNC' && data[nextScreen]?.status) {
          this.tabInterval = window.setInterval(() => {
            if (increment < 1) {
              increment = increment + 1;
              this.currentTabIndex = increment;
            } else {
              data.length == nextScreen + 1 ? nextScreen = 0 : nextScreen = nextScreen + 1;
              if (data.length != 1) {
                this.service.setRotationDetail(nextScreen, data);
              }
              this.currentTabIndex = 0;
              increment = 0;
            }
            console.log(data);
          }, data[nextScreen].duration * 1000);
        }
      });
    });
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
    this.currentTabIndex = tabChangeEvent.index;
  }

  internalTabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
    this.currentInternalTabIndex = tabChangeEvent.index;
  }

  getInternalSelectedIndex(): number {
    return this.currentInternalTabIndex;
  }

  ngOnDestroy() {
    clearInterval(this.tabInterval);
  }

  getSelectedIndex(): number {
    return this.currentTabIndex;
  }

}
