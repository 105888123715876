import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-horizontal-large-widget',
  templateUrl: './horizontal-large-widget.component.html',
  styleUrls: ['./horizontal-large-widget.component.css']
})
export class HorizontalLargeWidgetComponent implements OnInit {

  @Input() widgetName : string;
  @Input() leftFooterName : string;
  @Input() rightFooterName : string;
  @Input() widgetType : string;
  @Input() widgetData : any;
  @Input() xAxisLabelName: string;
  @Input() yAxisLabelName: string;
  @Input() isIssuesLoaded: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
