import { Component, OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Input, ViewChild } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatFileUploadQueueComponent } from 'angular-material-fileupload';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { environment } from '@env';

@Component({
  selector: 'app-csv-simulator',
  templateUrl: './csv-simulator.component.html',
  styleUrls: ['./csv-simulator.component.css']
})
export class CsvSimulatorComponent implements OnInit {
  headers: any;
  httpRequestParams: any;
  responseMessage:  string;
  interval: any;
  status: any;
  lastReportedTime: any; 
  autoRefreshPeriod = environment.operationAutoRefreshPeriodInSeconds * 1000;
  apiUrlCsvStatus: string = environment.coreApiBaseUrl + environment.isSimulatorStatusEndPoint;
  currentStatus: any;
  constructor(private authService: MsalService, private http: HttpClient, private auth: MsalService, private cd: ChangeDetectorRef) {
   }
   startCsvSimulatorEndPoint : any;
   stopCsvSimulatorEndPoint: any;
  ngOnInit(): void {
    this.startCsvSimulatorEndPoint = environment.coreApiBaseUrl+environment.startCsvSimulatorEndPoint;
    this.stopCsvSimulatorEndPoint = environment.coreApiBaseUrl+environment.stopCsvSimulatorEndPoint;
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'multipart/form-data;boundary=----WebKitFormBoundaryMLOB8ScJr1zUBv3z',
      'Authorization':'Bearer '+idToken,
      

    }
    this.headers = headers;
    // const httpRequestParams: HttpParams | {
    //   [param: string]: string | string[];
    // } = new HttpParams().set("file", "file");
    // this.httpRequestParams = httpRequestParams;
    this. checkCsvSimulatorRunning();
  }
  checkCsvSimulatorRunning() {
    this.interval = setInterval(() => {
      this.checkCsvimulatorStatus();
    }, this.autoRefreshPeriod);
  }
  checkCsvimulatorStatus() {
    this.checkOpcSimulatorStatusResponse().subscribe(
      (response) => {
        response.forEach(element => {
          if(element.simType == 'CSV'){
            console.log('status: '+element.status);
            this.currentStatus = element.status;
            this.lastReportedTime = element.lastReportedTime;
          }
        });
        
      },
      (error) => {
        // this.isPieLoad = true;
        console.log('There Was A Problem');
      }
    );
    if (this.status === this.currentStatus) {
      console.log('not changed');
    } else {
      this.status = this.currentStatus;
      console.log('changed');
    }
  }
  checkOpcSimulatorStatusResponse(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    };
    return this.http.post(this.apiUrlCsvStatus, '', { headers: headers });
  }
  public uploadEvent($event: any) {
    console.log('from client' + JSON.stringify($event));
    this.responseMessage = 'File Successfully Uploaded and CSV Simulator Started !!!';
  }
  public stopSimulator(){
    this.responseMessage="";
    this.getResponse().subscribe(
      (response) => {
       this.responseMessage = response.response;      
      },
      (error) => {
        this.responseMessage = "There was some problem while stopping CSV Simulator !!!" 
      });
  }
  getResponse(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.stopCsvSimulatorEndPoint, "", { 'headers': headers })
  }
  
  @ViewChild(MatFileUploadQueueComponent) queue: MatFileUploadQueueComponent;
 }
