
<mat-paginator style="display: inline-flex;margin-left: 180px;" #paginator [pageSizeOptions]="[5,10]" showFirstLastButtons></mat-paginator>
<table class="table-container" mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element"> {{element.id}}</td>
    </ng-container>

    <ng-container matColumnDef="displayname">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ParamName</th>
      <td mat-cell *matCellDef="let element"> {{element.displayname}}</td>
		</ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
      <td mat-cell *matCellDef="let element"> {{element.value}} </td>
    </ng-container>
    <!-- <ng-container matColumnDef="simfrequency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>SimFreq</th>
      <td mat-cell *matCellDef="let element"> {{element.simfrequency}} </td>
    </ng-container> -->
    <ng-container matColumnDef="simfrequency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>SimFreq</th>
      <td mat-cell *matCellDef="let element"> 
        <mat-form-field floatLabel="never">
            <input matInput [value]="element.simfrequency" [(ngModel)]="element.simfrequency">
           
          </mat-form-field>
        
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
			<td mat-cell *matCellDef="let element">
				<mat-form-field floatLabel="never">
					<mat-select placeholder="type" [value]="element.type" [(ngModel)]="element.type" (selectionChange)="selectOption($event)">
						<mat-option value="Random">Random</mat-option>
						<mat-option value="Constant">Constant</mat-option>
					</mat-select>
				</mat-form-field>
			</td>
		</ng-container>

    <ng-container matColumnDef="rangemin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>MinRange</th>
      <td mat-cell *matCellDef="let element"> 
        <mat-form-field floatLabel="never">
            <input matInput [value]="element.rangemin" [(ngModel)]="element.rangemin">
           
          </mat-form-field>
        
      </td>
    </ng-container>

    <ng-container matColumnDef="rangemax">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>MaxRange</th>
        <td mat-cell *matCellDef="let element"> 
            <mat-form-field floatLabel="never">
                <input matInput  [value]="element.rangemax" [(ngModel)]="element.rangemax">
            </mat-form-field>    
        </td>
      </ng-container>
   
    <ng-container matColumnDef="serverEnable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ServerFlag</th>
      <td mat-cell *matCellDef="let element; let i=index">
        <!-- <div class="custom-control custom-switch custom-control-inline">
            <input type="checkbox" class="custom-control-input" id="customSwitchDelFlag1">
            <label class="custom-control-label" for="customSwitchDelFlag1"></label>
        </div> -->
        <mat-checkbox (change)="updateCheckedList($event, element)"
                      [checked]="element.serverEnable"></mat-checkbox>
      </td>
    </ng-container>
   

    <tr mat-header-row *matHeaderRowDef="displayedColumns" style="height: 10px;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  
 <br>
 <p style="margin-left: 100px;">{{onLoadMessage}}</p>
  <button class="btn btn-info" style="margin-top: 5px;margin-left: 15px;" (click)="updateAssetInfo()">Update</button>&nbsp;
 <br>
 <p style="margin-left: 150px;">{{assetUpdateInfoMessage}}</p>