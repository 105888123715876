<!-- <mat-paginator style="display: inline-flex;" #paginator [pageSizeOptions]="[10,20]" showFirstLastButtons></mat-paginator> -->
<table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td *matCellDef="let element" class="text-center"> {{element.id}}</td>
    </ng-container>
    <ng-container matColumnDef="screen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Screen </th>
        <td mat-cell *matCellDef="let element"> {{element.screen}} </td>
    </ng-container>
    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Duration in seconds </th>
        <td mat-cell *matCellDef="let element"> <input type="number" [(ngModel)]="element.duration" (change)="updateDuration(element)"/> </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element; let i=index">
            <mat-checkbox (change)="updateCheckedList($event, element)" [checked]="element.status"></mat-checkbox>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
<br>
<button class="mat-focus-indicator mat-raised-button mat-button-base mat-primary ng-star-inserted"
    style="margin-bottom: 5px;" (click)="submitSelection()">Submit</button>

<ng-template #FormTemplate>
    <div class="modal-header h4">
        Information
    </div>
    <div class="modal-body text-center border-left">
        <div class="modalPopupCard modalCancel">
            <div class="modalPopupCardBody card-body srvceTktModal">
                <p>Configuration has been completed. Kindly switch to TV User for the effect</p>
            </div>
            <div class="modal-footer">
                <div class="row float-right">
                    <button type="button" class="btn btn-info" (click)="close()"> OK</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>