import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css']
})
export class DeviceListComponent implements OnInit {
  apiUrl: string = environment.coreApiBaseUrl + environment.retrieveAssetsListEndPoint
  delFlagUpdateUrl: string = environment.coreApiBaseUrl + environment.delFlagUpdateEndPoint
  DEVICE_LIST: DeviceList[] = [];
  dataSource;
  selectedAsset:any[]=[];
  deviceListResponse: any;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selection = new SelectionModel<DeviceList>(true, []);
  simulatedDeviceStatus: string;
  sumilatedReadyFlag: boolean = false;

  displayedColumns: string[] = ['id', 'assetId', 'serverenableflag'];
  ngAfterViewInit() {

    //this.dataSource.paginator = this.paginator;
    //this.dataSource.paginator._intl.itemsPerPageLabel ='';
    //this.dataSource.sort = this.sort;
  }

  constructor(private authService: MsalService, private http: HttpClient, private auth: MsalService, private cd: ChangeDetectorRef) {
    //this.dataSource = new MatTableDataSource(DEVICE_LIST);
  }

  ngOnInit(): void {
    this.populateDevicesList();
  }
  populateDevicesList() {
    this.getDeviceListResponse().subscribe(
      (response) => {

        //this.deviceListResponse = JSON.stringify(response);
       // console.log("Device List Response: " + this.deviceListResponse)
        response.forEach(element => {
          //console.log("Data: " + JSON.stringify(element));
          this.DEVICE_LIST.push(element);
        });
        console.log("Device List: "+this.DEVICE_LIST);
        this.dataSource = new MatTableDataSource(this.DEVICE_LIST);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        console.log("There Was A Problem Registering Service With SAP")
      });
  }
  getDeviceListResponse(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.apiUrl, "", { 'headers': headers })
  }
  updateCheckedList(event, element){
    event ? this.selection.toggle(element) : null;
    const item = {"displayname": element.assetId , "serverEnable": event.checked};
    this.selectedAsset.push(item);
   // this.selectedAsset = this.selection.selected.map(item => item.assetId);
  }
  updateDelFlag(){
    this.simulatedDeviceStatus = '';
   this.updateDel(this.selectedAsset).subscribe(
    (response) => {
      //console.log("response:"+response)
      this.sumilatedReadyFlag=true;
      this.simulatedDeviceStatus="***Automated Simulation Scheduled For Selected Device(s)***";

    });
  }
  updateDel(assets): Observable<any>{
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken
    }
    // const body = {
    //   assets
    // };
    // console.log(body)
    return this.http.post(this.delFlagUpdateUrl, assets, { 'headers': headers })

  }

}
export interface DeviceList {
  id: number;
  assetId: string;
  serverenableflag: boolean;

}
