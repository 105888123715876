<div class="container">
    <div class="row">
        <div class="col-md-2 mt-2">
            <div class="row">
                <img loading="lazy" src="assets/images/ev_bike.png" alt="Electric Vehicle" class="img-machine" />
            </div>
            <div class="col-sm-12"  >
                <div class="assetIdDropdn">
                    <div class="form-group">
                        <label for="demo_overview">Asset</label>
                        <select class="form-control" data-role="select-dropdown" (change)="onChnageOfAsset($event)">
                            <option *ngFor="let asset of VEHICLES_STATUS_LIST" [value]='asset.vehicleName'
                                [attr.selected]="asset.vehicleName==assetId ? '' : null">
                                {{asset.vehicleName}}
                            </option>

                        </select>
                    </div>
                </div>
                <ul class="image-attributes" style="margin-bottom: 0;padding:0;">
                    <p>Location : {{location}}</p>
                </ul>
            </div>
            <div class="row">
                <button class="btn btn-primary digitalTwnBtn">
                    <a (click)="openDialog()">Digital Twin</a>
                </button>
            </div>
        </div>
        <div class="col-md-10 mt-2">
            <div class="row">
                <ng-container *ngFor="let numberCard of evOperationNumberCardsData">
                    <div [ngClass]="numberCard?.class">
                        <app-custom-number-card
                            [attribute]="numberCard.attribute"
                            [attributeValue]="evOperationData[numberCard?.attributeValue]"
                            [unit]="numberCard?.unit"
                            [imageName]="evOperationData[numberCard?.imageName]"
                            [screenName]="screenName"
                            [cardFooterColor]="numberCard?.colorCondition
                                            ? (evOperationData[numberCard?.attributeValue] === numberCard?.colorCondition ? 'green'
                                            : 'darkred') : 'green'">
                        </app-custom-number-card>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <ng-container *ngFor="let card of evOperationCardsData">
                    <div [ngClass]=card?.class>
                        <ng-container [ngSwitch]="card?.type">
                            <app-gauge-chart-widget
                                *ngSwitchCase="'GAUGE_CARD'"
                                module="EV"
                                [widgetData]="evOperationData[card.widgetData.parent][card.widgetData.child] + card?.unit"
                                [widgetName]="card.widgetName"
                                [needleValue]="evOperationData[card.widgetData.parent][card.needleValue]"
                                [min]="card?.min ? evOperationData[card.widgetData.parent][card?.min] + card?.unit : ''"
                                [max]="card?.max ? evOperationData[card.widgetData.parent][card?.max] + card?.unit : ''">
                            </app-gauge-chart-widget>
                            <app-card-widget
                                *ngSwitchCase="'CARD_WIDGET'"
                                module="EV"
                                [type]="card?.subType"
                                [widgetData]="evOperationData[card.widgetData.parent][card.widgetData.child] + card?.unit"
                                [widgetName]="card?.widgetName"
                                [leftFooterName]="evOperationData[card.widgetData.parent][card.leftFooterName]"
                                [min]="card?.min ? evOperationData[card.widgetData.parent][card?.min] + card?.unit : ''"
                                [max]="card?.max ? evOperationData[card.widgetData.parent][card?.max] + card?.unit : ''">
                            </app-card-widget>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
