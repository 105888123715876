export interface PredictionPriority {
    value: number;
    name: string;
}
export interface PredictionProblemCauses {
    value: number;
    name: string;
}
export interface PredictionMachineIssues {
    value: number;
    name: string;
    cumulativePercentage: number;
}

export interface Data {
    id: number,
    type: string,
    label: {
        class: string,
        name: string
    },
    class: string,
    loading: boolean,
    data: any,
    screenName?: string,
    legend?: {
        orientation?: string,
        position: string
    }

}

export const CHARTS_DATA: Data[] = [
    {
        id: 3,
        type: 'kendo-chart-series',
        label: {
            class: 'chart-label series-chart-section__label',
            name: 'Probability By Problem Causes'
        },
        class: 'series-chart-section',
        loading: false,
        data: {
            series: []
        },
        legend: {
            orientation: 'vertical',
            position: 'bottom'
        }
    },
    {
        id: 2,
        type: 'pareto-chart',
        label: {
            class: 'chart-label pareto-chart-section__label',
            name: 'Count Of Problem Cause'
        },
        screenName: 'prediction',
        class: 'col-md-offset-2 pareto-chart-section',
        loading: false,
        data: {
            barChartData: [],
            lineChartData: []
        }
    },
    {
        id: 1,
        type: 'vertical-bar-chart',
        label: {
            class: 'chart-label horizontal-large-widget-section__label',
            name: 'RPN(Risk Priority Number)'
        },
        class: 'horizontal-large-widget-section',
        loading: false,
        data: {
            widgetData: []
        }
    }
]