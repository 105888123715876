import { Component, Input, OnInit } from '@angular/core';
import { single } from './number-card-data.js';

@Component({
  selector: 'app-ngx-number-card',
  templateUrl: './ngx-number-card.component.html',
  styleUrls: ['./ngx-number-card.component.css']
})
export class NgxNumberCardComponent implements OnInit{

  @Input() inputCardData : any[];
  @Input() colorSchemeIndex : number;
  //single: any[];
  view: any[] = [180,120];

  colorScheme = {
    domain: ['green']
  };
  cardColor: string = '#1b1a1a';

  constructor() {
    //Object.assign(this, { single });
  }

  ngOnInit(): void {
    if(this.colorSchemeIndex===0){
      this.colorScheme.domain[0]='green';
    } else {
      this.colorScheme.domain[0]='orange';
    }
  }

  onSelect(event) {
    console.log(event);
  }

  onResize(event) {
    console.log(event.target.innerWidth);
  }
}
