<div class="container" style="max-width: 94%;width: 100%;">
  <div class="row">
    <div class="col-md-2 mt-2">
      <div class="row">
        <img src="assets/images/battery_image.jpg" alt="Battery Connectivity" class="img-machine" />
      </div>
      <div class="col-sm-14">
        <div class="assetIdDropdn">
          <div class="form-group">
            <label for="demo_overview">Asset</label>
            <!-- <select class="form-control option-menu" data-role="select-dropdown" (change)="onChnageOfAsset($event)">
              <option *ngFor="let asset of assetIdList" [value]='asset.id' [attr.selected]="asset.assetId==assetId ? '' : null" (click)="chooseAsset(asset)" >
                  {{asset}}
              </option>
              <option class="option-menu" *ngFor="let asset of assetIdList" [value]='asset.id' [attr.selected]="asset.assetId==assetId ? '' : null" (click)="chooseAsset(asset)" >
                {{asset}}
            </option>

            </select> -->
            <select class="form-control" data-role="select-dropdown" (change)="onChnageOfAsset($event)">
              <option *ngFor="let asset of assetIdList" [value]='asset.id'
                [attr.selected]="asset.assetId==assetId ? '' : null">
                {{asset.assetId}}
              </option>

            </select>
          </div>
        </div>
        <!-- <ul class="image-attributes" style="margin-bottom: 0;padding:0;">
           <p>Asset Id : {{landOperationData.assetId}}</p>
        </ul> -->
      </div>

      <div class="row">
        <div class="row">
          <button class="btn btn-primary digitalTwnBtn">
            <a (click)="openDialog()">Digital Twin</a>
          </button>
        </div>
        <div class="row">
          <button class="btn btn-primary SoHbtn">
            <a  (click)="sohTab()">Battery SoH </a>
          </button>
        </div>
          </div>


    </div>
    <div class="col-md-10 mt-2">
      <div class="row">
        <div class="col">
          <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Discharge Energy'"
            [attributeValue]="landOperationData.DischargeEnergy +' Wh'" [imageName]="" [screenName]="'operation'">
          </app-custom-number-card>
        </div>
        <div class="col col-style">
          <app-custom-number-card [cardFooterColor]="'green'" [attribute]="' CCCV'"
            [attributeValue]="landOperationData.CCCVdistF+' '" [imageName]=""
            [screenName]="'operation'"></app-custom-number-card>

        </div>
        <div class="col col-style">
          <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Discharge Capacity '"
            [attributeValue]="landOperationData.DischargeCapacity+' Ah'" [imageName]=""
            [screenName]="'operation'"></app-custom-number-card>
        </div>
        <div class="col col-style">
          <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Pseudo Resistance'"
            [attributeValue]="landOperationData.PseudoResistance +' mOhm'" [imageName]=""
            [screenName]="'operation'"></app-custom-number-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <app-card-widget [widgetData]="landOperationData.RecoveryVoltage  +' V'"
            [widgetName]="'Recovery Voltage'"></app-card-widget>
        </div>
        <div class="col-md-3 col-style1">
          <app-card-widget [widgetData]="landOperationData.Capacity+' W'" [widgetName]="'Capacity'"></app-card-widget>
        </div>
        <div class="col-md-3 col-style1">
          <app-card-widget [widgetData]="landOperationData.CoefficientSkewness+' '"
            [widgetName]="'Coefficient Skewness'"></app-card-widget>
        </div>
        <div class="col-md-3 col-style1">
          <app-card-widget [widgetData]="landOperationData.DischargeQ+' %'"
            [widgetName]="'DischargeQ'"></app-card-widget>
        </div>
      </div>
      <div class="row" style="margin-top: +10px;">
        <div class="col-md-3">
          <app-card-widget [widgetData]="landOperationData.Energy +' kwh'" [widgetName]="'Energy'"></app-card-widget>
        </div>
        <div class="col-md-3 col-style1">
          <app-card-widget [widgetData]="landOperationData.Entropy +' mV/K'" [widgetName]="'Entropy'"></app-card-widget>
        </div>
        <div class="col-md-3 col-style1">
          <app-card-widget [widgetData]="landOperationData.KurtosisCoefficient+ ' '"
            [widgetName]="'Kurtosis Coefficient'"></app-card-widget>
        </div>
        <div class="col-md-3 col-style1">
          <app-card-widget [widgetData]="landOperationData.diffVoltage   +' V'"
            [widgetName]="'Differential Voltage'"></app-card-widget>
        </div>
      </div>
    </div>
  </div>
</div>
