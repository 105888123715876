<div class="container" style="width: 96%;max-width: 100%;">
  <div class="row">
    <div class="col-md-3">
      <div class="row button-series">
        <section>
          <div class="center">
            <a class="button btn1">
              <span>
                <em>Start Server</em>
                <i (click)="callIoTStart()">
                  <img src="assets/images/start.png" /> </i>
              </span>
            </a>
          </div>
        </section>
        <p>{{startServerResponse}}</p>
      </div>

      <div class="row">
        <div class="config-form">
          <p class="param-info">Device Configuration Params</p>
          <div class="fit-form-data">
            <device-config-form></device-config-form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="row button-series pl-2">
        <div class="col-md-6" style=" text-align: center;">
          <div class="center">
            <a class="button btn2">
              <span>
                <em>Stop Server</em>
                <i (click)="callIoTStop()">
                  <img src="assets/images/stop.png" /> </i>
              </span>
            </a>
          </div>
          <p  class="center-message" >{{stopServerResponse}}</p>
        </div>
        <div class="col-md-6 mt-1" style=" text-align: center;">
          <div class="center">
            <a class="button btn3">
              <span>
                <em>Fetch Model</em>
                <i>
                  <img src="assets/images/model.png" /> </i>
              </span>
            </a>
          </div>
          <div [(ngModel)]="status">
            <div class="row" *ngIf="status=='Online'">
              <div class="font-size" style="padding-left: 161px;"> OPC Simulator Status: </div>
              <div class="dot-size online"></div>
            </div>
          </div>
          <div [(ngModel)]="status">
            <div class="row" *ngIf="status=='Offline'">
              <div class="font-size" style="padding-left: 60px;"> OPC Simulator Status: </div>
              <div class="dot-size offline"></div>
              <div class="font-size" style="padding-left: 10px;">Last Reported Time: {{lastReportedTime}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row padding-x">
        <div class="device-table">
          <div class="row refresh-button-position">
            <p class="param-info1">Devices</p>
            <button class="btn btn-info pt-1" type="button" (click)="populateDevicesList('0','5')">Refresh</button>
          </div>

          <div *ngIf="isOnLoad" class="fit-device-data">
            <app-reusable-app-table [tableData]="opcTable1Data" [columnHeader]="opcTable1Header"

              [dataSourceTotalSize]="dataSourceTotalSize"  (nextPageEvent)="pageChangedOnClick($event)">
            </app-reusable-app-table>
            <!-- <app-reusable-app-table-device-simulator [tableData]="opcTable1Data" [columnHeader]="opcTable1Header"
              [dataSourceTotalSize]="dataSourceTotalSize" (nextPageEvent)="pageChangedOnClick($event)">
            </app-reusable-app-table-device-simulator> -->

          </div>
          <div *ngIf="loadingSpin" class="d-flex justify-content-center">
            <!-- <div class="spinner-border" role="status">
              <span class="sr-only" id="loading"></span>
          </div> -->
            <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image" />
          </div>
        </div>
      </div>
      <div>
        <div class="device-info">

          <p class="param-info">Selected Device Info</p>

          <div class="fit-device-data">
            <!-- <app-reusable-app-table [tableData]="opcTable2Data" [columnHeader]="opcTable2Header">
              </app-reusable-app-table> -->
            <app-inline-editable-table></app-inline-editable-table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">


    </div>
  </div>
</div>
