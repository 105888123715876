import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { AuthResponse } from 'msal';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-services-form',
  templateUrl: './services-form.component.html',
  styleUrls: ['./services-form.component.css']
})
export class ServicesFormComponent implements OnInit {

  enableManualService: boolean=false;
  enableAutoService : boolean = false;
  loadingSpin : boolean = false;
  errorCode: string;
  sapResponse: any;
  apiUrl: string = environment.coreApiBaseUrl+environment.sapCommonServicesEndPoint;
  assetsUrl: string = environment.coreApiBaseUrl + environment.retrieveAssetsListEndPoint
  assetsOPCAssetsUrl: string = environment.coreApiBaseUrl + environment.retriveOPCMOdelListEndPoint
  responseString: string;
  serviceTypes: any=['Service-Breakdown','Service-Corrective'];
  assetList: any=[];
  opcModelList: OPCModel[]=[];
  DEVICE_LIST: DeviceList[] = [];
  errorCodes: any[]=["01","02","04"];
  customfield1: any=["SM0001","SM0002","SM0003","SM0004"];
  customfield2: any=["10000567","10000568","10000580","10000592","10000604","10000616","10000628","10000640","10000652","10000664","10000676","10000688","10000700","10000712","10000724","10000736","10000748","10000760","10000772","10000784","10000796","10000808","10000820","10000832","10000844","10000856","10000868","10000880","10000892","10000904","10000916","10000928","10000940","10000952","10000964","10000976","10000988","10001000","10001012","10001024","10001036","10001048","10001060","10001072","10001084","10001096","10001108","10001120","10001132","10001144"];
  additionalFields: any=[];
  customfield1Req:''
  customfield2Req:''
  constructor(private authService: MsalService, private http: HttpClient, private auth: MsalService, private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.populateDevicesList();
    this.fetchAllAssets();
  }
  fetchAllAssets() {
    this.fetchAllOPCAssets().subscribe(
      (response) => {

        //this.deviceListResponse = JSON.stringify(response);
       // console.log("Device List Response: " + this.deviceListResponse)
        response.forEach(element => {
          //console.log("Data: " + JSON.stringify(element));
          this.opcModelList.push(element);
        });
      //  console.log("opcModelList: "+this.opcModelList);
      //   this.opcModelList.forEach(item => console.log("Display Name:"+item.displayname))
      },
      (error) => {
        console.log("There Was A Problem While Fetching OPC Assets")
      });
  }
  fetchAllOPCAssets(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.assetsOPCAssetsUrl, "", { 'headers': headers })
  }
  populateDevicesList() {
    this.getDeviceListResponse().subscribe(
      (response) => {

        //this.deviceListResponse = JSON.stringify(response);
       // console.log("Device List Response: " + this.deviceListResponse)
        response.forEach(element => {
          this.assetList.push(element.assetId);
        });
      },
      (error) => {
        console.log("There Was A Problem While Fetching Assets")
      });
  }
  getDeviceListResponse(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      'Authorization':'Bearer '+idToken

    }
    return this.http.post(this.assetsUrl, "", { 'headers': headers })
  }
  form = new FormGroup({
    services: new FormControl('', [Validators.required]),
    sim: new FormControl('', [Validators.required]),
    assetId: new FormControl('', [Validators.required]),
    errorCode: new FormControl('', [Validators.required, Validators.minLength(3)]),
    errorDesc: new FormControl('', [Validators.required, Validators.minLength(3)]),
    location: new FormControl('', [Validators.required, Validators.minLength(3)]),
    additionalField: new FormControl('', [Validators.required, Validators.minLength(3)]),
   });
  get f(){
    return this.form.controls;
}

submit(){
  this.loadingSpin=true;
  this.responseString= '';
  if(this.form.status === 'VALID'){
    console.log("Form Values: "+this.form.value);

  }

  this.getSapResponse().subscribe(
    (response) => {
      if(response){this.loadingSpin=false;hideloader();}
       if(response.TicketId != undefined){
        this.responseString = "Service Ticket ID: "+response.TicketId;
       } else if(response.ErrorResponse != undefined){
        this.responseString = "Error: "+response.ErrorResponse[0].errorMessage;
       }
    },
    (error) => {
      this.loadingSpin=false;hideloader();
      console.log("There Was A Problem Registering Service With SAP")
      this.responseString= "There Was A Problem Registering Service With SAP. Contant Support @EY IoT";
    });
    function hideloader() {
        document.getElementById('loading').style.display = 'none';
    }
}

getSapResponse(): Observable<any> {
  let idToken = localStorage.getItem('msal.idtoken');
  const headers = {
    'content-type': 'application/json',
    'Authorization':'Bearer '+idToken

}
  this.customfield1Req=''
  this.customfield2Req=''
  if (this.form.value.services == 'Service-Breakdown')
    this.customfield1Req = this.form.value.additionalField;
  else if (this.form.value.services == 'Service-Corrective')
    this.customfield2Req = this.form.value.additionalField;

  const body= {
    "errorCode" : this.form.value.errorCode,
    "errorDesc" : this.form.value.errorDesc,
    "podbarCode" : "",
    "podsalesNum" : "",
    "podreplNum" : "",
    "priority" : "1",
    "location" : this.form.value.location,
    "type" : this.form.value.services,
    "customfield1" : this.customfield1Req,
    "customfield2" : this.customfield2Req,
    "customfield3" : "",
    "customfield4" : "",
    "customfield5" : "",
    "customfield6" : "",
    "machineId" : this.form.value.assetId,
    "timestamp" : "2021-03-05 03:11:00+05:30"
  };
  console.log(body)
  return this.http.post(this.apiUrl, body,{'headers':headers})
}
setValue(){
  this.form.setValue({services: 'Service-Breakdown', sim:'Manual',assetId:'PD01',errorCode:'ERR_O1', errorDesc:'Lift Open', location: 'New York'});
}

resetValue(){
  this.form.reset({services: '', sim:'',assetId:'',errorCode:'', errorDesc:'', location: ''});
}
selectOption(id: number) {

  this.responseString = '';
 if(id==1){
  this.enableManualService = true;
  this.enableAutoService = false
 }else{
  this.enableManualService =false;
  this.enableAutoService = true;
 }

}
setAdditionalField(serviceSelected: string){
  this.additionalFields='';
console.log("Selected: "+serviceSelected)
if(serviceSelected == 'Service-Breakdown')
  this.additionalFields=this.customfield1;
else if(serviceSelected == 'Service-Corrective')
  this.additionalFields=this.customfield2;
}
populateLocation(asset: string){
  console.log("Selected Asset: "+asset)
  this.opcModelList.forEach(item => {
    if(item.displayname == asset && item.folderid == 'Location'){
      if(item.tab.includes(",")){
        const myArr = item.tab.split(",")[0];
        this.form.patchValue({
          location: myArr,
          
          });
      }else{
        this.form.patchValue({
          location: item.tab,
          
          });
      }
    }

  })
}
populateErrorDetails(error: string){
  this.form.patchValue({
    errorCode: error,
    });
    if(error == "01"){
      this.form.patchValue({
        errorDesc: "Freezer Temperature Out Of Range",
        });
    }else if(error == "02"){
      this.form.patchValue({
        errorDesc: "Dispense Force Out Of Range",
        });
    }else if(error == "04"){
      this.form.patchValue({
        errorDesc: "Unable To Home",
        });
    }
}

}
export interface DeviceList {
  id: number;
  assetId: string;

}
export interface OPCModel {
  displayname: string;
  folderid: string;
  tab: string
}
