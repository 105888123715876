import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  HostListener,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlantTwinDialogComponent } from '../../shared/plant-twin-dialog/plant-twin-dialog.component';
import { Observable, Subscription, interval } from 'rxjs';
import { DataService } from '../data.service';
import { environment } from '@env';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { anyChanged } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-ev-operation-summary',
  templateUrl: './ev-operation-summary.component.html',
  styleUrls: ['./ev-operation-summary.component.scss'],
})
export class EvOperationSummaryComponent implements OnInit {
  yLabelName = 'US Dollars';
  xLabelName = 'City';
  OPERATION_SUMMARY_LIST: OperationSummary[] = [];
  TOP_POWER_LIST: PowerConsumption[] = [];
  VEHICLES_STATUS_LIST: VehicleList[] = [];
  public barChartInput: any[] = [];
  public lineChartInput: any[] = [];
  isPieLoad: boolean = false;
  isParetoLoaded: boolean = false;
  loadingSpin: boolean = false;
  apiUrl: string =
    environment.coreApiBaseUrl + environment.evOpSummaryEndPoint ;
  apiUrlTopBatteryConsumptions: string =
    environment.coreApiBaseUrl + environment.evTopBatteryConsumption ;
  apiUrlOnlineOfflineStatus: string =
    environment.coreApiBaseUrl + environment.fetchAllVehiclesEndPoint;
  totalOnlineAssets: number = 0;
  totalOfflineAssets: number = 0;
  avgMaintSpent: string;
  avgMaintTime: string;
  serviceTicketsOpen: string;
  private updateSubscription: Subscription;
  @Input() screenName: string;
  isOperationSummary: any;
  inputPieGridData: any;
  inputPieGridData1: any;
  inputPieGridStock: any;
  inputPieGridConsumed: any;
  maintenancePlanned: any;
  maintenanceBreakdown: any;
  maintenancePredictive: any;
  label: string = 'Total';
  scale = { max: 100 };
  public colors: any[] = [
    {
      to: 30,
      color: '#e44d25',
    },
    {
      from: 25,
      to: 100,
      color: 'green',
    },
  ];

  constructor(
    public dialog: MatDialog,
    private service: DataService,
    private authService: MsalService,
    private http: HttpClient,
    private auth: MsalService,
    private cd: ChangeDetectorRef
  ) {}
  openDialog() {
    this.dialog.open(PlantTwinDialogComponent);
  }
  innerWidth: any;
  innerHeight: any;
  height;
  width;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth - 200;
    this.innerHeight = window.innerHeight - 150;
  }
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event?) {
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 641 && this.innerWidth <= 961) {
      this.height = '100';
      this.width = '100';
    } else if (this.innerWidth > 961 && this.innerWidth <= 1025) {
      this.height = '90';
      this.width = '90';
    } else {
      this.height = '110';
      this.width = '110';
    }
  }
  ngOnInit(): void {
    this.onOrientationChange();
    this.onResize();
    this.label = 'Total(hrs)';
    this.isOperationSummary = true;
    this.service.setData(this.isOperationSummary);
    this.getOperationSummary();
    this.topPowerConsumptions();
    this.assetsStatus();
  }
  getOperationSummary() {
    this.getOperationSummaryDetails().subscribe(
      (response) => {
        this.isPieLoad = true;

        response.operations.forEach((element) => {
          //console.log("Data: " + JSON.stringify(element));
          this.OPERATION_SUMMARY_LIST.push(element);
        });
        //console.log("Data: " + JSON.stringify(this.OPERATION_SUMMARY_LIST))
        this.parseResponse(this.OPERATION_SUMMARY_LIST);
      },
      (error) => {
        // this.isPieLoad = true;
        console.log('There Was A Problem');
      }
    );
  }
  parseResponse(summaryList) {
    summaryList.forEach((element) => {
      this.avgMaintSpent = "$"+element.avgMaintenanceSpend;
       this.avgMaintTime = element.avgMaintenanceTime + " Mins";
       this.serviceTicketsOpen = element.serviceTicketsOpen;
       this.inputPieGridData = element.uptime;
       this.inputPieGridData1 = element.downtime;
       this.inputPieGridStock = element.instock;
       this.inputPieGridConsumed = element.consumed;
       this.maintenancePlanned = element.maintenancePlanned;
       this.maintenanceBreakdown = element.maintenanceBreakdown;
       this.maintenancePredictive = element.maintenancePredictive;
    });
  }
  getOperationSummaryDetails(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    };
    return this.http.post(this.apiUrl, '', { headers: headers });
  }

  topPowerConsumptions() {
    this.loadingSpin = true;
     this.getTopPowerConsumptions().subscribe(
      (response) => {
        this.isParetoLoaded = true;

        response.forEach((element) => {
          //console.log("Data: " + JSON.stringify(element));
          this.TOP_POWER_LIST.push(element);
        });
        this.TOP_POWER_LIST.forEach((element) => {
          this.barChartInput.push({
            name: element.cityName,
            value: element.unitConsumption,
          });
          this.lineChartInput.push({
            name: element.cityName,
            value: element.consumptionPercentage,
          });
          // console.log('Data1: ' + element.unitConsumption);
          // console.log('Data2: ' + element.consumptionPercentage);
        });
        this.loadingSpin = false;
        hideloader();
      },
      (error) => {
        console.log('There Was A Problem');
      }
    );
    function hideloader() {
    }
  }
  getTopPowerConsumptions(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    };
    return this.http.post(this.apiUrlTopBatteryConsumptions, '', {
      headers: headers,
    });
  }
  assetsStatus() {
    this.loadingSpin = true;

    this.getAssetsStatus().subscribe(
      (response) => {
        response.forEach((element) => {
          //console.log("Data: " + JSON.stringify(element));
          this.VEHICLES_STATUS_LIST.push(element);
        });
        this.VEHICLES_STATUS_LIST.forEach((element) => {
         if (element.status == true) {
              this.totalOnlineAssets = this.totalOnlineAssets + 1;
            } else {
              this.totalOfflineAssets = this.totalOfflineAssets + 1;
            }
        });

        console.log('totalOnlineAssets: ' + this.totalOnlineAssets);
        console.log('totalOfflineAssets: ' + this.totalOfflineAssets);
      },
      (error) => {
        console.log('There Was A Problem');
      }
    );
  }
  getAssetsStatus(): Observable<any> {
    let idToken = localStorage.getItem('msal.idtoken');
    const headers = {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + idToken,
    };
    return this.http.post(this.apiUrlOnlineOfflineStatus, '', {
      headers: headers,
    });
  }
}

export interface OperationSummary {
  Id: number;
  serviceTicketsOpen: string;
  avgMaintenanceSpend: string;
  avgMaintenanceTime: string;
  sparesInventoryInStock: string;
  maintenancePlanned: string;
  maintenanceBreakdown: string;
  maintenancePredictive: string;
  powerConsumption: string;
  availabilityUptime: string;
  availabilityDownTime: string;
  assetsOnline: string;
  assetsOffline: string;
  uptime: string;
  downtime: string;
  instock: string;
  consumed: string;
  freeFld1: string;
  freeFld2: string;
  freeFld3: string;
  RModTime: string;
  RCreTime: string;
}
export interface PowerConsumption {
  cityName: string;
  unitConsumption: number;
  consumptionPercentage: number;
}
export interface VehicleList {
  id: number;
  address: string;
  latitude: string;
  longitude: string;
  status: boolean;
  vehicleName: string;
}
