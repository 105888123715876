<mat-toolbar class="header">
  <!-- <img src="assets/images/ey_logo.png" alt="EY Logo" class="ey-logo" /> -->
  <img src="assets/dist/images/EY_Logo_Beam_RGB.png" alt="EY Logo" class="ey-logo" />
  <p class="title-content">Nottingham Spirk Innovation Hub</p>
  <div class="toolbar-spacer"></div>

  <!-- <button mat-raised-button (click)="logout()">Logout</button> -->
  
  <div class="logoutDiv">
    
      <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" data-toggle="dropdown">
          <i class="fa fa-user mr-2" aria-hidden="true"></i>{{loggedInUser}}
        </button>
        <ul class="dropdown-menu">
           <li *ngFor='let role of userRoles'>
             <a (click)='userDefinedTabs(role)'><i class="fa fa-id-card mr-2" aria-hidden="true"></i>{{role}}</a>
           </li>
           <li><a class="disabledLink"><i class="fa fa-cog mr-2" aria-hidden="true"></i>Settings</a></li>
           <li><a (click)="logout()"><i class="fa fa-power-off mr-2" aria-hidden="true"></i>Logout</a></li>
        </ul>
    </div>
      <span class="roleDiv">{{defaultuserRole}}</span>
     

  </div>
  <!-- <img src="assets/images/logout_1.png" alt="Logout" class="logout-logo" (click)="logout()"/> -->
  <!-- <p class="logout-text" (click)="logout()">Logout</p> -->

</mat-toolbar>
