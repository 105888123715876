import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vertical-large-widget',
  templateUrl: './vertical-large-widget.component.html',
  styleUrls: ['./vertical-large-widget.component.css']
})
export class VerticalLargeWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
