<div class="row">
  <div class="col-md-6">
    <div class="sales-form">
      <div class="title-info">
        <p>Sales & Replenishment</p>
      </div>
      <div class="fit-form-data">
        <app-sales-form></app-sales-form>
      </div>
    </div>
  </div>
 
</div>