import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { DashboardContentComponent } from './components/dashboard-content/dashboard-content.component';
import { PlantPerformanceComponent } from './components/feature/plant-performance/plant-performance.component';
import { PolarDelightTwinComponent } from './components/feature/polar-delight-twin/polar-delight-twin.component';
import { NavigationContainerComponent } from './components/navigation-container/navigation-container.component';
import { OpcSimulatorComponent } from './components/feature/opc-simulator/opc-simulator.component';
import { DiagnosticsComponent } from './components/feature/diagnostics/diagnostics.component';
import { RealTimeFeedComponent } from './components/feature/real-time-feed/real-time-feed.component';
import { DialogGaugeComponent } from './components/shared/dialog-gauge/dialog-gauge.component';
import { LineChartComponent } from './components/shared/line-chart/line-chart.component';
import { EventTableComponent } from './components/shared/event-table/event-table.component';
import { WidgetComponent } from './components/shared/widget/widget.component';
import { MapComponent } from './components/shared/map/map.component';
import { HierachyComponent } from './components/shared/hierachy/hierachy.component';
import { ProgressBarComponent } from './components/shared/progress-bar/progress-bar.component';
import { BubbleChartComponent } from './components/shared/bubble-chart/bubble-chart.component';
import { GaugeChartModule } from 'angular-gauge-chart';
// import {GaugesModule} from 'ng-canvas-gauges';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxChartsComponent } from './components/shared/ngx-charts/ngx-charts.component';
import { NgxBubbleChartComponent } from './components/shared/ngx-charts/ngx-bubble-chart/ngx-bubble-chart.component';
import { NgxNumberCardComponent } from './components/shared/ngx-charts/ngx-number-card/ngx-number-card.component';
import { NgxGaugeChartComponent } from './components/shared/ngx-charts/ngx-gauge-chart/ngx-gauge-chart.component';
import { NgxLineChartComponent } from './components/shared/ngx-charts/ngx-line-chart/ngx-line-chart.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { D3LineChartComponent } from './components/shared/d3-charts/d3-line-chart/d3-line-chart.component';
import { NgxVerticalBarComponent } from './components/shared/ngx-charts/ngx-vertical-bar/ngx-vertical-bar.component';
import { NgxPieGridComponent } from './components/shared/ngx-charts/ngx-pie-grid/ngx-pie-grid.component';
import { NgxHorizontalBarComponent } from './components/shared/ngx-charts/ngx-horizontal-bar/ngx-horizontal-bar.component';
import { NgxPieChartComponent } from './components/shared/ngx-charts/ngx-pie-chart/ngx-pie-chart.component';
import { NgxStackedAreaChartComponent } from './components/shared/ngx-charts/ngx-stacked-area-chart/ngx-stacked-area-chart.component';
import { NgxNumberCardAssetStatusComponent } from './components/shared/ngx-charts/ngx-number-card-asset-status/ngx-number-card-asset-status.component';
import { FooterComponent } from './components/footer/footer.component';
import { CompactWidgetComponent } from './components/shared/compact-widget/compact-widget.component';
import { VerticalLargeWidgetComponent } from './components/shared/vertical-large-widget/vertical-large-widget.component';
import { HorizontalLargeWidgetComponent } from './components/shared/horizontal-large-widget/horizontal-large-widget.component';
import { NgxNumberCardPodComponent } from './components/shared/ngx-charts/ngx-number-card-pod/ngx-number-card-pod.component';
import { CustomNumberCardComponent } from './components/shared/custom/custom-number-card/custom-number-card.component';
import { HorizontalMediumWidgetComponent } from './components/shared/horizontal-medium-widget/horizontal-medium-widget.component';
import { AlertTableComponent } from './components/shared/alert-table/alert-table.component';
import { ReusableAppTableComponent } from './components/shared/reusable-app-table/reusable-app-table.component';
import { MatContactCardComponent } from './components/shared/mat-contact-card/mat-contact-card.component';
import { D3LineChartNewComponent } from './components/shared/d3-charts/d3-line-chart-new/d3-line-chart-new.component';
import { PolarDelightComponent } from './components/feature/polar-delight/polar-delight.component';
import { CncComponent } from './components/feature/cnc/cnc.component';
import { PdOperationsComponent } from './components/feature/pd-operations/pd-operations.component';
import { PdInsightsComponent } from './components/feature/pd-insights/pd-insights.component';
import { CardWidgetComponent } from './components/shared/card-widget/card-widget.component';
import { PlantTwinDialogComponent } from './components/shared/plant-twin-dialog/plant-twin-dialog.component';
import { ParetoChartComponent } from './components/shared/pareto-chart/pareto-chart.component';
import { ComboChartComponent } from './components/shared/ngx-charts/ngx-combo-chart/combo-chart/combo-chart.component';
import { ComboSeriesVerticalComponent } from './components/shared/ngx-charts/ngx-combo-chart/combo-chart';
import { NgxParetoChartComponent } from './components/shared/ngx-charts/ngx-combo-chart/ngx-pareto-chart-component';
import { AdvancedPieChartComponent } from './components/shared/ngx-charts/advanced-pie-chart/advanced-pie-chart.component';
import { GaugeChartWidgetComponent } from './components/shared/gauge-chart-widget/gauge-chart-widget.component';
import { ChartjsPieComponent } from './components/shared/chartjs-pie/chartjs-pie.component';
import { ChartsModule } from 'ng2-charts';
import { DeviceConfigComponent } from './components/feature/device-config/device-config.component';

import { InlineEditableTableComponent } from './components/shared/inline-editable-table/inline-editable-table.component';

import { SalesFormComponent } from './components/shared/sales-form/sales-form.component';
import { DeviceConfigFormComponent } from './components/shared/device-config-form/device-config-form.component';
import { DeviceListComponent } from './components/shared/device-list/device-list.component';
import { SalesOpcSimulatorComponent } from './components/feature/sales-opc-simulator/sales-opc-simulator.component';
import { ServiceOpcSimulatorComponent } from './components/feature/service-opc-simulator/service-opc-simulator.component';
import { ServicesFormComponent } from './components/shared/services-form/services-form.component';
import { GaugesModule } from '@progress/kendo-angular-gauges';
// import { KendoChartsModule } from '@progress/kendo-angular-charts';
import { ChartsModule as Alias} from '@progress/kendo-angular-charts';
import 'hammerjs';
import { environment } from '@env';
import { DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { RolePipe } from './components/shared/customPipe/role.pipe';
import { CsvSimulatorComponent } from './components/shared/csv-simulator/csv-simulator.component';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { FileUploadComponent } from './components/shared/file-upload/file-upload.component';
import { EvOperationsComponent } from './components/feature/ev-operations/ev-operations.component';
import { LandEvOperationsComponent } from './components/feature/land-ev-operations/land-ev-operations.component';
import { EvPredictionsComponent } from './components/feature/ev-predictions/ev-predictions.component';
import { EvDiagnosticsComponent } from './components/feature/ev-diagnostics/ev-diagnostics.component';
import { ElectricVehicleComponent } from './components/feature/electric-vehicle/electric-vehicle.component';
import { EvLoginComponent } from './components/ev-login/ev-login.component';
import { EvEventTableComponent } from './components/feature/ev-event-table/ev-event-table.component';
import { EvDiagnosticsTicketTableComponent } from './components/feature/ev-diagnostics-ticket-table/ev-diagnostics-ticket-table.component';
import { EvOperationSummaryComponent } from './components/feature/ev-operation-summary/ev-operation-summary.component';
import { EvMapComponent } from './components/feature/ev-map/ev-map.component';
import { LogoutComponent } from './components/logout/logout.component';
import { EvDeviceListComponent } from './components/shared/ev-device-list/ev-device-list.component';
import { RotationConfigComponent } from './components/shared/rotation-config/rotation-config.component';
import { RotationConfigListComponent } from './components/shared/rotation-config-list/rotation-config-list.component';
import { VerticalBarChartComponent } from './components/feature/vertical-bar-chart/vertical-bar-chart.component';
import { AssetHealthCncComponent } from './components/feature/asset-health-cnc/asset-health-cnc.component';
import { PredictionsCncComponent } from './components/feature/predictions-cnc/predictions-cnc.component';
import { RemoteOperationsCncComponent } from './components/feature/remote-operations-cnc/remote-operations-cnc.component';
import { MapCncComponent } from './components/feature/map-cnc/map-cnc.component';
import { OperationsCncComponent } from './components/feature/operations-cnc/operations-cnc.component';
import { EventTableCncComponent } from './components/feature/event-table-cnc/event-table-cnc.component';
import { TicketTableCncComponent } from './components/feature/ticket-table-cnc/ticket-table-cnc.component';
import { SmartWaterHeaterComponent } from './components/feature/smart-water-heater/smart-water-heater.component';
import { SwhLoginComponent } from './components/swh-login/swh-login.component';
import { AssetHealthSwhComponent } from './components/feature/asset-health-swh/asset-health-swh.component';
import { SwhPredictionsComponent } from './components/feature/swh-predictions/swh-predictions.component';
import { SwhRemoteOperationsComponent } from './components/feature/swh-remote-operations/swh-remote-operations.component';
import { SWHOperationSummaryComponent } from './components/feature/swh-operation-summary/swh-operation-summary.component';
import { SWHMapComponent } from './components/feature/swh-map/swh-map.component';
import { SwhEventTableComponent } from './components/feature/swh-event-table/swh-event-table.component';
import { SwhTicketTableComponent } from './components/feature/swh-ticket-table/swh-ticket-table.component';
import { LandEvBatteryComponent } from './components/feature/land-ev-battery/land-ev-battery.component';
// import { BatterySohComponent } from './components/battery-soh/battery-soh.component';
import {BatterySohComponent } from './components/battery-soh/battery-soh.component';
import { IntelligentBatteryPlatformComponent } from './components/intelligent-battery-platform/intelligent-battery-platform.component';
// import { IbpLoginComponent } from './components/ibp-login/ibp-login.component';
// Import for toaster
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HeaderComponent,
    DashboardContentComponent,
    PlantPerformanceComponent,
    PolarDelightTwinComponent,
    NavigationContainerComponent,
    OpcSimulatorComponent,
    DiagnosticsComponent,
    RealTimeFeedComponent,
    DialogGaugeComponent,
    LineChartComponent,
    EventTableComponent,
    WidgetComponent,
    MapComponent,
    HierachyComponent,
    ProgressBarComponent,
    BubbleChartComponent,
    NgxChartsComponent,
    NgxBubbleChartComponent,
    NgxNumberCardComponent,
    NgxGaugeChartComponent,
    NgxLineChartComponent,
    D3LineChartComponent,
    NgxVerticalBarComponent,
    NgxPieGridComponent,
    NgxHorizontalBarComponent,
    NgxPieChartComponent,
    NgxStackedAreaChartComponent,
    NgxNumberCardAssetStatusComponent,
    FooterComponent,
    CompactWidgetComponent,
    VerticalLargeWidgetComponent,
    HorizontalLargeWidgetComponent,
    NgxNumberCardPodComponent,
    CustomNumberCardComponent,
    HorizontalMediumWidgetComponent,
    AlertTableComponent,
    ReusableAppTableComponent,
    MatContactCardComponent,
    D3LineChartNewComponent,
    PolarDelightComponent,
    CncComponent,
    PdOperationsComponent,
    PdInsightsComponent,
    CardWidgetComponent,
    PlantTwinDialogComponent,
    ParetoChartComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    NgxParetoChartComponent,
    AdvancedPieChartComponent,
    GaugeChartWidgetComponent,
    ChartjsPieComponent,
    DeviceConfigComponent,
    DeviceConfigFormComponent,
    InlineEditableTableComponent,
    SalesOpcSimulatorComponent,
    SalesFormComponent,
    DeviceListComponent,
    ServiceOpcSimulatorComponent,
    ServicesFormComponent,
    RolePipe,
    CsvSimulatorComponent,
    FileUploadComponent,
    EvOperationsComponent,
    LandEvOperationsComponent,
    EvPredictionsComponent,
    EvDiagnosticsComponent,
    ElectricVehicleComponent,
    EvLoginComponent,
    EvEventTableComponent,
    EvDiagnosticsTicketTableComponent,
    EvOperationSummaryComponent,
    EvMapComponent,
    LogoutComponent,
    EvDeviceListComponent,
    RotationConfigComponent,
    RotationConfigListComponent,
    VerticalBarChartComponent,
    AssetHealthCncComponent,
    PredictionsCncComponent,
    RemoteOperationsCncComponent,
    MapCncComponent,
    OperationsCncComponent,
    EventTableCncComponent,
    TicketTableCncComponent,
    SmartWaterHeaterComponent,
    SwhLoginComponent,
    AssetHealthSwhComponent,
    SwhPredictionsComponent,
    SwhRemoteOperationsComponent,
    SWHOperationSummaryComponent,
    SWHMapComponent,
    SwhEventTableComponent,
    SwhTicketTableComponent,
    LandEvBatteryComponent,
    BatterySohComponent,
    IntelligentBatteryPlatformComponent,
    // IbpLoginComponent

  ],
  imports: [

    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,

    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatListModule,
    MatCardModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatCardModule,
    MatTableExporterModule,
    MatInputModule,
    MatGridListModule,
    MatTabsModule,
    MatBadgeModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatMenuModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSelectModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatSnackBarModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatFileUploadModule,
    AppRoutingModule,
    GaugeChartModule,
    GaugesModule,
    NgxChartsModule,
    ChartsModule,
    Alias,
    NgbModule,
    ModalModule.forRoot(),
    MsalModule.forRoot({
      auth: {
        clientId: environment.msalConfig.clientId,
        authority: "https://login.microsoftonline.com/"+environment.msalConfig.tenantId+"/",
        redirectUri: environment.msalConfig.redirectUri,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
    {
      popUp: !isIE,
      consentScopes: environment.msalConfig.consentScopes,
      protectedResourceMap: [
        ['https://api.timeseries.azure.com/', ['user.read',
        'openid',
        'profile'
        //,'user_impersonation'
      ]]
      ],
      extraQueryParameters: {}
    }),
    NgbModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    BsModalService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
