<div class="container" style="margin-top: 2px;">
    <!-- <div class="row"
      [ngStyle]="{'height.px':innerHeight+80,'min-height.px':innerHeight+150,'width.px':innerWidth+90,'min-width.px':innerWidth+70}"> -->
      <div class="row"
      [ngStyle]="{'width.px':innerWidth+90,'min-width.px':innerWidth+70}">
      <div class="col-md-5 pt-2">
        <div class="row">
          <div class="col-md-12 pr-2">
            <app-map-cnc></app-map-cnc>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 custom-card1">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Total Machine Online'"
              [attributeValue]=totalOnlineAssets [imageName]="" [screenName]="'summary1'"></app-custom-number-card>
          </div>
          <div class="col-md-6 custom-card2">
            <app-custom-number-card [cardFooterColor]="'#E44D25'" [attribute]="'Total Machine Offline'"
              [attributeValue]=totalOfflineAssets [imageName]="" [screenName]="'summary1'"></app-custom-number-card>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="row">
          <div class="col-md-4" style="padding-right: 4px;">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Work Order'"
              [attributeValue]=workOrder [imageName]="" [screenName]="'summary'"></app-custom-number-card>
          </div>
          <div class="col-md-4" style="padding-right: 4px;">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Avg. Maintenance Spend'"
              [attributeValue]=avgMaintSpent [imageName]="" [screenName]="'summary'"></app-custom-number-card>
          </div>
          <div class="col-md-4" style="padding-right: 0px;">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Avg. Maintenance Time'"
              [attributeValue]=avgMaintTime [imageName]="" [screenName]="'summary'"></app-custom-number-card>
  
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 widget__left">
  
            <div class="widget-1-left">
              <div>
                <p
                  style="font-size: 11pt; color: rgb(209, 209, 209);text-align: center;margin-top: 0px;padding-top: 5px;margin-bottom: 0;">
                  Average Availability</p>
              </div>
              <div *ngIf="!isPieLoad" class="d-flex justify-content-center">
                <!-- <div class="spinner-border" role="status">
                  <span class="sr-only" id="loading"></span>
                </div> -->
                <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image" />
              </div>
              <div *ngIf="isPieLoad" style="margin-top: 30px;">
                <!-- <app-ngx-pie-grid [gridName]="'Availability'"></app-ngx-pie-grid> -->
                <div class="row">
                  <div class="col-sm-6 left-div" style="padding-left: 10px;">
                    <kendo-circulargauge class="green-class" [value]="inputPieGridData" [scale]="{ max: 100 }"
                      [colors]="colors" [style.height.px]="height" [style.width.px]="width">
                      <ng-template kendoCircularGaugeCenterTemplate let-value="value">
                        {{ value }}% <br>UpTime
                      </ng-template>
                    </kendo-circulargauge>
                  </div>
                  <div class="col-sm-6 left-div" style="padding-right: 10px;">
                    <kendo-circulargauge class="red-class" [value]="inputPieGridData1" [scale]="{ max: 100 }"
                      [colors]="colors" [style.height.px]="height" [style.width.px]="width">
                      <ng-template kendoCircularGaugeCenterTemplate let-value="value">
                        {{ value }}% <br>DownTime
                      </ng-template>
                    </kendo-circulargauge>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-sm-6 text-right">Total(hrs):100</div>
                  <div class="col-sm-6 text-left">Total(hrs): 20</div>
                </div>
  
              </div>
            </div>
          </div>
          <div class="col-md-6 widget__right" style="margin-bottom: 5px;">
            <div class="widget-1">
              <p
                style="font-size: 11pt; color: rgb(209, 209, 209);text-align: center;margin-top: 0px;padding-top: 5px;margin-bottom: 0;">
                Average Energy
                Consumption</p>
              <div *ngIf="loadingSpin" class="d-flex justify-content-center" style='height:150px;'>
                <!-- <div class="spinner-border" role="status">
                  <span class="sr-only" id="loading"></span>
                </div> -->
                <img src="assets/images/spinner.gif" alt="Inprogress" class="inprogres-image" />
              </div>
              <div *ngIf="isParetoLoaded" style="margin-left: 3px;">
                <app-vertical-bar-chart  [results]="barChartInput">
                </app-vertical-bar-chart>	
                <!-- <app-ngx-pareto-chart [barChartData]="barChartInput" [lineChartData]="lineChartInput"
                  [screenName]="'summary'"></app-ngx-pareto-chart> -->
  
              </div>
  
            </div>
          </div>
        </div>
      <div class="row">
          <div class="col-md-4" style="padding-right: 4px;">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'OEE(%)'"
              [attributeValue]=oeeper_day [imageName]="" [screenName]="'summary'"></app-custom-number-card>
          </div>
          <div class="col-md-4" style="padding-right: 4px;">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'MTTR(Min)'"
              [attributeValue]=mttr [imageName]="" [screenName]="'summary'"></app-custom-number-card>
          </div>
          <div class="col-md-4" style="padding-right: 0px;">
            <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'MTBF(Min)'"
              [attributeValue]=mtbf [imageName]="" [screenName]="'summary'"></app-custom-number-card>
        </div>
        
      </div>
      <div class="row">
        <div class="col-md-4" style="padding-right: 4px;">
          <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Downtime(Min)'"
            [attributeValue]=downtime [imageName]="" [screenName]="'summary'"></app-custom-number-card>
        </div>
        <div class="col-md-4" style="padding-right: 4px;">
          <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Production'"
            [attributeValue]="production" [imageName]="" [screenName]="'summary'"></app-custom-number-card>
        </div>
        <div class="col-md-4" style="padding-right: 0px;">
          <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Failure Count'"
            [attributeValue]="failures" [imageName]="" [screenName]="'summary'"></app-custom-number-card>
      </div>
    </div>
  </div>