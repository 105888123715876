<div style="height: 100%;width: 100%;background-color: black;">
<!-- <title mat-dialog-title style="font-size: 18px;color: white;text-align: center;">Plant Twin</title>
<div *ngIf="loadingSpin" class="d-flex justify-content-center">
    <div class="spinner-border text-success" role="status">
        <span class="sr-only" id="loading"></span>
    </div>
  </div>
<div id="hiearchy" class="container"></div> -->
<iframe width="100%" height="100%" src="https://explorer.digitaltwins.azure.net/?tid=adb53b4f-b05f-4dcb-a2e1-9111380568c3&eid=ey-digital-twin.api.wcus.digitaltwins.azure.net" frameborder="0" allowFullScreen="true"></iframe>
<!-- <iframe width="100%" height="100%" src="https://explorer.digitaltwins.azure.net/?tid=5b973f99-77df-4beb-b27d-aa0c70b8482c&eid=euwdsrg027adt01.api.weu.digitaltwins.azure.net" frameborder="0" allowFullScreen="true"></iframe> -->
<!-- <iframe width="100%" height="100%" src="getAdtExplorerUrl()" frameborder="0" allowFullScreen="true"></iframe> -->
</div>
