<div class="container">
  <p class="h4 text-center p-2">Polar Delight Twin</p>
  <mat-tab-group mat-align-tabs="left" dynamicHeight="true" (selectedTabChange)="tabChanged($event)">
    <mat-tab label="Operations">
      <ng-template matTabContent>
        <div class="row">
          <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <img src="assets/images/polar_delight_machine-new.png" alt="Polar Delight" class="img-fluid" />
          </div>
          <div class="col-sm-6 col-md-10 col-lg-10 col-xl-10 mt-3">
            <div class="row">
              <div class="col-3">
                <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'Machine Status'"
                  [attributeValue]="'Online'" [imageName]=""></app-custom-number-card>
              </div>
              <div class="col-3">
                <app-custom-number-card [cardFooterColor]="'green'" [attribute]="'POD Code'" [attributeValue]="'85025'"
                  [imageName]=""></app-custom-number-card>
              </div>
              <div class="col-3">
                <app-custom-number-card [cardFooterColor]="'orange'" [attribute]="'Cup Detect'" [attributeValue]="'No'"
                  [imageName]="'ice-cream-cup.png'"></app-custom-number-card>
              </div>
              <div class="col-3">
                <app-custom-number-card [cardFooterColor]="'red'" [attribute]="'Bin Door'" [attributeValue]="'Open'"
                  [imageName]=""></app-custom-number-card>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <div class="row">
                  <div class="col-4">
                    <app-compact-widget [widgetData]="'Good'" [widgetName]="'Asset Health'" [leftFooterName]="'OK'"
                      [rightFooterName]="'UP'"></app-compact-widget>
                  </div>
                  <div class="col-4">
                    <app-compact-widget [widgetData]="35" [widgetName]="'Freezer Temperature'" [leftFooterName]="'OK'"
                      [rightFooterName]="'Down'"></app-compact-widget>
                  </div>
                  <div class="col-4">
                    <app-compact-widget [widgetData]="85" [widgetName]="'Dispense Pressure'" [leftFooterName]="'FAULT'"
                      [rightFooterName]="'UP'"></app-compact-widget>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <app-compact-widget [widgetData]="65" [widgetName]="'Cycle time'" [leftFooterName]="'OK'"
                      [rightFooterName]="'Down'"></app-compact-widget>
                  </div>
                  <div class="col-4">
                    <app-compact-widget [widgetData]="600" [widgetName]="'Max RAM Load'" [leftFooterName]="'OK'"
                      [rightFooterName]="'UP'"></app-compact-widget>
                  </div>
                  <div class="col-4">
                    <app-compact-widget [widgetData]="34" [widgetName]="'Freezer Door'" [leftFooterName]="'FAULT'"
                      [rightFooterName]="'UP'"></app-compact-widget>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <div class="row custom-col-31">
                  <app-widget [widgetType]="'progress-bar'" [widgetName]="'RAM Position (mm)'" [leftFooterName]="''"
                  [rightFooterName]="''" [widgetData]="progressBar1"></app-widget>
                </div>
                <div class="row custom-col-31">
                  <app-widget [widgetType]="'progress-bar'" [widgetName]="'Cycle Status'" [leftFooterName]="''"
                    [rightFooterName]="''" [widgetData]="progressBar2"></app-widget>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Insights">
      <ng-template matTabContent>
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <app-horizontal-medium-widget [widgetType]="'d3-line-chart'" [widgetName]="'Asset Failure Probability'"
              [leftFooterName]="''" [rightFooterName]="''" [widgetData]="lineChartInputData">
            </app-horizontal-medium-widget>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <app-horizontal-medium-widget [widgetType]="'bubble-chart'" [widgetName]="'Events History'"
              [leftFooterName]="''" [rightFooterName]="''" [widgetData]="gaugeInputData3">
            </app-horizontal-medium-widget>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div class="custom-col-6">
              <app-horizontal-medium-widget [widgetType]="'d3-line-chart-new'" [widgetName]="'Insights'"
                [leftFooterName]="''" [rightFooterName]="''" [widgetData]="lineChartInputData">
              </app-horizontal-medium-widget>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">

            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
