export var single = [
  {
    "name": "Network",
    "value": 100
  },
  {
    "name": "POD JAM",
    "value": 90
  },
  {
    "name": "Device Connectivity",
    "value": 40
  },
  {
    "name": "Pressure Compressor",
    "value": 35
  },
  {
    "name": "Stepper Motor",
    "value": 40
  }
];
