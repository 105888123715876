import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.css']
})
export class WidgetComponent implements OnInit {

  @Input() widgetName : string;
  @Input() leftFooterName : string;
  @Input() rightFooterName : string;
  @Input() widgetType : string;
  @Input() widgetData : any;

  constructor() { }

  ngOnInit(): void {
  }

}
