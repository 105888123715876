import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { DataService } from '../feature/data.service';

@Component({
  selector: 'app-navigation-container',
  templateUrl: './navigation-container.component.html',
  styleUrls: ['./navigation-container.component.css']
})
export class NavigationContainerComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = false;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  isSecuredHomeShow: boolean = false;

  selectedMenu1: boolean = true;
  selectedMenu2: boolean = false;
  selectedMenu3: boolean = false;
  selectedMenu4: boolean = false;
  selectedMenu5: boolean = false;
  selectedMenu6: boolean = false;
  userRole: any;


  constructor(private router: Router,
    private service: DataService) {
    this.service.rotationData.subscribe((menuId: any) => {
      this.service.rotationDataIndex.subscribe((index: number) => {
        if (menuId != undefined) menuId.length >= 1 ? this.getMenuDetails(menuId[index].id) : this.getMenuDetails(menuId.id);
      });
    });
  }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');
    if (sessionStorage.getItem('ev_login') === 'true') {
      this.selectedMenu4 = true;
      this.getMenuDetails(4);
    } else if (sessionStorage.getItem('swh_login') === 'true') {
      this.selectedMenu5 = true;
      this.getMenuDetails(5);
    }
    sessionStorage.setItem('ev_login', '');
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }



  deselectAllSelectedMenus() {
    this.selectedMenu1 = false;
    this.selectedMenu2 = false;
    this.selectedMenu3 = false;
    this.selectedMenu4 = false;
    this.selectedMenu5 = false;
    this.selectedMenu6 = false;
  }

  getAzureMap() {
    this.deselectAllSelectedMenus();
    this.router.navigate(["/home/polar-delight"]);
  }
  getMenuDetails(menuId: number) {
    this.deselectAllSelectedMenus();
    switch (menuId) {
      case 1: {
        this.selectedMenu1 = true;
        this.router.navigate(["/home/polar-delight"]);
        break;
      }
      case 2: {
        this.selectedMenu2 = true;
        this.router.navigate(["/home/cnc"]);
        break;
      }
      case 3: {
        this.selectedMenu3 = true;
        this.router.navigate(["/home/opc-simulator"]);
        break;
      }
      case 4: {
        this.selectedMenu4 = true;
        this.router.navigate(["/home/ev"]);
        break;
      }
      case 5: {
        this.selectedMenu5 = true;
        this.router.navigate(["/home/smart-water-heater"]);
        break;
      }
      case 6: {
        this.selectedMenu6 = true;
        this.router.navigate(["/home/intelligent-battery-platform"]);
        break;
      }
      default: {
        //statements;
        break;
      }
    }

  }


}
