import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-polar-delight-twin',
  templateUrl: './polar-delight-twin.component.html',
  styleUrls: ['./polar-delight-twin.component.css']
})
export class PolarDelightTwinComponent implements OnInit {

  gaugeInputData1 = [
    {
      "name": "RAM Speed",
      "value": 65
    }
  ];


  gaugeInputData2 = [
    {
      "name": "Freezer Temperature",
      "value": 85
    }
  ];

  gaugeInputData3 = [
    {
      "name": "Dispense Pressure",
      "value": 1500
    }
  ];

  gaugeInputData4 = [
    {
      "name": "Cycle Time",
      "value": 55
    }
  ];

  lineChartInputData = [
    {
      "name": "PD01",
      "series": [
        {
          "name": "1990",
          "value": 100
        },
         {
          "name": "1991",
          "value": 500
        },
        {
          "name": "2010",
          "value": 800
        },
        {
          "name": "2011",
          "value": 650
        },
        {
          "name": "1990",
          "value": 500
        },
        {
          "name": "2010",
          "value": 20000
        },
        {
          "name": "2010",
          "value": 10
        },
        {
          "name": "2010",
          "value": 15000
        },
              {
          "name": "2010",
          "value": 2500
        },
        {
          "name": "2010",
          "value": 2000
        },
        {
          "name": "2010",
          "value": 100
        },
        {
          "name": "2010",
          "value": 2500
        },
        {
          "name": "2010",
          "value": 15000
        },
        {
          "name": "2011",
          "value": 1000
        }
      ]
    },

    {
      "name": "PD02",
      "series": [
        {
          "name": "1990",
          "value": 1000
        },
        {
          "name": "2010",
          "value": 2500
        },
        {
          "name": "2011",
          "value": 311000000
        }
      ]
    },

    {
      "name": "PD03",
      "series": [
        {
          "name": "1990",
          "value": 6500
        },
        {
          "name": "2010",
          "value": 4000
        },
        {
          "name": "2011",
          "value": 1000
        }
      ]
    },
    {
      "name": "PD04",
      "series": [
        {
          "name": "1990",
          "value": 200
        },
        {
          "name": "2010",
          "value": 5000
        }
      ]
    }
  ];

  cardData1 = [
    {
      "name": "Machine Status",
      "value": "Online"
    }
  ];

  cardData2 = [
    {
      "name": "POD Code",
      "value": "85025"
    }
  ];

  cardData3 = [
    {
      "name": "Pod Back",
      "value": "Up"
    }
  ];

  cardData4 = [
    {
      "name": "Cap Home",
      "value": "Down"
    }
  ];

  cardData5 = [
    {
      "name": "Bin Door",
      "value": "Open"
    }
  ];

  cardData6 = [
    {
      "name": "Cup Detect",
      "value": "No"
    }
  ];

  progressBar1 = "75";

  progressBar2 = "55";

  constructor() { }

  ngOnInit(): void {
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
}
}
