import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@env';

@Component({
  selector: 'app-swh-login',
  templateUrl: './swh-login.component.html',
  styleUrls: ['./swh-login.component.css']
})
export class SwhLoginComponent implements OnInit {

  @Output() isLoginEventTriggered: EventEmitter<Boolean> = new EventEmitter();
  keys: any;
  constructor(private authService: MsalService) { }

  ngOnInit(): void {
  }

  login() {
    sessionStorage.setItem('ev_login', '');
    sessionStorage.setItem('swh_login', 'true');
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    localStorage.setItem('role','Admin');
    localStorage.setItem('redirectUrlAfterLogin','/home/smart-water-heater');
    if (isIE) {
      this.authService.loginRedirect({redirectUri: environment.msalConfig.swhRedirectUri});
    } else {
      //this.authService.loginPopup();
      this.authService.loginRedirect({redirectUri: environment.msalConfig.swhRedirectUri});
    }
  }

}
