import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-opc-simulator',
  templateUrl: './sales-opc-simulator.component.html',
  styleUrls: ['./sales-opc-simulator.component.css']
})
export class SalesOpcSimulatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
